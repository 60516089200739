import { useEffect, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { logout } from '../store/slices/authentication.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../router/router';
import { useDialog } from '@backlinkit/shared';
import { useClientDialog } from '../providers/client-dialog/use-client-dialog';
import LoginDialog from '../pages/login/login-dialog';

const REFRESH_THRESHOLD = 60; // 1 minutes in seconds
const TOKEN_CHECK_INTERVAL = 60000; // 1 minute in milliseconds

export const useTokenValidation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const interval = useRef<any>(null);
  const dialog = useDialog();
  const clientDialog = useClientDialog();

  const onLogout = () => {
    dispatch(logout());
    navigate(RoutesList.Index);
  };

  // useEffect(() => {
  //   if (token) {
  //     debugger;
  //     setLocalToken(token);
  //   }
  // }, [token]);

  // const acquireTokenWithRefreshToken = async () => {
  //   try {
  //     if (accounts.length && instance) {
  //       let authRequest = {
  //         authority: b2cPolicies.authorities.signUpSignIn.authority,
  //         scopes: [`${authorityDomain}/${msalConfig.auth.clientId}/access_as_user`],
  //       };

  //       const response = await instance.acquireTokenSilent({
  //         account: accounts[0],
  //         ...authRequest,
  //       });
  //       const decodeToken = jwt(response.accessToken);
  //       localStorage.setItem('backendAccessToken', response.accessToken);
  //       localStorage.getItem('backendAccessToken');
  //       dispatch(setToken(response.accessToken));

  //       console.log('Token refreshed');
  //       console.log('Token renewed:', decodeToken);
  //     }
  //   } catch (error) {
  //     console.log('Error refreshing token', error); // Handle token refresh error
  //   }
  // };

  const openLoginDialog = () => {
    clientDialog({
      title: 'Sign In!',
      showOverlay: true,
      showCancel: false,
      render: (onCancel) => {
        return (
          <LoginDialog
            onClose={async () => {
              onCancel();
            }}
          ></LoginDialog>
        );
      },
    });
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      const currentToken = localStorage.getItem('bck_id_token');
      if (currentToken) {
        const decodeToken = jwtDecode(currentToken);
        if (decodeToken && decodeToken.exp) {
          const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
          const timeUntilExpiry = decodeToken.exp - currentTime;
          if (timeUntilExpiry <= REFRESH_THRESHOLD) {
            dialog({
              title: 'Your session has expired, Sign back in to continue.',
              showCancel: false,
              showOverlay: true,
              blocking: true,
              render: (onSubmit) => {
                return (
                  // <AppConfirm
                  //   title=""
                  //   message="Your session has expired, Sign back in to continue."
                  //   confirmText="Sign back in"
                  //   cancelText="Logout"
                  //   onConfirm={async () => {
                  //     onSubmit();
                  //     openLoginDialog();
                  //   }}
                  //   onCancel={() => {
                  //     onLogout();
                  //     onSubmit();
                  //   }}
                  // ></AppConfirm>

                  <LoginDialog
                    onClose={async () => {
                      onSubmit();
                    }}
                  ></LoginDialog>
                );
              },
            });
          }
        }
      }
    };
    interval.current = setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL);
    checkTokenExpiry(); // Check token expiry immediately after mounting
    return () => clearInterval(interval.current);
  }, []);
  return null; // You might not need to return anything from this hook
};
