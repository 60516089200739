import {
  Tr,
  Td,
  Checkbox,
  Tbody,
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Collapse,
} from '@chakra-ui/react';
import { AppColors } from '../../../theme';
import { AppButton } from '../../app-button/app-button';
import {
  TableRowsProps,
  CellClickEvent,
  ColumnSpecialTypes,
  TableRowsSelect,
} from '../app-data-table-types';
import { format } from 'date-fns';
import { memo, useState } from 'react';
import { flatMapObjectPropertyMap } from '../../../utils/flatMapObjectPropertyMap';
import { AppText } from '../../app-text';
import { AppCard } from '../../app-card';
import { FiChevronRight, FiChevronUp } from 'react-icons/fi';

const TableRow = ({
  row,
  columns,
  selectableRows,
  index,
  bgColor,
  selected,
  showExpanding = false,
  handleCellClickEvent,
  onRowCheckEvent,
}: any) => {
  const [rowIsExpanded, setRowIsExpanded] = useState<boolean>(false);
  return (
    <>
      <Tr
        key={`row-${index}`}
        bg={row.isHighlighted ? '#222222' : bgColor}
        color={row.isHighlighted ? 'white' : AppColors.textColor}
        height={'75px'}
      >
        {selectableRows && (
          <Td key={`select-row-${index}`} py={1}>
            <Checkbox
              isChecked={!!selected}
              onChange={(e) =>
                onRowCheckEvent({
                  index: index,
                  checked: e.currentTarget.checked,
                  row: row,
                })
              }
            ></Checkbox>
          </Td>
        )}
        {columns
          .filter((col: any) => col.isVisible)
          .map((column: any, index2: any) => {
            const flatRow = flatMapObjectPropertyMap(row);
            const rowValue = getRowValue(flatRow[column.key], column.type);

            return (
              <Td
                key={`cell-${index2}`}
                position={column.stickyColumn ? 'sticky' : 'unset'}
                right={0}
                bg={column.stickyColumn ? 'white' : 'transparent'}
                py={3}
              >
                <Box
                  as="span"
                  onClick={() => {
                    column.cellDataClickable && handleCellClickEvent({ column: column, row: row });
                  }}
                  _hover={{
                    cursor: column.cellDataClickable ? 'pointer' : 'unset',
                  }}
                  height={'100%'}
                >
                  {column.render ? (
                    column.render(row)
                  ) : column.cellDataClickable ? (
                    <AppButton
                      bgColor={AppColors.secondary}
                      variant={'solid'}
                      size="xs"
                      borderRadius="full"
                    >
                      {rowValue}
                    </AppButton>
                  ) : (
                    <div style={{ fontSize: '16px', height: '100%' }}>{rowValue}</div>
                  )}
                </Box>

                {showExpanding && index2 === 0 && (
                  <Box
                    mt={2}
                    display={'flex'}
                    alignItems={'center'}
                    onClick={() => setRowIsExpanded(!rowIsExpanded)}
                  >
                    {rowIsExpanded ? (
                      <FiChevronUp color={AppColors.primary} />
                    ) : (
                      <FiChevronRight color={AppColors.primary} />
                    )}

                    <AppText ml={2}>{row.expandText && row.expandText}</AppText>
                  </Box>
                )}
              </Td>
            );
          })}
      </Tr>

      {showExpanding && (
        <Tr bg={AppColors.appBackground}>
          <Td display={rowIsExpanded ? '' : 'none'} colSpan={columns.length} right={0} py={3}>
            <Collapse in={rowIsExpanded} animateOpacity>
              <AppCard rounded={'none'} mt={2} mb={2}>
                {row.expandRender}
              </AppCard>
            </Collapse>
          </Td>
        </Tr>
      )}
    </>
  );
};

const MemoTableRow = memo(TableRow, (prev: any, next: any) => {
  if (prev.columns.length !== next.columns.length) {
    return false;
  }

  if (prev.row.id === next.row.id && prev.selected !== next.selected) {
    return false;
  }

  if (prev.row.id !== next.row.id) {
    return false;
  }

  return false;
});

export const TableRows = <T extends {}>({
  data,
  columns,
  selectedRows,
  selectableRows,
  showExpanding,
  onRowsSelectedChange,
  onCellClick,
}: TableRowsProps<T>): JSX.Element => {
  const onRowCheckEvent = (tableRowSelect: TableRowsSelect<T>) => {
    if (onRowsSelectedChange) {
      onRowsSelectedChange(tableRowSelect);
    }
  };

  const handleCellClickEvent = (clickEvent: CellClickEvent<T>) => {
    if (onCellClick) {
      onCellClick(clickEvent);
    }
  };

  const rows = data.map((row, index) => {
    var selected = selectedRows.find((x) => x === row);

    return (
      <MemoTableRow
        key={index}
        row={row}
        bgColor={index % 2 !== 0 ? 'rgba(238, 238, 238, 0.3)' : 'white'}
        columns={columns}
        selectableRows={selectableRows}
        index={index}
        selected={!!selected}
        handleCellClickEvent={handleCellClickEvent}
        onRowCheckEvent={onRowCheckEvent}
        showExpanding={showExpanding}
      ></MemoTableRow>
    );
  });

  return <Tbody overflowY={'scroll'}>{rows}</Tbody>;
};

const getRowValue = (value: any, columnType: ColumnSpecialTypes | undefined) => {
  if (!columnType) return value;
  switch (columnType) {
    case ColumnSpecialTypes.date:
      return value ? format(new Date(value), 'dd MMM yyyy') : '';
    default:
      return value;
  }
};
