import React, { useState } from 'react';
import { Flex, Link, Button, Box } from '@chakra-ui/react';
import { Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import {
  AppAlertItemList,
  AppCheckbox,
  AppColors,
  AppInput,
  AppText,
  SelectOption,
} from '@backlinkit/shared';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { containsUpperCaseRegex, containsNumericRegex } from '../../models/Regex';

export type RegistrationData = {
  accountName: string;
  email: string;
  password: string;
  termsAndConditions: boolean;
  keepUpdated: boolean;
  productId: string;
};

export const registrationFormDataDefaultValues: RegistrationData = {
  accountName: '',
  email: '',
  password: '',
  termsAndConditions: false,
  keepUpdated: false,
  productId: '',
};

const registrationFormDataSchema = yup.object({
  accountName: yup.string().required('Field is required'),
  email: yup.string().email().required('Field is required'),
  password: yup
    .string()
    .required('Field is required')
    .min(8, 'At least 8 characters')
    .matches(containsNumericRegex, 'At least 1 number')
    .matches(containsUpperCaseRegex, 'At least 1 capital letter'),
});

type RegistrationProps<T> = { showError: boolean } & BaseFormProps<T>;

const RegistrationForm: React.FC<RegistrationProps<RegistrationData>> = ({
  showError,
  onSubmit,
}) => {
  const navigate = useNavigate();
  // const productParam = useParams();
  // const { data: products, refetch: refetchProducts } = useFetchProductsQuery({
  //   refetchOnMountArgChange: true,
  // });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<RegistrationData>({
    defaultValues: registrationFormDataDefaultValues,
    resolver: yupResolver(registrationFormDataSchema),
    mode: 'all',
  });

  // const productOptions = useMemo(
  //   () =>
  //     products?.map((x) => {
  //       return {
  //         label: x.name,
  //         id: x.id,
  //       };
  //     }),
  //   [products]
  // );

  const handleFormSubmit = async (formData: RegistrationData) => {
    onSubmit(formData);
  };

  const [defaultProduct, setDefaultProduct] = useState<SelectOption>();

  // useEffect(() => {
  //   if (productParam) {
  //     const product = products?.find((item) => item.id === productParam.sourceId);
  //     setDefaultProduct({
  //       label: product?.name,
  //       value: product?.id,
  //     });
  //   }
  // }, [productParam]);

  return (
    <Flex flexDir={'column'} gap={4}>
      <AppInput<RegistrationData>
        control={control}
        name="accountName"
        placeHolder="Enter your account name"
        error={errors.accountName}
        label={'Account Name'}
        variant="default"
      />
      <AppInput<RegistrationData>
        control={control}
        name="email"
        placeHolder="Enter your email"
        error={errors.email}
        label="Email"
        variant="default"
      />
      <AppInput<RegistrationData>
        inputType="password"
        control={control}
        error={errors.password}
        name="password"
        placeHolder="Password"
        label="Password"
        variant="default"
        tooltip="Password must include 1 number, 1 capital letter and have at least 8 characters"
      />
      <Stack spacing={4}>
        <Flex gap={4} align={'center'}>
          <AppCheckbox<RegistrationData>
            name="termsAndConditions"
            control={control}
            label=""
            error={errors.termsAndConditions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue('termsAndConditions', e.target.checked);
            }}
          />
          <Flex flexFlow={'row wrap'} columnGap={'6px'}>
            <AppText fontSize={'14px'}>By continuing, I accept and agree with the</AppText>
            <Link
              fontSize={'15px'}
              lineHeight={1.2}
              textDecoration={'none'}
              onClick={() => window.open('https://backlinkit.com/legal/terms', '_blank')}
            >
              Terms of Use,
            </Link>
            <Link
              fontSize={'15px'}
              lineHeight={1.2}
              textDecoration={'none'}
              onClick={() => window.open('https://backlinkit.com/legal/privacy', '_blank')}
            >
              Privacy Policy,
            </Link>
            <Link
              fontSize={'15px'}
              lineHeight={1.2}
              textDecoration={'none'}
              onClick={() => window.open('https://backlinkit.com/cookies', '_blank')}
            >
              Cookie Policy
            </Link>
            <AppText fontSize={'15px'}>and</AppText>
            <Link
              fontSize={'15px'}
              lineHeight={1.2}
              textDecoration={'none'}
              onClick={() => window.open('https://backlinkit.com/legal/disclaimer', '_blank')}
            >
              Disclaimer
            </Link>
          </Flex>
        </Flex>
        <Flex gap={4} align={'center'}>
          <AppCheckbox<RegistrationData>
            name="keepUpdated"
            control={control}
            label=""
            error={errors.keepUpdated}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue('keepUpdated', e.target.checked);
            }}
          />
          <AppText fontSize={'15px'}>
            I would like to receive marketing material and promotional offers from Backlinkit.
          </AppText>
        </Flex>
        <AppText
          as={Link}
          onClick={() => window.open('https://backlinkit.com/legal/definitions', '_blank')}
          color={AppColors.secondary2}
          _hover={{ cursor: 'pointer' }}
        >
          Click here for our legal definitions
        </AppText>
      </Stack>
      {showError && (
        <Box mt={2}>
          <AppAlertItemList
            messages={[
              'Please read through & accept the terms & conditions to continue creating your account.',
            ]}
            alertType={'error'}
          ></AppAlertItemList>
        </Box>
      )}
      <Stack>
        <Button
          isDisabled={!isValid}
          onClick={handleSubmit(handleFormSubmit)}
          fontFamily={'heading'}
          mt={showError ? 2 : 8}
          w={'full'}
          height={'50px'}
          borderRadius={'full'}
          bgColor={AppColors.secondary2}
          color={'white'}
          _hover={{
            boxShadow: 'xl',
            transform: 'translateY(-2px)',
          }}
          fontWeight={'400'}
          fontSize={'16px'}
        >
          Sign Up
        </Button>
        <Flex w={'100%'} align={'center'} gap={2} justify={'center'} mt={4}>
          <AppText>Already have an account</AppText>
          <AppText
            onClick={() => navigate(RoutesList.Index)}
            color={AppColors.secondary2}
            fontWeight={'700'}
            _hover={{
              cursor: 'pointer',
            }}
          >
            Sign In
          </AppText>
        </Flex>
      </Stack>
    </Flex>
  );
};

export default RegistrationForm;
