import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  Organization,
  OrganizationForm,
  OrganizationUpdateCreditRequest,
} from '@backlinkit/shared';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    organizationCreate: build.mutation<Organization, OrganizationForm>({
      query: (body) => ({
        url: `/organization/create`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: Organization) => {
        return response;
      },
    }),
    editOrganization: build.mutation<Organization, Organization>({
      query: (payload) => ({
        url: `/organization/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    patchInitializeOrganizationCustomer: build.mutation<Organization, string>({
      query: (orgId) => ({
        url: `/organization/initialize/customer/${orgId}`,
        method: 'PATCH',
      }),
    }),
    patchUpdateOrganizationCustomer: build.mutation<Organization, Organization>({
      query: (body) => ({
        url: `/organization/update/customer/${body.id}`,
        method: 'PATCH',
        body,
      }),
    }),
    // editOrganizationCreditByProductId: build.mutation<
    //   Organization,
    //   OrganizationUpdateCreditRequest
    // >({
    //   query: (payload) => ({
    //     url: `/organization/update/credit/by/productId/${payload.id}/${payload.productId}`,
    //     method: 'PATCH',
    //     body: payload,
    //   }),
    // }),
    fetchOrganizationById: build.query<Organization, string>({
      query: (id) => ({
        url: `/organization/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Organization) => {
        const transformedData: Organization = response;
        return transformedData;
      },
    }),
  }),
});

export const {
  // useFetchOrganizationByIdQuery,
  // useLazyFetchOrganizationByIdQuery,
  useEditOrganizationMutation,
  // useEditOrganizationCreditByProductIdMutation,
  usePatchInitializeOrganizationCustomerMutation,
  // usePatchUpdateOrganizationCustomerMutation,
} = organizationApi;
