import { Box, Flex, Icon, IconButton, Image, Link, useBreakpointValue } from '@chakra-ui/react';
import { AppColors } from '../../theme';
import { AppButton } from '../app-button';
import { AppText } from '../app-text';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import {
  socials,
  coreTools,
  insights,
  supportLinks,
  legalLinks,
} from '../../constants/footer-nav-items';

export type AppFooterProps = {
  hasFreeTrialBanner?: boolean;
  footerLogo: string;
  usersImage: string;
  onBtnClick: () => void;
};

const SharedFooter: React.FC<AppFooterProps> = ({
  hasFreeTrialBanner = true,
  footerLogo,
  usersImage,
  onBtnClick,
}) => {
  const navigate = useNavigate();
  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'tablet',
    lg: 'desktop',
  });

  const linkFontSize = variant === 'mobile' ? '14px' : '18px';

  const getTopMargin = () => {
    switch (variant) {
      case 'mobile':
        return '180px';
      case 'tablet':
        return '280px';
      case 'desktop':
        return '50px';
      default:
        return '50px';
    }
  };
  return (
    <Flex
      width={'100%'}
      p={4}
      gap={4}
      minH={variant !== 'desktop' ? '110vh' : '550px'}
      height={'100%'}
      align={'center'}
      justify={'center'}
      flexDir={'column'}
      bgColor={'#3B4147'}
      pos={'relative'}
      mt={hasFreeTrialBanner ? '200px' : 0}
    >
      {hasFreeTrialBanner && (
        <Flex
          height={variant !== 'desktop' ? 'auto' : '310px'}
          bgColor={'white'}
          pos={'relative'}
          w={variant !== 'desktop' ? 'full' : '1140px'}
          maxW={'100%'}
          padding={4}
          justify={'center'}
          align={'center'}
          borderRadius={'3xl'}
          px={variant !== 'mobile' ? '80px' : 4}
          py={variant !== 'mobile' ? '65px' : 4}
          position={'absolute'}
          top={variant !== 'desktop' ? '-155px' : '-160px'}
          boxShadow={`0px 15px 25px rgba(0, 0, 0, 0.25)`}
          mx={variant !== 'desktop' ? 7 : 0}
        >
          <Flex
            maxW={variant !== 'desktop' ? '100%' : '1010px'}
            w={'100%'}
            align={'center'}
            flexDir={variant !== 'desktop' ? 'column' : 'row'}
            gap={variant === 'mobile' ? '10px' : '0px'}
          >
            <Flex
              height={variant !== 'mobile' ? '100%' : '150px'}
              align={'center'}
              width={'100%'}
              justify={'start'}
            >
              <Flex
                flexDir={'column'}
                justify={'space-between'}
                gap={4}
                height={{ base: 'auto', lg: '180px' }}
                maxW={'520px'}
              >
                <AppText as={'h3'} fontSize={{base: '22px', lg: '36px'}} fontWeight={'700'}>
                  Grab 7 days of free access to Backlinkit's intelligence platform!
                </AppText>
                <AppText>no credit card required</AppText>
              </Flex>
            </Flex>
            <Flex
              width={{ base: '100%', lg: '50%' }}
              justify={'space-between'}
              flexDir={'column'}
              align={{ base: 'start', lg: 'center' }}
              height={'100%'}
              gap={8}
            >
              <AppButton
                bgColor={AppColors.secondary2}
                onClick={onBtnClick}
                color={'white'}
                height={variant !== 'desktop' ? '60px' : '80px'}
                w={'200px'}
                borderRadius={'full'}
                fontSize={'24px'}
                p={0}
                mt={{base: 4, lg: 0}}
              >
                <AppText fontSize={'18px'}>Start Free Trial</AppText>
              </AppButton>
              <Image src={usersImage} height={'auto'} maxW={'270px'} w={'100%'} />
            </Flex>
          </Flex>
        </Flex>
      )}

      <Flex
        justify={'space-between'}
        bgColor={'#3B4147'}
        maxWidth={'1140px'}
        marginTop={getTopMargin()}
        flexDir={variant !== 'desktop' ? 'column' : 'row'}
        width={'100%'}
        pb={variant !== 'destkop' ? 8 : 0}
      >
        <Flex width={'260px'} flexDir={'column'} justify={'center'} gap={4} color={'white'}>
          <Image src={footerLogo} />
          <AppText fontSize={'16px'}>
            It’s next-level backlink intelligence to safeguard your domain authority.
          </AppText>
          <Box>
            <AppText fontWeight={'bold'} fontSize={'18px'} color={AppColors.innerContentColor}>
              Follow the buzz
            </AppText>
            <Flex align={'center'} gap={4} mt={4}>
              {socials.map((item, idx) => (
                <IconButton
                  bgColor={'#32302E'}
                  aria-label={''}
                  onClick={() => window.open(item.href)}
                  p={1}
                  borderRadius={'full'}
                  key={`${item.href}-${idx}`}
                >
                  <Icon as={item.icon} color={'white'} boxSize={'15px'} />
                </IconButton>
              ))}
            </Flex>
          </Box>
        </Flex>
        <Flex
          width={'100%'}
          justify={variant !== 'desktop' ? 'start' : 'end'}
          marginTop={variant !== 'desktop' ? 4 : 0}
          marginBottom={variant === 'mobile' ? '80px' : 0}
        >
          <Flex gap={6} color={'white'} flexDir={variant !== 'desktop' ? 'column' : 'row'}>
            {/* <Flex flexDir={'column'} gap={4}>
              <AppText fontSize={'18px'} fontWeight={'bold'}>
                Insights
              </AppText>
              <Flex flexDir={'column'} gap={4}>
                {insights.map((link, idx) => (
                  <Link
                    key={`${link.title}-${idx}`}
                    onClick={() => navigate(link.href)}
                    fontSize={'16px'}
                    opacity={0.8}
                  >
                    {link.title}
                  </Link>
                ))}
              </Flex>
            </Flex>
            <Flex flexDir={'column'} gap={4}>
              <AppText fontSize={'18px'} fontWeight={'bold'}>
                Core Tools
              </AppText>
              <Flex flexDir={'column'} gap={4}>
                {coreTools.map((link, idx) => (
                  <Link
                    key={`${link.title}-${idx}`}
                    onClick={() => navigate(link.href)}
                    fontSize={'16px'}
                    opacity={0.8}
                  >
                    {link.title}
                  </Link>
                ))}
              </Flex>
            </Flex> */}
            <Flex flexDir={'column'} gap={4}>
              <AppText fontSize={'18px'} fontWeight={'bold'}>
                Support
              </AppText>
              <Flex flexDir={'column'} gap={4}>
                {supportLinks.map((link, idx) => (
                  <Link
                    key={`${link.title}-${idx}`}
                    onClick={() => navigate(link.href)}
                    fontSize={'16px'}
                    opacity={0.8}
                  >
                    {link.title}
                  </Link>
                ))}
              </Flex>
            </Flex>
            <Flex flexDir={'column'} gap={6}>
              <AppText fontSize={'18px'} fontWeight={'bold'}>
                Legals
              </AppText>
              <Flex flexDir={'column'} gap={4}>
                {legalLinks.map((link, idx) => (
                  <Link
                    as={RouterLink}
                    key={`${link.title}-${idx}`}
                    onClick={() => navigate(link.href)}
                    fontSize={'16px'}
                    opacity={0.8}
                  >
                    {link.title}
                  </Link>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width={'100%'}
        p={2}
        justify={'center'}
        bgColor={'#32302E'}
        pos={'absolute'}
        bottom={0}
        marginTop={4}
        left={0}
      >
        <Flex
          align={'center'}
          flexDir={variant === 'mobile' ? 'column-reverse' : 'row'}
          gap={3}
          justify={'space-between'}
          width={'1140px'}
        >
          <AppText color={'white'} fontSize={'16px'}>
            © 2024-2025 Backlinkit.com. All Rights Reserved
          </AppText>
          <Flex gap={variant !== 'mobile' ? 8 : 3} align={'center'}></Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SharedFooter;
