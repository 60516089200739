import { Icon, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineGoogle } from 'react-icons/ai';
import { AppColors } from '@backlinkit/shared';
import { FaTruckLoading } from 'react-icons/fa';
import { format } from 'date-fns';

type GoogleIndexCellProps = {
  url: string;
  hasDomainIndex?: boolean;
  hasPageIndex?: boolean;
  googleCheckDate?: Date;
};

const GoogleIndexCell: React.FC<GoogleIndexCellProps> = ({
  url,
  hasDomainIndex,
  hasPageIndex,
  googleCheckDate,
}) => {
  if (hasDomainIndex === undefined && hasPageIndex === undefined) {
    return <></>;
  }

  const getToolTipLabel = () => {
    if (!googleCheckDate) {
      return 'In 24h';
    }

    const date = format(new Date(googleCheckDate), 'dd MMM yyyy hh:mm:ss a O');

    if (hasPageIndex) {
      return `Page Indexed - Last Checked - ${date}`;
    } else if (hasDomainIndex) {
      return `Domain Indexed - Last Checked - ${date}`;
    } else {
      return `No Index - Last Checked - ${date}`;
    }
  };

  const getIconColor = () => {
    if (!googleCheckDate) {
      return 'grey';
    }
    if (hasPageIndex) {
      return 'green';
    } else if (hasDomainIndex) {
      return AppColors.secondary;
    } else {
      return 'orange';
    }
  };

  const handleGoogleIndexCellClick = async () => {
    window.open(`https://www.google.com/search?q=site:${url}`, 'blank');
  };

  return (
    <Tooltip label={`${getToolTipLabel()}`}>
      <span onClick={handleGoogleIndexCellClick}>
        {googleCheckDate ? (
          <Icon color={getIconColor()} as={AiOutlineGoogle} boxSize={'25px'} />
        ) : (
          <Icon color={getIconColor()} as={FaTruckLoading} boxSize={'25px'} />
        )}
      </span>
    </Tooltip>
  );
};

export default GoogleIndexCell;
