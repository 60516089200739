import { Base } from './base';

export interface ProductDto extends Base {
  name: string;
  description: string;
  price: number;
  isInitial: boolean;
  type: ProductType;
  linkLimit: number;
  freeCredits: number;
  priceKey: string;
  domainLimit: number;
  userLimit: number;
}

export enum ProductType {
  SUBSCRIPTION,
  CHECKERCREDITS,
  PAYG,
  FREE_SUBSCRIPTION,
}

export interface ProductForm {
  name: string;
  description: string;
  price: number;
  isInitial: boolean;
  type: ProductType;
  linkLimit: number;
  freeCredits: number;
  priceKey: string;
  domainLimit: number;
  userLimit: number;
}

export interface ProductEditForm extends ProductForm {
  id: string;
}
