import { Flex, Image, useToast, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import OTPForm, { OTPFormData } from '../../components/forms/external-report-otp-form';
import { AppColors, useLoading } from '@backlinkit/shared';
import { useLazyVerifyOrganizationOtpQuery } from '../../store/api/tempOrganizationApi';
import { useUserRegistrationInitiateMutation } from '../../store/api/authenticationApi';
import { useDispatch } from 'react-redux';
import { setUserAuth } from '../../store/slices/authentication.slice';
import { RoutesList } from '../../router/router';
import loginImage from '../../assets/login-image.png';

type ExternalReportOtpVerificationPageProps = {
  id: string;
};

const AccountSignupOtpVerificationPage: React.FC<ExternalReportOtpVerificationPageProps> = ({
  id,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [smallDevice] = useMediaQuery('(max-width: 1400px)');

  const { setLoading } = useLoading();
  const [verifyOtp] = useLazyVerifyOrganizationOtpQuery();
  const [userRegistration, { data, isLoading, error, isSuccess, isError }] =
    useUserRegistrationInitiateMutation();
  const handleClick = async (data: OTPFormData) => {
    try {
      setLoading(true);
      const verified = await verifyOtp({ id, otp: data.otp ?? 0 }).unwrap();

      if (verified) {
        toast({
          title: 'OTP Successfully verified!',
          description: 'Please wait while we get everything ready for you!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        await userRegistration(id)
          .unwrap()
          .then((res) => {
            dispatch(setUserAuth(res));
            navigate(RoutesList.Dashboard);
          });
      } else {
        toast({
          title: 'OTP Failed verification!',
          description: 'Please try again.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Flex h={'100%'} align={'center'} justify={'center'} bgColor={AppColors.secondary2}>
      <Flex
        w={'100%'}
        maxW={{ base: '650px', xl: '100%' }}
        justify={'space-evenly'}
        align={'center'}
        p={{ base: 4, sm: 6, md: 8 }}
      >
        <OTPForm
          onSubmit={(data) => {
            handleClick(data);
          }}
        />
      </Flex>
      {!smallDevice && (
        <Flex w={'100%'} height={'100%'} justify={'center'} align={'center'}>
          <Image maxH={'640px'} maxW={'760px'} src={loginImage} />
        </Flex>
      )}
    </Flex>
  );
};
export default AccountSignupOtpVerificationPage;
