import { Box, Flex, Tag, TagLabel, useToast } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import {
  AppButton,
  AppColors,
  AppText,
  HttpError,
  RoleDto,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import PasswordForm, { PasswordFormData } from '../../components/forms/password-reset-form';
import { useUpdatePasswordMutation } from '../../store/api/authenticationApi';

const UserProfile: React.FC = () => {
  const { setLoading } = useLoading();
  const toast = useToast();
  const user = useSelector(selectUser);
  const dialog = useDialog();

  const [updatePassword] = useUpdatePasswordMutation();

  const handlePasswordChange = async (formData: PasswordFormData) => {
    try {
      setLoading(true);
      await updatePassword({
        id: user?.id ?? '',
        password: formData.confirmedPassword,
      });
      toast({
        title: 'Password',
        description: 'Successfully saved.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      const httpError: HttpError = (error as any).data as HttpError;

      toast({
        title: 'Password change failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const changePassword = () => {
    dialog({
      title: 'Change your Password',
      size: '2xl',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <Box as={'form'}>
            <PasswordForm
              onSubmit={(data) => {
                handlePasswordChange(data);
                onSubmit();
              }}
              saveButtonText="Save"
            ></PasswordForm>
          </Box>
        );
      },
    });
  };

  const renderRole = (role: RoleDto) => {
    return (
      <Tag
        borderRadius="full"
        bg={AppColors.primary}
        color={'white'}
        height={'30px'}
        minW={'100px'}
        display={'flex'}
        justifyContent={'center'}
      >
        <TagLabel>{role.name}</TagLabel>
      </Tag>
    );
  };

  return (
    <Flex width={'100%'} p={8}>
      <Flex direction={'column'} w={'100%'} borderRadius={'2xl'}>
        <Flex bgColor={AppColors.secondary2} borderTopRadius={'2xl'} minH={'65px'} p={4}>
          <Flex justifyContent={'space-between'} alignContent={'center'}>
            <Flex alignItems={'center'}>
              <Flex direction={'row'} mr={8}>
                <AppText variant={'h4'} color={'white'}>
                  Your Profile
                </AppText>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          gap={5}
          p={'30px'}
          borderBottomRadius={'2xl'}
          border={`1px solid ${AppColors.secondary2}`}
        >
          <Flex
            bgColor={'white'}
            flexDir={'column'}
            gap={4}
            flex={1}
            borderRadius={'2xl'}
            py={12}
            px={8}
          >
            <Flex flexDirection={'column'} gap={4}>
              <AppText variant={'h4'}>Your Profile Details:</AppText>
              <Flex flexDir={'column'} gap={4}>
                <Flex
                  align={'center'}
                  width={'100%'}
                  justify={'space-between'}
                  bgColor={AppColors.appBorder}
                  borderRadius={'2xl'}
                  p={4}
                  h={'70px'}
                >
                  <Box width={'50%'}>
                    <AppText fontWeight={'medium'} fontSize={'18px'}>
                      User Created:
                    </AppText>
                  </Box>
                  <Box width={'50%'}>
                    <AppText fontWeight={'bold'} fontSize={'18px'}>
                      {user?.dateCreated ? format(new Date(user?.dateCreated), 'dd MMM yyyy') : ''}
                    </AppText>
                  </Box>
                </Flex>
                <Flex
                  align={'center'}
                  width={'100%'}
                  justify={'space-between'}
                  bgColor={AppColors.appBorder}
                  borderRadius={'2xl'}
                  p={4}
                  h={'70px'}
                >
                  <Box width={'50%'}>
                    <AppText fontWeight={'medium'} fontSize={'18px'}>
                      Email / Username:
                    </AppText>
                  </Box>
                  <Box width={'50%'}>
                    <AppText fontWeight={'bold'} fontSize={'18px'}>
                      {user?.email}
                    </AppText>
                  </Box>
                </Flex>

                <Flex
                  align={'center'}
                  h={'70px'}
                  width={'100%'}
                  justify={'space-between'}
                  bgColor={AppColors.appBorder}
                  borderRadius={'2xl'}
                  p={4}
                >
                  <Box width={'50%'}>
                    <AppText fontWeight={'medium'} fontSize={'18px'}>
                      Role:
                    </AppText>
                  </Box>
                  <Box width={'50%'}>{user && user?.role && renderRole(user?.role)}</Box>
                </Flex>
              </Flex>

              <Box>
                <AppButton
                  mt={12}
                  bgColor={AppColors.ctaColor}
                  h={'55px'}
                  w={'full'}
                  color={'white'}
                  borderRadius={'full'}
                  onClick={() => {
                    changePassword();
                  }}
                >
                  Change Password
                </AppButton>
              </Box>
            </Flex>
          </Flex>
          <Flex bgColor={'white'} py={12} px={8} borderRadius={'2xl'} gap={4} flex={1}>
            {/* ADD CAN I HERE */}
            {/* {user?.organizationRoleId === OrganizationRoleType.ADMIN && (
              <Flex bgColor={'white'} flexDir={'column'} gap={4} flex={1} borderRadius={'2xl'}>
                <AppText variant={'h4'}>Organization Details:</AppText>
                <Flex flexDir={'column'} gap={4} width={'100%'}>
                  <Flex
                    bgColor={AppColors.appBorder}
                    height={'65px'}
                    p={4}
                    align={'center'}
                    justify={'space-between'}
                    borderRadius={'2xl'}
                  >
                    <Box width={'50%'}>
                      <AppText fontWeight={'medium'} fontSize={'14px'}>
                        Organization Contact Email:
                      </AppText>
                    </Box>
                    <Box width={'50%'}>
                      <AppText fontWeight={'bold'} fontSize={'14px'}>
                        {user?.organization?.email}
                      </AppText>
                    </Box>
                  </Flex>

                  <Flex
                    bgColor={AppColors.appBorder}
                    height={'65px'}
                    p={4}
                    align={'center'}
                    justify={'space-between'}
                    borderRadius={'2xl'}
                  >
                    <Box width={'50%'}>
                      <AppText fontWeight={'medium'} fontSize={'14px'}>
                        Company Name:
                      </AppText>
                    </Box>
                    <Box width={'50%'}>
                      <AppText fontWeight={'bold'} fontSize={'14px'}>
                        {user?.organization.name}
                      </AppText>
                    </Box>
                  </Flex>

                  <Flex
                    bgColor={AppColors.appBorder}
                    height={'65px'}
                    p={4}
                    align={'center'}
                    justify={'space-between'}
                    borderRadius={'2xl'}
                  >
                    <Box width={'50%'}>
                      <AppText fontWeight={'medium'} fontSize={'14px'}>
                        Accepted Terms:
                      </AppText>
                    </Box>
                    <Box width={'50%'}>
                      <Checkbox size={'lg'} isDisabled isChecked={user?.organization.acceptTerms} />
                    </Box>
                  </Flex>

                  <Flex
                    bgColor={AppColors.appBorder}
                    height={'65px'}
                    p={4}
                    align={'center'}
                    justify={'space-between'}
                    borderRadius={'2xl'}
                  >
                    <Box width={'50%'}>
                      <AppText fontWeight={'medium'} fontSize={'14px'}>
                        Accepted Marketing:
                      </AppText>
                    </Box>
                    <Box width={'50%'}>
                      <Checkbox
                        isDisabled
                        size={'lg'}
                        isChecked={user?.organization.acceptMarketing}
                      />
                    </Box>
                  </Flex>

                  <Flex
                    bgColor={AppColors.appBorder}
                    height={'65px'}
                    p={4}
                    align={'center'}
                    justify={'space-between'}
                    borderRadius={'2xl'}
                  >
                    <Box width={'50%'}>
                      <AppText fontWeight={'medium'} fontSize={'14px'}>
                        Organization Created:
                      </AppText>
                    </Box>
                    <Box width={'50%'}>
                      <AppText fontWeight={'bold'} fontSize={'14px'}>
                        {user?.organization?.dateCreated
                          ? format(new Date(user?.organization?.dateCreated), 'dd MMM yyyy')
                          : ''}
                      </AppText>
                    </Box>
                  </Flex>

                  <Flex
                    bgColor={AppColors.appBorder}
                    height={'65px'}
                    p={4}
                    align={'center'}
                    justify={'space-between'}
                    borderRadius={'2xl'}
                  >
                    <Box width={'50%'}>
                      <AppText fontWeight={'medium'} fontSize={'14px'}>
                        Current Subscription:
                      </AppText>
                    </Box>
                    <Box width={'50%'}>
                      <AppText fontWeight={'bold'} fontSize={'14px'}>
                        {user?.organization?.product?.name}
                      </AppText>
                    </Box>
                  </Flex>
                  <Flex
                    bgColor={AppColors.appBorder}
                    height={'65px'}
                    p={4}
                    align={'center'}
                    justify={'space-between'}
                    borderRadius={'2xl'}
                  >
                    <Box width={'50%'}>
                      <AppText fontWeight={'medium'} fontSize={'14px'}>
                        Available Credit:
                      </AppText>
                    </Box>
                    <Box width={'50%'}>
                      <AppText fontWeight={'bold'} fontSize={'14px'}>
                        {user?.organization?.availableCredit}
                      </AppText>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            )} */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UserProfile;
