import { bugApi } from './api/bugReportApi';
import { notificationApi } from './api/notificationsApi';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { authenticationSlice } from './slices/authentication.slice';
import { authenticationApi } from './api/authenticationApi';
import { domainApi } from './api/domainApi';
import { backlinksApi } from './api/backlinksApi';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { domainSlice } from './slices/domain.slice';
import { backlinkSlice } from './slices/backlink.slice';
import { backlinkEflApi } from './api/backlinkEflApi';
import { backlinkReportApi } from './api/backlinkReportApi';
import { googleCheckerApi } from './api/googleCheckerApi';
import { tagApi } from './api/tagApi';
import { userApi } from './api/userApi';
import { organizationApi } from './api/organizationApi';
import { productApi } from './api/productApi';
import { organizationPaymentApi } from './api/organizationPaymentApi';
import { faqApi } from './api/faqApi';
import { nicheTypeApi } from './api/nicheTypeApi';
import { domainUserRoleTypesApi } from './api/domainUserRoleTypesApi';
import { batchApi } from './api/backlinkBatchesApi';
import { batchTypesApi } from './api/batchTypesApi';
import { tagSlice } from './slices/tag.slice';
import { verifyApi } from './api/verifyApi';
import { organizationBudgetTypeApi } from './api/organizationBudgetTypeApi';
import { organizationLinkBuildingStratergiesTypeApi } from './api/organizationLinkBuilidingStrategiesTypeApi';
import { organizationLinkEstimationTypeApi } from './api/organizationLinkEstimationTypeApi';
import { organizationSEOExperienceTypeApi } from './api/organizationSEOExperienceTypeApi';
import { organizationTypeApi } from './api/organizationTypeApi';
import { userInviteEmailApi } from './api/userInviteEmailApi';
import { tempOrganizationApi } from './api/tempOrganizationApi';
import { unAuthBacklinkReportApi } from './api/unAuthBacklinkReportApi';
import { userSlice } from './slices/user.slice';
import { preferencesSlice } from './slices/preferences.slice';
import { organizationCompetitorApi } from './api/organization-competitorsApi';
import { organizationExcludeLinkApi } from './api/organizationExludeLinkApi';
import { organizationSlice } from './slices/organization.slice';
import { organizationSourceTypeApi } from './api/organizationSourceTypeApi';
import { organizationNewFeatureTypeApi } from './api/organizationNewFeatureApi';
import { roleApi } from './api/roleApi';
import { featureApi } from './api/featureApi';
import { featureSlice } from './slices/feature.slice';
import { paddleApi } from './api/paddleApi';
import { ErrorMiddleware } from './api/errorMiddleware';

const authPersistedReducer = persistReducer(
  {
    key: 'auth',
    storage,
  },
  authenticationSlice.reducer
);

// const domianPersistedDomainReducer = persistReducer(
//   {
//     key: 'domain',
//     storage,
//   },
//   domainSlice.reducer
// );

// const backlinksPersistedReducer = persistReducer(
//   {
//     key: 'backlinks',
//     storage,
//   },
//   backlinkSlice.reducer
// );

// const backlinkCategoryPersistedReducer = persistReducer(
//   {
//     key: 'backlinkCategories',
//     storage,
//   },
//   backlinkCategorySlice.reducer
// );

const preferencePersistedReducer = persistReducer(
  {
    key: 'preference',
    storage,
  },
  preferencesSlice.reducer
);

const featurePersistedReducer = persistReducer(
  {
    key: 'feature',
    storage,
  },
  featureSlice.reducer
);

const domainPersistReducer = persistReducer(
  {
    key: 'domains',
    storage,
  },
  domainSlice.reducer
);

export const store = configureStore({
  reducer: {
    authentication: authPersistedReducer,
    domain: domainPersistReducer,
    backlink: backlinkSlice.reducer,
    tag: tagSlice.reducer,
    user: userSlice.reducer,
    organization: organizationSlice.reducer,
    feature: featurePersistedReducer,
    preferences: preferencesSlice.reducer,
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [domainApi.reducerPath]: domainApi.reducer,
    [backlinksApi.reducerPath]: backlinksApi.reducer,
    [googleCheckerApi.reducerPath]: googleCheckerApi.reducer,
    [backlinkEflApi.reducerPath]: backlinkEflApi.reducer,
    [backlinkReportApi.reducerPath]: backlinkReportApi.reducer,
    [tagApi.reducerPath]: tagApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [organizationPaymentApi.reducerPath]: organizationPaymentApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [nicheTypeApi.reducerPath]: nicheTypeApi.reducer,
    [domainUserRoleTypesApi.reducerPath]: domainUserRoleTypesApi.reducer,
    [batchApi.reducerPath]: batchApi.reducer,
    [batchTypesApi.reducerPath]: batchTypesApi.reducer,
    [verifyApi.reducerPath]: verifyApi.reducer,
    [organizationBudgetTypeApi.reducerPath]: organizationBudgetTypeApi.reducer,
    [organizationLinkBuildingStratergiesTypeApi.reducerPath]:
      organizationLinkBuildingStratergiesTypeApi.reducer,
    [organizationLinkEstimationTypeApi.reducerPath]: organizationLinkEstimationTypeApi.reducer,
    [organizationSEOExperienceTypeApi.reducerPath]: organizationSEOExperienceTypeApi.reducer,
    [organizationTypeApi.reducerPath]: organizationTypeApi.reducer,
    [bugApi.reducerPath]: bugApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [userInviteEmailApi.reducerPath]: userInviteEmailApi.reducer,
    [tempOrganizationApi.reducerPath]: tempOrganizationApi.reducer,
    [unAuthBacklinkReportApi.reducerPath]: unAuthBacklinkReportApi.reducer,
    [organizationCompetitorApi.reducerPath]: organizationCompetitorApi.reducer,
    [organizationExcludeLinkApi.reducerPath]: organizationExcludeLinkApi.reducer,
    [organizationSourceTypeApi.reducerPath]: organizationSourceTypeApi.reducer,
    [organizationNewFeatureTypeApi.reducerPath]: organizationNewFeatureTypeApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [featureApi.reducerPath]: featureApi.reducer,
    [paddleApi.reducerPath]: paddleApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authenticationApi.middleware,
      domainApi.middleware,
      backlinksApi.middleware,
      googleCheckerApi.middleware,
      backlinkEflApi.middleware,
      backlinkReportApi.middleware,
      tagApi.middleware,
      userApi.middleware,
      organizationApi.middleware,
      productApi.middleware,
      organizationPaymentApi.middleware,
      faqApi.middleware,
      nicheTypeApi.middleware,
      domainUserRoleTypesApi.middleware,
      batchApi.middleware,
      batchTypesApi.middleware,
      verifyApi.middleware,
      organizationBudgetTypeApi.middleware,
      organizationLinkBuildingStratergiesTypeApi.middleware,
      organizationLinkEstimationTypeApi.middleware,
      organizationTypeApi.middleware,
      organizationSEOExperienceTypeApi.middleware,
      bugApi.middleware,
      notificationApi.middleware,
      userInviteEmailApi.middleware,
      tempOrganizationApi.middleware,
      unAuthBacklinkReportApi.middleware,
      organizationCompetitorApi.middleware,
      organizationExcludeLinkApi.middleware,
      organizationSourceTypeApi.middleware,
      organizationNewFeatureTypeApi.middleware,
      roleApi.middleware,
      featureApi.middleware,
      paddleApi.middleware,
      ErrorMiddleware
    ),
});

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
