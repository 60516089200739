import React, { useState, useEffect } from 'react';
import { Alert, AlertIcon, AlertTitle, Flex, FormLabel } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { domainURLRegex } from '../../constants/regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppInput, AppButton, SelectListFilter, SelectOption, AppColors } from '@backlinkit/shared';
import { useFetchAllDomainUserRoleTypesQuery } from '../../store/api/domainUserRoleTypesApi';
import { useFetchAllNicheTypesQuery } from '../../store/api/nicheTypeApi';

export type DomainFormData = {
  id?: string;
  domainName: string;
  url: string;
  domainUserRoleTypeId?: string;
  nicheTypeId?: string;
};

export const domainFormDataDefaultValues: DomainFormData = {
  domainName: '',
  url: '',
  nicheTypeId: undefined,
  domainUserRoleTypeId: undefined,
};

const domainFormDataSchema = yup.object({
  domainName: yup.string().required('Field is required'),
  url: yup.string().required('Field is required').matches(domainURLRegex, 'Not a valid domain'),
});

type DomainCreateFormProps<T> = {
  form?: DomainFormData;
} & BaseFormProps<T>;

const DomainCreateForm: React.FC<DomainCreateFormProps<DomainFormData>> = ({ form, onSubmit }) => {
  const {
    control: domainControl,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<DomainFormData>({
    defaultValues: form || domainFormDataDefaultValues,
    resolver: yupResolver(domainFormDataSchema),
    mode: 'onChange',
  });

  const { data: domainUserRoles } = useFetchAllDomainUserRoleTypesQuery({
    refetchOnMountArgChange: true,
  });
  const { data: domainNicheTypes } = useFetchAllNicheTypesQuery({
    refetchOnMountArgChange: true,
  });

  const [error, setError] = useState<boolean>(false);

  const [userRoleOptions, setUserRoleOptions] = useState<SelectOption[]>();
  const [domainNicheTypeOptions, setDomainNicheTypeOptions] = useState<SelectOption[]>();

  useEffect(() => {
    if (domainNicheTypes) {
      const nicheSelectors: SelectOption[] = [];
      domainNicheTypes?.map((x) => {
        nicheSelectors.push({
          label: x.name,
          value: x.id,
        });
      });
      setDomainNicheTypeOptions(nicheSelectors);
    }
  }, [domainNicheTypes]);

  useEffect(() => {
    if (domainUserRoles) {
      const roleSelectors: SelectOption[] = [];
      domainUserRoles?.map((x) => {
        roleSelectors.push({
          label: x.name,
          value: x.id,
        } as SelectOption);
      });
      setUserRoleOptions(roleSelectors);
    }
  }, [domainUserRoles]);

  const getDomainNicheTypeDefaultOption = () => {
    if (domainNicheTypeOptions && form && form.nicheTypeId) {
      const defaultValue = domainNicheTypeOptions.find((x) => x.value === form.nicheTypeId);

      return defaultValue;
    }

    return undefined;
  };

  const getUserRoleDefaultOption = () => {
    if (userRoleOptions && form && form.domainUserRoleTypeId) {
      const defaultValue = userRoleOptions.find((x) => x.value === form.domainUserRoleTypeId);

      return defaultValue;
    }

    return undefined;
  };

  const handleFormSubmit = () => {
    const formValues = getValues();
    onSubmit(formValues);
  };

  return (
    <Flex direction={'column'} gap={4}>
      <AppInput<DomainFormData>
        control={domainControl}
        name="domainName"
        label="Project Name"
        placeHolder="Domain Name"
        error={errors.domainName}
        variant="grayed"
      />
      <FormLabel p={0} m={0}>
        Project Niche
      </FormLabel>
      <SelectListFilter
        name="domainNicheType"
        w={'full'}
        showClear={true}
        isInModal
        isMulti={false}
        defaultOption={getDomainNicheTypeDefaultOption()}
        options={domainNicheTypeOptions}
        onSelectionChange={(item: SelectOption[]) => {
          if (item && item.length > 0) {
            setValue('nicheTypeId', item[0].value);
          } else {
            setValue('nicheTypeId', undefined);
          }
        }}
      />
      <AppInput<DomainFormData>
        control={domainControl}
        error={errors.url}
        label="Website Url"
        placeHolder="domain.com"
        name="url"
        variant="grayed"
      />

      <FormLabel p={0} m={0}>
        Your Role?
      </FormLabel>
      <SelectListFilter
        name="userRole"
        w={'full'}
        showClear={true}
        isInModal
        isMulti={false}
        defaultOption={getUserRoleDefaultOption()}
        options={userRoleOptions}
        onSelectionChange={(item: SelectOption[]) => {
          if (item && item.length > 0) {
            setValue('domainUserRoleTypeId', item[0].value);
          } else {
            setValue('domainUserRoleTypeId', undefined);
          }
        }}
      />
      {/* {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>
            Missing the Project Niche! - Please select a Project Niche & try again.
          </AlertTitle>
        </Alert>
      )} */}

      <AppButton
        w={'full'}
        mt={8}
        isDisabled={!isValid}
        onClick={handleFormSubmit}
        bgColor={AppColors.ctaColor}
        color={'white'}
      >
        Save
      </AppButton>
    </Flex>
  );
};

export default DomainCreateForm;
