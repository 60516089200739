import React, { useState } from 'react';
import { ButtonGroup, Flex, Icon, IconButton } from '@chakra-ui/react';
import DateRangeFilter, { DateRange } from './date-range-filter';
import { CloseIcon } from '@chakra-ui/icons';
import { AiOutlineGoogle } from 'react-icons/ai';
import { MdLink } from 'react-icons/md';
import { BsCardImage } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectBatches, selectTags } from '../../../../store/slices/domain.slice';
import {
  BackLinkListItem,
  BacklinkFilter,
  SelectOption,
  RelStatus,
  LinkStatus,
  GiOptionsValues,
  AppColors,
  LinkType,
  OrganizationTag,
  NotesFilterValues,
  SelectListFilter,
  AppButton,
  RelValues,
  ColumnDefinitionType,
  RobotsIndexStatus,
} from '@backlinkit/shared';
import { useAppSelector } from '../../../../store/store';
import { format } from 'date-fns';

export interface LinksFilterProps {
  isBatchTable: boolean;
  searchableColumns?: ColumnDefinitionType<BackLinkListItem>[];
  onFiltersChange?: (backlinkFilter: BacklinkFilter) => void;
}

const relOptions: SelectOption[] = [
  {
    label: 'FL',
    value: RelStatus.follow,
  },
  {
    label: 'NF',
    value: RelStatus.noFollow,
  },
  {
    label: 'UGC',
    value: RelValues.ugc,
  },
  {
    label: 'SPONSORED',
    value: RelValues.sponsored,
  },
];

const linkStatusOptions: SelectOption[] = [
  {
    label: '200',
    value: LinkStatus.Ok,
  },
  // {
  //   label: 'NHF',
  //   value: LinkStatus.LinkNotFound,
  // },
  // {
  //   label: 'ATM',
  //   value: LinkStatus.AnchorTextMismatch,
  // },
  {
    label: '403',
    value: LinkStatus.Unauthorized,
  },
  {
    label: '404',
    value: LinkStatus.NotFound,
  },
  {
    label: '410',
    value: LinkStatus.Gone,
  },
  {
    label: '500',
    value: LinkStatus.InternalError,
  },
];

const giOptions: SelectOption[] = [
  {
    label: 'Page Indexed',
    value: GiOptionsValues.PageIndexed,
    icon: <Icon color={'green'} as={AiOutlineGoogle} />,
  },
  {
    label: 'Domain Indexed',
    value: GiOptionsValues.DomainIndexed,
    icon: <Icon color={AppColors.secondary} as={AiOutlineGoogle} />,
  },
  {
    label: 'No Index',
    value: GiOptionsValues.NoIndex,
    icon: <Icon color={'orange'} as={AiOutlineGoogle} />,
  },
];

const linkTypeOptions: SelectOption[] = [
  {
    label: 'Link',
    value: LinkType.Link,
    icon: <Icon as={MdLink} />,
  },
  {
    label: 'Image',
    value: LinkType.Image,
    icon: <Icon as={BsCardImage} />,
  },
  {
    label: 'Redirect',
    value: LinkType.Redirect,
    icon: <Icon as={FiExternalLink} />,
  },
];

const robotIndexStatusOptions: SelectOption[] = [
  {
    label: 'Unset',
    value: RobotsIndexStatus.Unset,
  },
  {
    label: 'Indexed',
    value: RobotsIndexStatus.Indexed,
  },
  {
    label: 'No Index',
    value: RobotsIndexStatus.NoIndex,
  },
  {
    label: 'Follow',
    value: RobotsIndexStatus.follow,
  },
  {
    label: 'No Follow',
    value: RobotsIndexStatus.Nofollow,
  },
];

export enum LinkFilterSelectionType {
  LinkType = 'linkType',
  LinkStatus = 'linkStatus',
  RelStatus = 'relStatus',
  RobotStatus = 'robotStatus',
}

const LinksFilter: React.FC<LinksFilterProps> = ({ onFiltersChange, isBatchTable }) => {
  const [backlinkFilter, setBacklinkFilter] = useState<BacklinkFilter>({
    addedDateFrom: undefined,
    addedDateTo: undefined,
    tags: undefined,
    linkType: undefined,
    linkStatuses: undefined,
    relStatuses: undefined,
    hasPageIndex: undefined,
    hasDomainIndex: undefined,
    withNotes: undefined,
    backlinkBatchId: undefined,
  });
  // const [domainTagsFilters, setDomainTagsFilters] = useState<OrganizationTag[]>([]);
  const [selectedAddedDates, setSelectedAddedDates] = useState<Date[]>([]);
  const [showAddedDateClear, setShowAddedDateClear] = useState<boolean>(false);
  const [notesFilter, setNotesFilter] = useState<{
    with: boolean;
    without: boolean;
  }>({ with: false, without: false });
  const [googleCacheFilter, setGoogleCacheFilter] = useState<{
    with: boolean;
    without: boolean;
  }>({ with: false, without: false });
  const [googleIndexFilter, setGoogleIndexFilter] = useState<{
    with: boolean;
    without: boolean;
  }>({ with: false, without: false });
  const [errorFilter, setErrorFilter] = useState<{
    with: boolean;
    without: boolean;
  }>({ with: false, without: false });
  const [prospectFilter, setProspectFilter] = useState<{
    with: boolean;
    without: boolean;
  }>({ with: false, without: false });
  const domainBatches = useAppSelector(selectBatches);

  // const tags = useSelector(selectTags);

  const handleAddedDateChange = (dates: DateRange) => {
    console.log('handleAddedDateChange');
    if (dates?.startDate && dates?.endDate) {
      const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));
      copy.addedDateFrom = format(dates.startDate, 'yyyyMMdd');
      copy.addedDateTo = format(dates.endDate, 'yyyyMMdd');

      setShowAddedDateClear(true);
      setBacklinkFilter(copy);

      onFiltersChange && onFiltersChange(copy);
    } else {
      setShowAddedDateClear(false);
    }
  };

  const handleSelectionChange = (key: string, selection: SelectOption[]) => {
    const values = selection
      .filter((x) => x.value !== undefined)
      .map((x) => {
        return x.value;
      });

    const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));

    if (values && values.length > 0) {
      switch (key) {
        case LinkFilterSelectionType.LinkType:
          copy.linkType = values[0];
          break;
        case LinkFilterSelectionType.RobotStatus:
          copy.robotsIndexStatus = values[0];
          break;
        case LinkFilterSelectionType.LinkStatus: {
          const has200 = values.some((x) => x === LinkStatus.Ok);
          if (has200 && values) {
            copy.linkStatuses = [];
            const statusValues = [LinkStatus.LinkNotFound, LinkStatus.AnchorTextMismatch];
            const stringTransform = statusValues as string[];

            stringTransform.forEach((x) => {
              copy.linkStatuses?.push(x);
            });

            values.forEach((x) => {
              copy.linkStatuses?.push(x);
            });
          } else {
            copy.linkStatuses = values ? (values as string[]) : [];
          }

          break;
        }
        case LinkFilterSelectionType.RelStatus:
          copy.relStatuses = values ? (values as string[]) : [];
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case LinkFilterSelectionType.LinkType:
          copy.linkType = undefined;
          break;
        case LinkFilterSelectionType.RobotStatus:
          copy.robotsIndexStatus = undefined;
          break;
        case LinkFilterSelectionType.LinkStatus:
          copy.linkStatuses = undefined;
          break;
        case LinkFilterSelectionType.RelStatus:
          copy.relStatuses = undefined;
          break;
        default:
          break;
      }
    }

    setBacklinkFilter(copy);
    onFiltersChange && onFiltersChange(copy);
  };

  // const handleGiSelectionChange = (key: string, selection: SelectOption[]) => {
  //   const values = selection
  //     .filter((x) => x.value !== undefined)
  //     .map((x) => {
  //       return x.value;
  //     }) as string[];

  //   const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));

  //   if (values && values.length > 0) {
  //     values.forEach((x) => {
  //       switch (x) {
  //         case GiOptionsValues.DomainIndexed:
  //           if (!copy.hasDomainIndex) {
  //             copy.hasDomainIndex = true;
  //             copy.hasPageIndex = false;
  //           }
  //           break;
  //         case GiOptionsValues.PageIndexed:
  //           if (!copy.hasPageIndex) {
  //             copy.hasPageIndex = true;
  //             copy.hasDomainIndex = true;
  //           }
  //           break;
  //         case GiOptionsValues.NoIndex:
  //           copy.hasPageIndex = false;
  //           copy.hasDomainIndex = false;
  //           break;
  //       }
  //     });
  //   } else {
  //     copy.hasPageIndex = undefined;
  //     copy.hasDomainIndex = undefined;
  //   }

  //   setBacklinkFilter(copy);
  //   onFiltersChange && onFiltersChange(copy);
  // };

  const handleOnAddedDateClearClick = () => {
    setShowAddedDateClear(false);
    setSelectedAddedDates([]);

    const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));
    copy.addedDateFrom = undefined;
    copy.addedDateTo = undefined;
    setBacklinkFilter(copy);

    onFiltersChange && onFiltersChange(copy);
  };

  const handleOnHasNotes = (value: NotesFilterValues, flagValue: boolean) => {
    const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));
    copy.withNotes = undefined;
    switch (value) {
      case NotesFilterValues.WithNotes:
        copy.withNotes = notesFilter.with === true ? undefined : true;
        setNotesFilter({ without: false, with: !flagValue });

        break;
      case NotesFilterValues.WithoutNotes:
        copy.withNotes = notesFilter.without === true ? undefined : false;
        setNotesFilter({ with: false, without: !flagValue });
        break;
      default:
        copy.withNotes = undefined;
        break;
    }

    setBacklinkFilter(copy);

    onFiltersChange && onFiltersChange(copy);
  };

  // const handleOnHasGoogleCache = (value: NotesFilterValues, flagValue: boolean) => {
  //   const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));
  //   copy.hasGoogleCache = undefined;
  //   switch (value) {
  //     case NotesFilterValues.WithNotes:
  //       copy.hasGoogleCache = googleCacheFilter.with === true ? undefined : true;
  //       setGoogleCacheFilter({ without: false, with: !flagValue });

  //       break;
  //     case NotesFilterValues.WithoutNotes:
  //       copy.hasGoogleCache = googleCacheFilter.without === true ? undefined : false;
  //       setGoogleCacheFilter({ with: false, without: !flagValue });
  //       break;
  //     default:
  //       copy.hasGoogleCache = undefined;
  //       break;
  //   }

  //   setBacklinkFilter(copy);

  //   onFiltersChange && onFiltersChange(copy);
  // };

  const handleOnHasGoogleIndex = (value: NotesFilterValues, flagValue: boolean) => {
    const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));
    copy.hasPageIndex = undefined;
    switch (value) {
      case NotesFilterValues.WithNotes:
        copy.hasPageIndex = googleIndexFilter.with === true ? undefined : true;
        setGoogleIndexFilter({ without: false, with: !flagValue });

        break;
      case NotesFilterValues.WithoutNotes:
        copy.hasPageIndex = googleIndexFilter.without === true ? undefined : false;
        setGoogleIndexFilter({ with: false, without: !flagValue });
        break;
      default:
        copy.hasPageIndex = undefined;
        break;
    }

    setBacklinkFilter(copy);

    onFiltersChange && onFiltersChange(copy);
  };

  const handleOnHasErrors = (value: NotesFilterValues, flagValue: boolean) => {
    const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));
    copy.hasErrors = undefined;
    switch (value) {
      case NotesFilterValues.WithNotes:
        copy.hasErrors = errorFilter.with === true ? undefined : true;
        setErrorFilter({ without: false, with: !flagValue });

        break;
      case NotesFilterValues.WithoutNotes:
        copy.hasErrors = errorFilter.without === true ? undefined : false;
        setErrorFilter({ with: false, without: !flagValue });
        break;
      default:
        copy.hasErrors = undefined;
        break;
    }

    setBacklinkFilter(copy);

    onFiltersChange && onFiltersChange(copy);
  };

  const handleOnIsProspect = (value: NotesFilterValues, flagValue: boolean) => {
    const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));
    copy.isProspectLink = undefined;
    switch (value) {
      case NotesFilterValues.WithNotes:
        copy.isProspectLink = prospectFilter.with === true ? undefined : true;
        setProspectFilter({ without: false, with: !flagValue });

        break;
      case NotesFilterValues.WithoutNotes:
        copy.isProspectLink = prospectFilter.without === true ? undefined : false;
        setProspectFilter({ with: false, without: !flagValue });
        break;
      default:
        copy.isProspectLink = undefined;
        break;
    }

    setBacklinkFilter(copy);

    onFiltersChange && onFiltersChange(copy);
  };

  // const handleDomainTagsChange = (selection: SelectOption[]) => {
  //   console.log(selection);
  //   const tagIdsSelected = selection.map((x) => {
  //     return x.value || '';
  //   });
  //   const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));
  //   if (tagIdsSelected?.length > 0) {
  //     const newTagsFilter = tags.filter((x) => tagIdsSelected.includes(x.id));
  //     setDomainTagsFilters(newTagsFilter);
  //     copy.tags = tagIdsSelected;
  //     setBacklinkFilter(copy);

  //     onFiltersChange && onFiltersChange(copy);
  //   } else {
  //     setDomainTagsFilters([]);
  //     copy.tags = undefined;
  //     setBacklinkFilter(copy);

  //     onFiltersChange && onFiltersChange(copy);
  //   }
  // };

  const handleBatchChange = (selection: SelectOption[]) => {
    const selectedBatch = selection && selection.length > 0 ? selection[0] : undefined;
    const copy: BacklinkFilter = JSON.parse(JSON.stringify(backlinkFilter));

    if (selectedBatch) {
      copy.backlinkBatchId = selectedBatch.value;
      setBacklinkFilter(copy);
      onFiltersChange && onFiltersChange(copy);
    } else {
      copy.backlinkBatchId = undefined;
      setBacklinkFilter(copy);
      onFiltersChange && onFiltersChange(copy);
    }
  };

  return (
    <Flex py={4} wrap={'wrap'} gap={4}>
      <Flex>
        <DateRangeFilter
          placeholder="Added Date Range"
          dates={selectedAddedDates}
          onSelectedDatesChange={handleAddedDateChange}
        ></DateRangeFilter>
        {showAddedDateClear && (
          <IconButton
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            bg={AppColors.secondary}
            color={'white'}
            aria-label="Added Date Range clear button"
            isRound={false}
            onClick={handleOnAddedDateClearClick}
          >
            <Icon as={CloseIcon} boxSize={'1em'} color={'white'} />
          </IconButton>
        )}
      </Flex>
      {!isBatchTable && (
        <SelectListFilter
          minW={'200px'}
          isMulti={false}
          options={
            domainBatches &&
            domainBatches.map((batch) => {
              return { label: batch.name, value: batch.id.toString() };
            })
          }
          isFullWidth
          placeholder={'Batches'}
          onSelectionChange={(selection: SelectOption[]) => {
            handleBatchChange(selection);
          }}
        />
      )}
      {/* <SelectListFilter
        minW={'200px'}
        isMulti={true}
        options={
          tags &&
          tags.map((tag) => {
            return { label: tag.name, value: tag.id.toString() };
          })
        }
        placeholder={'Tags'}
        onSelectionChange={(selection: SelectOption[]) => {
          handleDomainTagsChange(selection);
        }}
        isInModal={true}
      /> */}
      <SelectListFilter
        minW={'200px'}
        isMulti={false}
        options={linkTypeOptions}
        placeholder={'Link Type'}
        isInModal={true}
        onSelectionChange={(selection: SelectOption[]) => {
          handleSelectionChange(LinkFilterSelectionType.LinkType, selection);
        }}
        isFullWidth
      />
      <SelectListFilter
        minW={'200px'}
        isMulti={false}
        options={robotIndexStatusOptions}
        placeholder={'Robots Meta Tag'}
        isInModal={true}
        onSelectionChange={(selection: SelectOption[]) => {
          handleSelectionChange(LinkFilterSelectionType.RobotStatus, selection);
        }}
        isFullWidth
      />
      <SelectListFilter
        minW={'200px'}
        isMulti={true}
        options={linkStatusOptions}
        placeholder={'Status'}
        isInModal={true}
        onSelectionChange={(selection: SelectOption[]) => {
          handleSelectionChange(LinkFilterSelectionType.LinkStatus, selection);
        }}
        isFullWidth
      />
      <SelectListFilter
        minW={'200px'}
        isMulti={true}
        options={relOptions}
        placeholder={'Rel'}
        isInModal={true}
        onSelectionChange={(selection: SelectOption[]) => {
          handleSelectionChange(LinkFilterSelectionType.RelStatus, selection);
        }}
        isFullWidth
      />
      {/* <SelectListFilter
        minW={'200px'}
        isMulti={false}
        options={giOptions}
        placeholder={'Google Index'}
        isInModal={true}
        onSelectionChange={(selection: SelectOption[]) => {
          handleGiSelectionChange('gi', selection);
        }}
      /> */}
      <ButtonGroup isAttached>
        <AppButton
          borderEndRadius={'0px'}
          fontWeight={'400'}
          borderRight={'1px solid white'}
          bgColor={AppColors.appBackground}
          isActive={notesFilter.with}
          onClick={() => {
            handleOnHasNotes(NotesFilterValues.WithNotes, notesFilter.with);
          }}
        >
          With Notes
        </AppButton>
        <AppButton
          borderStartRadius={'0px'}
          fontWeight={'400'}
          borderLeft={'1px solid white'}
          bgColor={AppColors.appBackground}
          isActive={notesFilter.without}
          onClick={() => {
            handleOnHasNotes(NotesFilterValues.WithoutNotes, notesFilter.without);
          }}
        >
          Without Notes
        </AppButton>
      </ButtonGroup>
      {/* <ButtonGroup isAttached>
        <AppButton
          borderEndRadius={'0px'}
          borderRight={'1px solid white'}
          isActive={googleCacheFilter.with}
          onClick={() => {
            handleOnHasGoogleCache(NotesFilterValues.WithNotes, googleCacheFilter.with);
          }}
        >
          Google Cached
        </AppButton>
        <AppButton
          borderStartRadius={'0px'}
          borderLeft={'1px solid white'}
          isActive={googleCacheFilter.without}
          onClick={() => {
            handleOnHasGoogleCache(NotesFilterValues.WithoutNotes, googleCacheFilter.without);
          }}
        >
          Google Not Cached
        </AppButton>
      </ButtonGroup> */}
      <ButtonGroup isAttached>
        <AppButton
          borderEndRadius={'0px'}
          fontWeight={'400'}
          bgColor={AppColors.appBackground}
          borderRight={'1px solid white'}
          isActive={googleIndexFilter.with}
          onClick={() => {
            handleOnHasGoogleIndex(NotesFilterValues.WithNotes, googleIndexFilter.with);
          }}
        >
          Google Index
        </AppButton>
        <AppButton
          borderStartRadius={'0px'}
          borderLeft={'1px solid white'}
          fontWeight={'400'}
          bgColor={AppColors.appBackground}
          isActive={googleIndexFilter.without}
          onClick={() => {
            handleOnHasGoogleIndex(NotesFilterValues.WithoutNotes, googleIndexFilter.without);
          }}
        >
          Google Not Indexed
        </AppButton>
      </ButtonGroup>
      <ButtonGroup isAttached>
        <AppButton
          fontWeight={'400'}
          borderEndRadius={'0px'}
          borderRight={'1px solid white'}
          bgColor={AppColors.appBackground}
          isActive={errorFilter.with}
          onClick={() => {
            handleOnHasErrors(NotesFilterValues.WithNotes, errorFilter.with);
          }}
        >
          With Errors
        </AppButton>
        <AppButton
          borderStartRadius={'0px'}
          borderLeft={'1px solid white'}
          isActive={errorFilter.without}
          bgColor={AppColors.appBackground}
          onClick={() => {
            handleOnHasErrors(NotesFilterValues.WithoutNotes, errorFilter.without);
          }}
        >
          Without Errors
        </AppButton>
      </ButtonGroup>
      <ButtonGroup isAttached>
        <AppButton
          fontWeight={'400'}
          borderEndRadius={'0px'}
          borderRight={'1px solid white'}
          bgColor={AppColors.appBackground}
          isActive={prospectFilter.with}
          onClick={() => {
            handleOnIsProspect(NotesFilterValues.WithNotes, prospectFilter.with);
          }}
        >
          Prospect Links
        </AppButton>
        <AppButton
          borderStartRadius={'0px'}
          borderLeft={'1px solid white'}
          isActive={prospectFilter.without}
          bgColor={AppColors.appBackground}
          onClick={() => {
            handleOnIsProspect(NotesFilterValues.WithoutNotes, prospectFilter.without);
          }}
        >
          Live Links
        </AppButton>
      </ButtonGroup>
    </Flex>
  );
};

export default LinksFilter;
