import { Box, Flex, Icon, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineGoogle } from 'react-icons/ai';
import { AppColors, AppText } from '@backlinkit/shared';
import { FaTruckLoading } from 'react-icons/fa';
import { format } from 'date-fns';

type GoogleIndexCellProps = {
  url: string;
  hasDomainIndex?: boolean;
  hasPageIndex?: boolean;
  googleCheckDate?: Date;
};

const CheckerGoogleIndexCell: React.FC<GoogleIndexCellProps> = ({
  url,
  hasDomainIndex,
  hasPageIndex,
  googleCheckDate,
}) => {
  if (hasDomainIndex === undefined && hasPageIndex === undefined) {
    return <></>;
  }

  const getToolTipLabel = () => {
    if (!googleCheckDate) {
      return 'In 24h';
    }

    const date = format(new Date(googleCheckDate), 'dd MMM yyyy hh:mm:ss a O');

    if (hasPageIndex) {
      return `Page Indexed - Last Checked - ${date}`;
    } else if (hasDomainIndex) {
      return `Domain Indexed - Last Checked - ${date}`;
    } else {
      return `No Index - Last Checked - ${date}`;
    }
  };

  const getIconColor = () => {
    if (!googleCheckDate) {
      return 'grey';
    }
    if (hasPageIndex) {
      return 'green';
    } else if (hasDomainIndex) {
      return AppColors.secondary;
    } else {
      return 'orange';
    }
  };

  return (
    <Box>
      <Tooltip label={getToolTipLabel()}>
        <span>
          {googleCheckDate ? (
            <Icon color={getIconColor()} as={AiOutlineGoogle} boxSize={'30px'} />
          ) : (
            <Icon color={getIconColor()} as={FaTruckLoading} boxSize={'30px'} />
          )}
        </span>
      </Tooltip>
    </Box>
  );
};

export default CheckerGoogleIndexCell;
