import React, { useEffect } from 'react';
import { Box, Flex, FlexProps, FormControl, FormLabel } from '@chakra-ui/react';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { AppColors } from '../../theme';
import { AppAlertItemList } from '../app-alert-list';

export type AppDatePickerProps<T extends FieldValues> = FlexProps & {
  control: Control<T>;
  name: Path<T>;
  error: FieldError | undefined;
  placeHolder?: string;
  label: string;
  minDate?: Date;
};

export const AppDatePicker = <T extends FieldValues>({
  control,
  name,
  error,
  placeHolder,
  label,
  minDate,
  ...props
}: AppDatePickerProps<T>) => {
  return (
    <Flex {...props}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl>
            <FormLabel fontSize={'14px'}>{label}</FormLabel>
            <SingleDatepicker
              name="date-input"
              date={value}
              onDateChange={onChange}
              configs={{
                dateFormat: 'dd MMM yyyy',
              }}
              minDate={minDate}
              propsConfigs={{
                dateNavBtnProps: {
                  colorScheme: '#000000',
                  padding: '0',
                  minW: '0',
                  height: '100%',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  color: '#555661',
                  lineHeight: 0.8,
                },
                dayOfMonthBtnProps: {
                  defaultBtnProps: {
                    _hover: {
                      background: AppColors.primary,
                      color: '#000000',
                    },
                    borderRadius: '50%',
                    fontSize: '0.75rem',
                    fontWeight: 'normal',
                    height: 6,
                    lineHeight: 6,
                    aspectRatio: '1/1',
                    padding: 0,
                    minW: 0,
                    justifySelf: 'center',
                    textAlign: 'center',
                  },
                  selectedBtnProps: {
                    background: AppColors.primary,
                    color: '#000000',
                  },
                  isInRangeBtnProps: {
                    background: AppColors.primary,
                    color: AppColors.innerContentColor,
                    _hover: {
                      background: AppColors.primary,
                      color: AppColors.innerContentColor,
                    },
                  },
                },
                popoverCompProps: {
                  popoverBodyProps: {
                    p: 7,
                    boxShadow:
                      '0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 4px 16px -1px rgba(0, 0, 0, 0.08)',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    borderRadius: '0.5rem',
                  },
                  popoverContentProps: {
                    background: AppColors.innerContentColor,
                    color: '#000000',
                    borderRadius: '0.5rem',
                  },
                },
                calendarPanelProps: {
                  wrapperProps: {
                    display: 'flex',
                    gap: 8,
                    width: 'fit-content',
                    height: 'min-content',
                  },
                  contentProps: { borderWidth: '0', padding: '0' },
                  headerProps: {
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  bodyProps: { width: 'max-content', gridGap: 2 },
                  dividerProps: { display: 'none' },
                },
                dateHeadingProps: { fontWeight: '500', width: 'max-content', lineHeight: 0.8 },
                weekdayLabelProps: { fontSize: '0.75rem', textAlign: 'center', fontWeight: '300' },

                // dateNavBtnProps: {
                //   colorScheme: AppColors.primary,
                // },
                // dayOfMonthBtnProps: {
                //   defaultBtnProps: {
                //     _hover: {
                //       background: AppColors.secondary,
                //       color: AppColors.innerContentColor,
                //     },
                //   },
                //   selectedBtnProps: {
                //     background: AppColors.secondary,
                //     color: AppColors.innerContentColor,
                //   },
                //   todayBtnProps: {
                //     background: AppColors.tertiary,
                //     color: AppColors.innerContentColor,
                //     _hover: {
                //       background: AppColors.secondary,
                //       color: AppColors.innerContentColor,
                //     },
                //   },
                // },
                inputProps: {
                  height: '60px',
                  borderRadius: '2xl',
                  border: 'none',
                  backgroundColor: 'rgba(177, 177, 177, 0.1)',
                  placeholder: 'Select Date',
                },
                // popoverCompProps: {
                //   popoverBodyProps: {
                //     p: '1px',
                //     borderWidth: 2,
                //   },
                //   popoverContentProps: {
                //     background: AppColors.innerContentColor,
                //     color: AppColors.primary,
                //   },
                // },
              }}
            />
            {error && error?.message && (
              <Box mt={2}>
                <AppAlertItemList
                  messages={[error?.message]}
                  alertType={'error'}
                ></AppAlertItemList>
              </Box>
            )}
          </FormControl>
        )}
      />
    </Flex>
  );
};
