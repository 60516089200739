import React from 'react';
import { useDisclosure, Flex } from '@chakra-ui/react';
import * as yup from 'yup';
import { BaseFormProps } from './base-forms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppButton, AppColors, AppInput, AppText } from '@backlinkit/shared';

export interface OTPFormData {
  otp?: number;
}

const otpFormDataSchema = yup.object({
  otp: yup
    .number()
    .typeError(
      'Please enter a valid OTP. Ensure it is a number and use the OTP provided in the email.'
    )
    .required('Field is required'),
});

const otpDefaultValues: OTPFormData = {
  otp: undefined,
};

type OTPFormProps<T> = BaseFormProps<T>;

const OTPForm: React.FC<OTPFormProps<OTPFormData>> = ({ isLoading, onSubmit }, pageUrl: string) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<OTPFormData>({
    defaultValues: otpDefaultValues,
    resolver: yupResolver(otpFormDataSchema),
    mode: 'all',
  });
  const { onClose } = useDisclosure({ defaultIsOpen: true });

  const handleFormSubmit = (formData: OTPFormData) => {
    onSubmit(formData);
    onClose();
  };

  return (
    <>
      <Flex
        minH={'max-content'}
        w={{ md: '380px', lg: '600px' }}
        fontWeight={'medium'}
        p={8}
        py={12}
        flexDir={'column'}
        justify={'center'}
        gap={12}
        bg={'white'}
        borderRadius={'40px'}
        boxShadow={'lg'}
      >
        <AppText alignSelf={'center'} variant={'h2'}>
          Enter OTP
        </AppText>
        <AppInput<OTPFormData>
          control={control}
          inputType="number"
          name="otp"
          placeHolder="OTP"
          error={errors.otp}
        />
        <AppButton
          isDisabled={!isValid}
          loadingText="Checking OTP"
          isLoading={isLoading}
          onClick={handleSubmit(handleFormSubmit)}
          gap={3}
          bgColor={AppColors.secondary2}
          color={'white'}
        >
          Enter
        </AppButton>
      </Flex>
    </>
  );
};

export default OTPForm;
