import {
  Flex,
  useToast,
  useColorModeValue,
  Image,
  Text,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DomainActionsMenu from '../app/app-menus/domain-actions-menu';
import DomainCreateForm, { DomainFormData } from '../forms/domain-create-form';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  AppPaginator,
  AppText,
  CellClickEvent,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  DomainViewModel,
  PageChangeEvent,
  PaginatedData,
  Pagination,
  SearchParam,
  SortParam,
  TableSearchEventProps,
  searchingColumnsToSearchParam,
  sortingColumnsToSortingArray,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import { RoutesList } from '../../router/router';
import {
  useDeleteDomainMutation,
  useEditDomainMutation,
  useLazyFetchDomainsByOptionsQuery,
  useLazyFetchDomainsByOrgIdQuery,
  useSaveDomainMutation,
} from '../../store/api/domainApi';
import { selectUser } from '../../store/slices/authentication.slice';
import { setSelectedDomain } from '../../store/slices/domain.slice';
import { useAppSelector } from '../../store/store';
import { useLazyFetchBatchesByDomainQuery } from '../../store/api/backlinkBatchesApi';
import { FaEdit } from 'react-icons/fa';
import { Can } from '../../providers/permissions-provider';

const Domains: React.FC = () => {
  const title = 'Domains';
  const { setLoading } = useLoading();
  const user = useAppSelector(selectUser);

  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [currentPageSize, setCurrentPageSize] = useState<number | undefined>(5);
  const [currentSortList, setCurrentSortList] = useState<SortParam[]>([]);

  const [currentSearchParam, setCurrentSearchParam] = useState<SearchParam>();

  const [dashboardDomains, setDashboardDomains] = useState<PaginatedData<DomainViewModel>>();

  const [getDomainsByOptions] = useLazyFetchDomainsByOptionsQuery();
  const [getDomainsByOrgId] = useLazyFetchDomainsByOrgIdQuery();
  const [deleteDomainTrigger] = useDeleteDomainMutation();
  const [getBatchesByDomain] = useLazyFetchBatchesByDomainQuery();
  const [saveDomainTrigger, { isLoading: isDomainAddSaving }] = useSaveDomainMutation();

  const [editDomainTrigger, { isLoading: isDomainEditSaving }] = useEditDomainMutation();

  const [pagination, setPagination] = useState<Pagination>();

  const dispatch = useDispatch();
  const toast = useToast();
  const dialog = useDialog();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPage && currentPageSize) {
      setupDomains(currentPage, currentPageSize, currentSortList, currentSearchParam);
    }
  }, [currentPage, currentPageSize]);

  useEffect(() => {
    if (currentSortList && currentSearchParam) {
      setupDomains(currentPage, currentPageSize, currentSortList, currentSearchParam);
    }
  }, [currentSortList, currentSearchParam]);

  useEffect(() => {
    if (dashboardDomains?.pagination) {
      console.log('dashboardDomains?.pagination ', dashboardDomains?.pagination);
      setPagination(dashboardDomains?.pagination);
    }
  }, [dashboardDomains]);

  const setupDomains = async (
    page: number | undefined,
    pageSize: number | undefined,
    sortList: SortParam[] | undefined,
    searchParam: SearchParam | undefined
  ) => {
    try {
      setLoading(true);
      setDashboardDomains(undefined);
      const domainList = await getDomainsByOptions({
        organizationId: user?.organizationId?.toString() ?? '',
        pageNumber: page,
        pageSize,
        searchParam,
        sortParams: sortList,
      }).unwrap();

      setDashboardDomains(domainList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBacklinkItClick = async (domain: DomainViewModel) => {
    handleDomainSelection(domain, RoutesList.Batches);
  };

  const handleProfileClick = async (domain: DomainViewModel) => {
    handleDomainSelection(domain, RoutesList.DomainProfile);
  };

  const handleDomainSelection = async (domain: DomainViewModel, route: RoutesList) => {
    getBatchesByDomain(domain.id);
    await dispatch(setSelectedDomain(domain));
    navigate(route);
  };

  const handleEditDialog = (domain: DomainViewModel) => {
    const domainForm = {
      id: domain.id,
      domainName: domain.name,
      url: domain.url,
      nicheTypeId: domain.nicheTypeId,
      domainUserRoleTypeId: domain.domainUserRoleTypeId,
    };
    dialog({
      title: 'Edit Domain',
      size: 'xl',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <DomainCreateForm
            form={domainForm}
            onSubmit={(formData) => {
              onSubmit();
              saveDomain(formData, domain);
            }}
          ></DomainCreateForm>
        );
      },
    });
  };

  const handleErrorListDialog = async (domain: DomainViewModel) => {
    getBatchesByDomain(domain.id);
    await dispatch(setSelectedDomain(domain));
    navigate(RoutesList.Links, { state: { hasErrors: true } });

    // const errorList: AlertListItem[] = [];
    // domain.backlinks?.forEach((backLink) => {
    //   if (backLink.errors) {
    //     const errors: AlertListItem[] = backLink.errors.map((error) => {
    //       return {
    //         name: backLink.url,
    //         alertType: error.errorType,
    //         alertMessage: error.errorMessage,
    //       };
    //     });
    //     errorList.push(...errors);
    //   }
    // });
    // panel({
    //   title: `${domain.name} Errors`,
    //   size: 'xl',
    //   render: (onSubmit) => {
    //     if (domain.errorCount && domain.errorCount > 0) {
    //       return <AppAlertList items={errorList} showName={true} showType={true}></AppAlertList>;
    //     } else {
    //       return (
    //         <AppText fontSize={'lg'} fontWeight={'500'}>
    //           There are no errors!
    //         </AppText>
    //       );
    //     }
    //   },
    // });
  };

  const domainColumns: ColumnDefinitionType<DomainViewModel>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: true,
    },
    {
      key: 'name',
      header: 'Name',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'url',
      header: 'Domain',
      cellDataClickable: true,
      headerSortable: true,
      columnSearchable: true,
      render: (item) => {
        return (
          <Flex direction={'row'}>
            <Image
              minW={'20px'}
              src={`https://www.google.com/s2/favicons?domain=${item?.url}`}
              alt=""
            />
            <AppText ml={2}>{item.url}</AppText>
          </Flex>
        );
      },
    },
    // {
    //   key: 'domainCreatedDate',
    //   header: 'Domain Age',
    //   render: (link) => {
    //     if (link.domainCreatedDate) {
    //       return (
    //         <LinkDomainAgeCell originalDomainCreationDate={link.domainCreatedDate} url={link.url} />
    //       );
    //     } else {
    //       return <></>;
    //     }
    //   },
    // },
    {
      key: 'backlinkCount',
      header: 'Backlinks',
      headerSortable: false,
      render: (item) => {
        return (
          <Box>
            <AppButton
              onClick={() => {
                handleBacklinkItClick(item);
              }}
              borderRadius="full"
              bgColor={AppColors.secondary2}
              textColor={'white'}
              p={2}
            >
              <AppText cursor={'pointer'} fontSize={'16px'}>
                {item?.backlinkCount}
              </AppText>
            </AppButton>
          </Box>
        );
      },
    },
    {
      key: 'backlinkErrorCount',
      headerSortable: false,
      header: 'Errors',
      render: (item) => {
        return (
          <AppButton borderRadius="full" bgColor={'rgba(255, 0, 0, 0.1)'} p={2}>
            <AppText
              cursor={'pointer'}
              onClick={() => handleErrorListDialog(item)}
              color={'red !important'}
              fontWeight={'500'}
            >
              {item?.backlinkErrorCount}
            </AppText>
          </AppButton>
        );
      },
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      cellDataClickable: false,
      render: (item) => {
        return (
          <Flex flexDirection={'row'}>
            <Can permission={'Backlinks'} permissionLevel="Write">
              <AppButton
                mr={4}
                variant={'ghost'}
                border={`1px solid ${AppColors.iconColorOne}`}
                borderRadius="full"
                isLoading={isDomainEditSaving}
                onClick={() => {
                  handleEditDialog(item);
                }}
                rightIcon={<FaEdit color={AppColors.outlineColor} />}
                color={AppColors.outlineColor}
              >
                Edit
              </AppButton>
            </Can>
            <Can permission={'Backlinks'} permissionLevel="Read">
              <AppButton
                mr={4}
                variant={'ghost'}
                border={`1px solid ${AppColors.iconColorOne}`}
                borderRadius="full"
                onClick={() => {
                  handleProfileClick(item);
                }}
                rightIcon={<FaEdit color={AppColors.outlineColor} />}
                color={AppColors.outlineColor}
              >
                Domain Profile
              </AppButton>
              <AppButton
                variant={'ghost'}
                border={`1px solid ${AppColors.iconColorOne}`}
                borderRadius="full"
                onClick={() => {
                  handleBacklinkItClick(item);
                }}
                color={AppColors.outlineColor}
                rightIcon={<FaEdit color={AppColors.outlineColor} />}
              >
                Link Batches
              </AppButton>
            </Can>
          </Flex>
        );
      },
    },
  ];

  const [tableColumns, setTableColumns] =
    useState<ColumnDefinitionType<DomainViewModel>[]>(domainColumns);

  const [selectedDomains, setSelectedDomains] = useState<DomainViewModel[]>([]);

  const handleTableRowsSelectedChange = (selection: DomainViewModel[]) => {
    setSelectedDomains(selection);
  };

  const saveDomain = async (domainFormData: DomainFormData, model?: DomainViewModel) => {
    try {
      if (!user) {
        toast({
          title: 'User account not found.',
          description: 'Sign out and back in.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
      }

      setLoading(true);

      if (model && model.id) {
        await editDomainTrigger({
          id: model.id,
          organizationId: model.organizationId,
          createdUserId: model.createdUserId,
          dateCreated: model.dateCreated,
          domainCreatedDate: model.domainCreatedDate,
          lastModified: model.lastModified,
          lastModifiedUserId: model.lastModifiedUserId,
          name: domainFormData.domainName,
          url: domainFormData.url,
          domainUserRoleTypeId: domainFormData.domainUserRoleTypeId,
          nicheTypeId: domainFormData.nicheTypeId,
        });

        setLoading(false);
        toast({
          title: 'Domain updated.',
          description: "We've updated your domain for you",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        await saveDomainTrigger({
          name: domainFormData.domainName,
          url: domainFormData.url,
          organizationId: user.organizationId.toString(),
          domainUserRoleTypeId: domainFormData.domainUserRoleTypeId,
          nicheTypeId: domainFormData.nicheTypeId,
        }).unwrap();

        setLoading(false);
        toast({
          title: 'Domain added.',
          description: "We've added your domain for you, come back soon to monitor the status",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }

      setupDomains(currentPage, currentPageSize, currentSortList, currentSearchParam);
      getDomainsByOrgId(user.organizationId);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddDialog = () => {
    dialog({
      title: 'Create Domain',
      size: '2xl',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <DomainCreateForm
            onSubmit={(formData) => {
              onSubmit();
              saveDomain(formData);
            }}
          ></DomainCreateForm>
        );
      },
    });
  };

  const handleTableCellClick = async (clickEvent: CellClickEvent<DomainViewModel>) => {
    switch (clickEvent.column.key) {
      case 'backlinkCount':
      case 'url':
        window.open(clickEvent.row.url, 'blank');
        break;
      default:
        break;
    }
  };

  const handleDeleteDomainsClicked = () => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selectedDomains.length} selected Domains?`}
            onConfirm={async () => {
              onSubmit();

              await handleDeleteDomains(selectedDomains);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleSortByColumnClick = (columns: ColumnDefinitionType<DomainViewModel>[]) => {
    const columnSortQueryString = sortingColumnsToSortingArray(columns);
    setCurrentSortList(columnSortQueryString);

    console.log(columnSortQueryString);
  };

  const handleDeleteDomains = async (domains: DomainViewModel[]) => {
    try {
      const deletePromises = domains.map((domain) => deleteDomainTrigger(domain.id).unwrap());
      await Promise.all(deletePromises);
    } catch (e) {
      toast({
        title: 'Domain Delete error.',
        description:
          "We've run into a problem deleting the selected domains, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected Domains deleted.',
      description: "We've deleted the domains you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    setupDomains(currentPage, currentPageSize, currentSortList, currentSearchParam);
    if (user) getDomainsByOrgId(user.organizationId);
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    console.log(pageChangeEvent);
    setCurrentPage(pageChangeEvent.currentPage);
    setCurrentPageSize(pageChangeEvent.currentPageSize);
  };

  const handleSearchChange = (event: TableSearchEventProps<DomainViewModel>) => {
    const searchObjects = searchingColumnsToSearchParam(event);
    setCurrentSearchParam(searchObjects);

    console.log(searchObjects);
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });

  return (
    <>
      <Flex
        flexDir={variant === 'desktop' ? 'row' : 'column'}
        px={8}
        justifyContent={'space-between'}
        bgColor={AppColors.secondary2}
        minH={'105px'}
        align={'center'}
        borderTopRadius={'3xl'}
      >
        <Flex flexDir={'column'}>
          <Text color={'white'} fontWeight="bold" fontSize="2xl">
            {title}
          </Text>
          <AppText color={'white'}>List of Tracked Domains</AppText>
        </Flex>
        <Can permission={'Backlinks'} permissionLevel="Write">
          <Flex alignItems={'center'} height={'75px'}>
            <DomainActionsMenu
              isDisabled={selectedDomains.length === 0}
              onDeleteDomains={handleDeleteDomainsClicked}
            />
            <AppButton
              height={'50px'}
              borderRadius={'full'}
              isLoading={isDomainAddSaving}
              loadingText={'Adding Domain'}
              onClick={handleAddDialog}
            >
              Add Domain
            </AppButton>
          </Flex>
        </Can>
      </Flex>

      <Flex py={6}>
        <Flex
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          px={'4'}
          flexDir={'column'}
        >
          <AppDataTable
            data={dashboardDomains?.data || []}
            noDataMessage={'No domains added, start by adding some!'}
            columns={tableColumns}
            selectableRows={true}
            onRowSelected={handleTableRowsSelectedChange}
            onTableCellClick={handleTableCellClick}
            onSearchInputChange={(event: TableSearchEventProps<DomainViewModel>) => {
              handleSearchChange(event);
            }}
            onSortByColumnClick={(event: ColumnDefinitionType<DomainViewModel>[]) =>
              handleSortByColumnClick(event)
            }
          />
          <AppPaginator
            pagination={pagination}
            onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
          ></AppPaginator>
        </Flex>
      </Flex>
    </>
  );
};

export default Domains;
