import {
  Flex,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { protocolRegex } from '../../../../constants/regex';
import { AppColors, AppText } from '@backlinkit/shared';
import { HiOutlineClipboardCopy } from 'react-icons/hi';

type UrlCellProps = {
  url?: string;
  isTruncated?: boolean;
};

const CheckerUrlCell: React.FC<UrlCellProps> = ({ url, isTruncated = true, ...props }) => {
  const toast = useToast();
  const handleCopyToClipboardClick = () => {
    if (url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          toast({
            title: 'Copied to clipboard',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        })
        .catch((e) => {
          toast({
            title: 'Failed to copy to clipboard',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };
  return (
    <Flex direction={'row'} alignItems={'center'}>
      {url && (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Flex>
              <Image
                minW={'20px'}
                objectFit={'contain'}
                src={`https://www.google.com/s2/favicons?domain=${url}`}
                alt=""
              />

              <AppText
                ml={2}
                maxW={isTruncated ? '300px' : '100%'}
                isTruncated={isTruncated}
                _hover={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                {url ? url.replace(protocolRegex, '') : ''}
              </AppText>
            </Flex>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody display={'flex'} flexDir={'row'} alignItems={'center'}>
              <IconButton
                bg={AppColors.secondary}
                color={'white'}
                aria-label="column visibility button"
                isRound={false}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                size="xs"
                mr={'15px'}
                onClick={(event) => {
                  event.stopPropagation();
                  handleCopyToClipboardClick();
                }}
              >
                <Icon as={HiOutlineClipboardCopy} size="1rem" color={'white'} />
              </IconButton>
              <Flex>
                <AppText ml={2} whiteSpace={'pre-wrap'} wordBreak={'break-word'} cursor={'pointer'}>
                  {url}
                </AppText>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
};

export default CheckerUrlCell;
