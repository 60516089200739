import React from 'react';
import { Flex, Image, useToast, useMediaQuery } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useUserAuthenticationMutation } from '../../store/api/authenticationApi';
import { setUserAuth, logout } from '../../store/slices/authentication.slice';
import { HttpError, AppText } from '@backlinkit/shared';
import LoginDialogForm, { LoginFormData } from '../../components/forms/login-dialog-form';

export type LoginDialogProps = {
  onClose: () => void;
};

const LoginDialog: React.FC<LoginDialogProps> = ({ onClose }) => {
  const toast = useToast();

  const [userAuthentication, { isLoading }] = useUserAuthenticationMutation();

  const dispatch = useDispatch();

  const signIn = async (loginFormData: LoginFormData) => {
    try {
      await dispatch(logout());

      const res = await userAuthentication({
        username: loginFormData.email,
        password: loginFormData.password,
      }).unwrap();

      dispatch(setUserAuth(res));
      localStorage.setItem(`bck_id_token`, res.token);

      if (res && res.user && res.user.organization && res.user.organization.subscriptionIsActive) {
        onClose();
      } else {
        toast({
          title: 'Your trail has ended!',
          description:
            'The owner of this organization will need to subscribe to one of our products.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (errResponse) {
      console.error(errResponse);
      const httpError: HttpError = (errResponse as any).data as HttpError;

      toast({
        title: 'Login Failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      flexDir={'row-reverse'}
      w={'100%'}
      justify={'space-evenly'}
      height={'100%'}
      align={'center'}
    >
      <Flex
        flexDir={'column'}
        bg={'white'}
        justify={'center'}
        align={'center'}
        borderRadius={'40px'}
        p={{ base: 4, sm: 6, md: 8 }}
        gap={8}
        w={'100%'}
        maxW={'650px'}
        height={`calc(100vh - 10%)`}
        my={{ base: 4, sm: 6, md: 8 }}
      >
        <Flex
          as={'form'}
          mt={10}
          width={'100%'}
          flexDir={'column'}
          justify={'center'}
          align={'center'}
        >
          <LoginDialogForm isLoading={isLoading} onSubmit={signIn}></LoginDialogForm>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginDialog;
