import React from 'react';
import { Flex, Spinner, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppInput, AppButton } from '@backlinkit/shared';
import { AppColors } from '@backlinkit/shared';

export type LoginFormData = {
  email: string;
  password: string;
};

export const loginFormDataDefaultValues: LoginFormData = {
  email: '',
  password: '',
};

const loginFormDataSchema = yup.object({
  email: yup.string().required('Field is required'),
  password: yup.string().required('Field is required'),
});

type LoginDialogFormProps<T> = BaseFormProps<T>;

const LoginDialogForm: React.FC<LoginDialogFormProps<LoginFormData>> = ({
  isLoading,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    defaultValues: loginFormDataDefaultValues,
    resolver: yupResolver(loginFormDataSchema),
    mode: 'all',
  });

  const handleFormSubmit = (formData: LoginFormData) => {
    onSubmit(formData);
  };

  return (
    <>
      <Stack spacing={4} maxW={'555px'} w={'100%'}>
        <AppInput<LoginFormData>
          control={control}
          name="email"
          placeHolder="Email"
          error={errors.email}
          variant="default"
          label="Email"
        />
        <AppInput<LoginFormData>
          inputType="password"
          control={control}
          placeHolder="Password"
          error={errors.password}
          name="password"
          variant="default"
          label="Password"
        />
        <Stack spacing={4}>
          <AppButton
            onClick={handleSubmit(handleFormSubmit)}
            fontFamily={'heading'}
            mt={8}
            height={'50px'}
            w={'full'}
            bgColor={AppColors.secondary2}
            color={'white'}
            _hover={{
              bgGradient: 'linear(to-r, #00a4ff,#037DFC)',
              boxShadow: 'xl',
            }}
            fontSize={'16px'}
          >
            {isLoading ? (
              <Flex gap={'10px'} align={'center'}>
                <Spinner /> Logging in
              </Flex>
            ) : (
              'Sign in'
            )}
          </AppButton>
        </Stack>
      </Stack>
    </>
  );
};

export default LoginDialogForm;
