import { useEffect, useState } from 'react';
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import {
  useFetchUsersByOptionsQuery,
  useUserCreateMutation,
  useUserDeleteMutation,
  useUserUpdateMutation,
} from '../../../store/api/userApi';
import { selectUser } from '../../../store/slices/authentication.slice';
import UserCreateForm, { UserCreateFormData } from '../../forms/user-create-form';
import UserEditForm, { UserEditFormData } from '../../forms/edit-user-form';
import {
  AppColors,
  AppDataTable,
  AppLoader,
  AppPaginator,
  AppText,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  User,
  useDataTable,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import { Can } from '../../../providers/permissions-provider';
import { useAppSelector } from '../../../store/store';

const UsersTable: React.FC = () => {
  const user = useAppSelector(selectUser);
  const [saveUserTrigger] = useUserCreateMutation();
  const [editUserTrigger] = useUserUpdateMutation();
  const [deleteUserTrigger] = useUserDeleteMutation();

  const dialog = useDialog();
  const toast = useToast();
  const { setLoading } = useLoading();

  const {
    handleOnPageChange,
    handleSortByColumnClick,
    handleSearchChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
  } = useDataTable<User>({});

  const {
    data: userApiData,
    isLoading,
    refetch: refetchUsers,
  } = useFetchUsersByOptionsQuery(
    {
      organizationId: user?.organizationId?.toString() ?? '',
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (userApiData?.pagination) {
      setPagination(userApiData?.pagination);
    }
  }, [userApiData]);

  const renderDefaultTableActions = (item: User) => {
    return (
      <Can permission={'OrganizationUsers'} permissionLevel="Write">
        <Menu>
          <MenuButton
            bgColor={'white'}
            color={'gray.700'}
            border={`1px solid ${AppColors.appBorder}`}
            as={Button}
            rightIcon={<ChevronDownIcon color={'gray.700'} boxSize={'15px'} />}
            borderRadius="full"
            minH={'40px'}
            fontWeight={'400'}
          >
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                handleEditDialog(item);
              }}
              _hover={{
                bgColor: 'transparent',
              }}
            >
              <Flex
                align={'center'}
                w={'100%'}
                gap={4}
                p={1}
                borderRadius={'md'}
                _hover={{
                  bgColor: AppColors.appBackground,
                }}
              >
                <Flex p={1} bgColor={'rgba(0, 0, 0, 0.2)'} borderRadius={'md'}>
                  <SettingsIcon />
                </Flex>
                Edit
              </Flex>
            </MenuItem>
            {user?.id !== item.id && (
              <MenuItem
                onClick={() => {
                  handleDeleteUser(item);
                }}
                _hover={{
                  bgColor: 'transparent',
                }}
              >
                <Flex
                  align={'center'}
                  gap={4}
                  p={1}
                  width={'100%'}
                  _hover={{
                    bgColor: AppColors.appBackground,
                  }}
                  borderRadius={'md'}
                >
                  <Flex p={1} bgColor={'rgba(0, 0, 0, 0.2)'} borderRadius={'md'}>
                    <DeleteIcon />
                  </Flex>
                  Delete
                </Flex>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Can>
    );
  };

  const renderRole = (item: User) => {
    return (
      <Flex
        borderRadius="full"
        bg={AppColors.primary}
        color={'white'}
        p={2}
        justify={'center'}
        align={'center'}
        maxW={'120px'}
      >
        <AppText color={'white'} fontSize={'14px'}>
          {item.role?.name}
        </AppText>
      </Flex>
    );
  };

  const userColumns: ColumnDefinitionType<User>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'role',
      header: 'Role',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => renderRole(item),
    },
    {
      key: 'email',
      header: 'email',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  const [tableColumns, setTableColumns] = useState<ColumnDefinitionType<User>[]>(userColumns);

  const handleEditDialog = (selectedUser: User) => {
    const isOwner = user?.organization.createdUserId === selectedUser.id;
    const UserForm: UserEditFormData = {
      id: selectedUser.id.toString(),
      email: selectedUser.email,
      roleId: selectedUser.roleId,
      organizationId: user!.organizationId,
    };
    dialog({
      title: 'Edit User',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <UserEditForm
            isOwner={isOwner}
            form={UserForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertUser(formData);
            }}
          ></UserEditForm>
        );
      },
    });
  };

  const handleAddDialog = () => {
    dialog({
      title: 'Create User',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <UserCreateForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertUser(formData);
            }}
          ></UserCreateForm>
        );
      },
    });
  };

  const upsertUser = async (formData: any) => {
    let edittedUser = formData.id ? true : false;
    try {
      setLoading(true);
      if (!edittedUser) {
        const createFormData = formData as UserCreateFormData;
        await saveUserTrigger({
          email: createFormData.email,
          password: createFormData.password,
          roleId: createFormData.roleId,
          organizationId: user!.organizationId,
        }).unwrap();
      } else {
        const editFormData = formData as UserEditFormData;
        await editUserTrigger({
          id: editFormData.id ?? '',
          email: editFormData.email,
          roleId: editFormData.roleId,
          organizationId: user!.organizationId,
        }).unwrap();
        edittedUser = true;
      }

      toast({
        title: `User ${!edittedUser ? 'added' : 'updated'}`,
        description: `We've ${
          !edittedUser ? 'added' : 'updated'
        } your user for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchUsers();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
    refetchUsers();
  };

  const handleDeleteUser = async (user: User) => {
    try {
      const deletePromise = deleteUserTrigger(user.id).unwrap();
      await deletePromise;
    } catch (err) {
      toast({
        title: 'User Delete Error',
        description: `We've run into a problem deleting the selected user. Contact support for help`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'User deleted',
      description: `We've deleted the user you selected`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    refetchUsers();
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <>
      <Flex justifyContent={'space-between'} m={4}>
        {isLoading && <AppLoader />}
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
          {'Users'}
        </Text>
        <Flex alignItems={'center'}>
          <Can permission={'OrganizationUsers'} permissionLevel="Write">
            <Button
              onClick={handleAddDialog}
              size="md"
              height={'50px'}
              borderRadius={'full'}
              bgColor={'white'}
              border={'1px solid rgba(255, 136, 0, 0.5)'}
              color={AppColors.ctaColor}
              fontWeight={'500'}
            >
              + Add User
            </Button>
          </Can>
        </Flex>
      </Flex>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        rounded={'2xl'}
        m={4}
        flexDir={'column'}
        flex={1}
      >
        <AppDataTable
          data={userApiData?.data || []}
          noDataMessage={'No tags added, start by adding some!'}
          columns={tableColumns}
          onSearchInputChange={handleSearchChange}
          onSortByColumnClick={handleSortByColumnClick}
        />

        <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
      </Flex>
    </>
  );
};

export default UsersTable;
