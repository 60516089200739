import React, { useEffect, useState } from 'react';
import { Box, Flex, FormLabel, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { AppButton, AppColors, SelectListFilter, SelectOption } from '@backlinkit/shared';
import { useClientDialog } from '../providers/client-dialog/use-client-dialog';

import { RiSettings5Fill } from 'react-icons/ri';
import { useAppSelector } from '../store/store';
import { selectOrganizationTags } from '../store/slices/tag.slice';
import TagManagement from './dialog-management/tag-management';
import { iconHelper } from '../utils/iconHelper';

type BaacklinkTagDropDownComponentProps = {
  setSelectedTags: (tagIds?: string[]) => void;
};

const BaacklinkTagDropDownComponent: React.FC<BaacklinkTagDropDownComponentProps> = ({
  setSelectedTags,
}) => {
  const clientDialog = useClientDialog();
  const tags = useAppSelector(selectOrganizationTags);
  const [currentTags, setCurrentTags] = useState<SelectOption[]>([]);

  const handleAddTags = (items: SelectOption[]) => {
    const tags: string[] = items.map((x) => {
      return x.value;
    });
    setSelectedTags(tags);
  };

  useEffect(() => {
    if (tags) {
      const selectors: SelectOption[] = tags.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setCurrentTags(selectors);
    }
  }, [tags]);

  const handleTagManagement = () => {
    clientDialog({
      title: `Tag Management`,
      position: 3,
      showCancel: true,
      size: '4xl',
      render: (onSubmit) => {
        return <TagManagement />;
      },
    });
  };

  return (
    <Flex alignItems={'end'}>
      <Box flex={1}>
        <FormLabel mb={2} p={0}>
          Tags
        </FormLabel>
        <SelectListFilter
          mr={2}
          name="backlinkTags"
          options={currentTags}
          isInModal
          isMulti
          onSelectionChange={(items: SelectOption[]) => {
            handleAddTags(items);
          }}
        />
      </Box>

      <Tooltip shouldWrapChildren label={'Manage Tags'} aria-label="manageTags">
        <IconButton
          aria-label="add-edit-tags"
          px={3}
          bgColor={AppColors.appBackground}
          color={'black'}
          onClick={() => handleTagManagement()}
          boxSize={'54px'}
          borderRadius={'xl'}
        >
          <Icon as={iconHelper.settingsIcon} height={'25px'} width={'25px'} color={'gray.400'} />
        </IconButton>
      </Tooltip>
    </Flex>
  );
};

export default BaacklinkTagDropDownComponent;
