import React, { useEffect, useState } from 'react';
import { Flex, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import {
  AppButton,
  AppColors,
  SelectListFilter,
  SelectOption,
  useDialog,
} from '@backlinkit/shared';

import { RiSettings5Fill } from 'react-icons/ri';
import { useFetchBatchesByDomainQuery } from '../store/api/backlinkBatchesApi';
import BatchManagement from './dialog-management/backlink-batches-management';
import { iconHelper } from '../utils/iconHelper';
import { useClientDialog } from '../providers/client-dialog/use-client-dialog';

type BatchDropDownComponentProps = {
  domainId: string;
  setSelectedBatchId: (batchId?: string) => void;
};

const BatchDropDownComponent: React.FC<BatchDropDownComponentProps> = ({
  domainId,
  setSelectedBatchId,
}) => {
  const clientDialog = useClientDialog();
  const [batchOptions, setBatchOptions] = useState<SelectOption[]>([]);

  const { data: batches } = useFetchBatchesByDomainQuery(domainId);

  useEffect(() => {
    if (batches) {
      const options: SelectOption[] = batches.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });

      setBatchOptions(options);
    }
  }, [batches]);

  const handleBatchManagement = () => {
    clientDialog({
      title: `Batch Management`,
      position: 3,
      size: '4xl',
      render: (onSubmit) => {
        return <BatchManagement />;
      },
    });
  };

  return (
    <Flex>
      <SelectListFilter
        flex={1}
        mr={2}
        options={batchOptions}
        isInModal
        isMulti={false}
        placeholder="Select Batch"
        onSelectionChange={async (item: SelectOption[]) => {
          if (item && item.length > 0) {
            setSelectedBatchId(item[0].value);
          } else {
            setSelectedBatchId(undefined);
          }
        }}
        showClear={false}
      />

      <Tooltip shouldWrapChildren label={'Manage Batches'} aria-label="manageBatches">
        <IconButton
          aria-label="add-edit-batches"
          px={3}
          bgColor={AppColors.appBackground}
          color={'black'}
          onClick={() => handleBatchManagement()}
          boxSize={'54px'}
          borderRadius={'xl'}
        >
          <Icon as={iconHelper.settingsIcon} height={'25px'} width={'25px'} color={'gray.400'} />
        </IconButton>
      </Tooltip>
    </Flex>
  );
};

export default BatchDropDownComponent;
