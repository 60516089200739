import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  FormLabel,
  Icon,
  IconButton,
  SimpleGrid,
  Tooltip,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { backlinkURLRegex } from '../../constants/regex';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BacklinkFormData,
  AppButton,
  AppInput,
  AppText,
  BackLinkListItem,
  AppColors,
  SelectOption,
  SelectListFilter,
  AppAlertItemList,
  AppDatePicker,
} from '@backlinkit/shared';
import CheckerLinkStatusCell from '../app/app-link-checker/components/app-checker-link-status-cell';
import CheckerRelCell from '../app/app-link-checker/components/checker-rel-cell';
import { useCurrencies } from '../../hooks/useCurrencies';
import { selectOrganizationTags } from '../../store/slices/tag.slice';
import { useAppSelector } from '../../store/store';
import TagManagement from '../dialog-management/tag-management';
import { useClientDialog } from '../../providers/client-dialog/use-client-dialog';
import { iconHelper } from '../../utils/iconHelper';
import { BacklinkTag } from '@backlinkit/shared/lib/models/backlinkTag';
import { addDays, format } from 'date-fns';

export const backLinkFormDataDefaultValues: BacklinkFormData = {
  url: '',
  notes: '',
  expectedLandingPage: '',
  expectedAnchor: '',
  expectedRel: '',
  price: 0,
  priceCurrency: undefined,
  expectedLiveDate: undefined,
};

const backLinkFormDataSchema = yup.object({
  url: yup.string().required('Field is required').matches(backlinkURLRegex, 'Not a valid Url'),
  expectedLiveDate: yup.date(),
});

type BackLinkEditFormType = {
  form?: BacklinkFormData;
  backlink: BackLinkListItem;
  defaultTags?: BacklinkTag[];
  onSubmit: (data: BacklinkFormData, tags?: string[]) => void;
};

const BackLinkEditForm: React.FC<BackLinkEditFormType> = ({
  form,
  backlink,
  defaultTags,
  onSubmit,
}) => {
  const clientDialog = useClientDialog();
  const tags = useAppSelector(selectOrganizationTags);
  const [currentTags, setCurrentTags] = useState<SelectOption[]>([]);
  const [currentDefaultTags, setCurrentDefaultTags] = useState<SelectOption[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>();

  const [relSplit, setRelSplit] = useState<string[]>([]);
  const [relFollow, setRelFollow] = useState<boolean>(false);
  const [defaultCurrency, setDefaultCurrency] = useState<SelectOption>();
  const [currencyOptions, setCurrencyOptions] = useState<SelectOption[]>();
  const [warning, setWarning] = useState<boolean>(false);
  const currencies = useCurrencies();

  const handleAddTags = (items: SelectOption[]) => {
    const tags: string[] = items.map((x) => {
      return x.value;
    });
    setSelectedTags(tags);
  };

  useEffect(() => {
    if (tags) {
      const selectors: SelectOption[] = tags.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setCurrentTags(selectors);
    }
  }, [tags]);

  useEffect(() => {
    if (defaultTags && currentTags) {
      const defaultTagIds = defaultTags.map((x) => x.tagId);
      const defaultValues = currentTags.filter((x) => defaultTagIds.includes(x.value));
      setCurrentDefaultTags(defaultValues);
      handleAddTags(defaultValues);
    }
  }, [defaultTags, currentTags]);

  const {
    setValue,
    control: backLinkControl,
    formState: { isValid, errors },
    getValues,
  } = useForm<BacklinkFormData>({
    defaultValues: form || backLinkFormDataDefaultValues,
    resolver: yupResolver(backLinkFormDataSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (form) {
      // const relSplit = backlink?.expectedRelValue ?? '';
      const relSplit = backlink?.expectedRelValue?.split(',') || [];
      setRelSplit(relSplit);
      if (relSplit.includes('follow')) {
        setRelFollow(true);
      }
    }
  }, [form]);

  useEffect(() => {
    if (currencies && form) {
      setCurrencyOptions(currencies);

      const selectedCurrency = currencies?.find((x) => {
        return x.value === form.priceCurrency;
      });
      if (selectedCurrency) {
        setDefaultCurrency(selectedCurrency);
      }
    }
    getCurrencyWarning();
  }, [currencies, form?.priceCurrency]);

  const onRelSelectChange = (checked: boolean, rel: string) => {
    if (rel === 'follow' && checked) {
      setRelFollow(true);
      setRelSplit([rel]);
      setValue('expectedRel', rel);
    } else {
      setRelFollow(false);
      if (checked) {
        setRelSplit([...relSplit, rel]);
        setValue('expectedRel', [...relSplit, rel].join(','));
      } else {
        const relCopy = [...relSplit];
        const relIndex = relCopy.findIndex((x) => x === rel);
        if (relIndex !== -1) {
          relCopy.splice(relIndex, 1);
        }
        setRelSplit(relCopy);
        setValue('expectedRel', relCopy.join(','));
      }
    }
  };

  const handleFormSubmit = () => {
    const formData = getValues();
    if (form?.id && formData) {
      onSubmit(formData, selectedTags);
    }
  };

  const getCurrencyWarning = () => {
    const formData = getValues();
    if (formData.price && formData.price > 0) {
      switch (formData?.priceCurrency) {
        case undefined:
          setWarning(true);
          break;
        case '':
          setWarning(true);
          break;
        default:
          setWarning(false);
          break;
      }
    }
  };

  const handleTagManagement = () => {
    clientDialog({
      title: `Tag Management`,
      position: 3,
      size: '4xl',
      render: (onSubmit) => {
        return <TagManagement />;
      },
    });
  };

  // const minDate = addDays(new Date(), 1);

  const expectedLiveDate =
    backlink && backlink.expectedLiveDate
      ? format(new Date(backlink.expectedLiveDate), 'dd MMM yyyy')
      : '';

  return (
    <Flex flexDirection={'column'} flex={1}>
      <form>
        <Flex flexDirection={'column'}>
          <AppText pb={4} fontSize={'18px'} fontWeight={'medium'}>
            Current Details
          </AppText>
          <SimpleGrid columns={2} spacingY={4}>
            <Box>
              <AppText fontWeight={'medium'} fontSize={'14px'}>
                Status:
              </AppText>
            </Box>
            <Box>
              <CheckerLinkStatusCell
                isManual={backlink?.isManual}
                linkStatus={backlink?.backlinkMeta?.linkStatus}
              />
            </Box>
            <Box>
              <AppText fontWeight={'medium'} fontSize={'14px'}>
                Landing:
              </AppText>
            </Box>
            <Box>
              <AppText>{backlink?.landingPage}</AppText>
            </Box>
            <Box>
              <AppText fontWeight={'medium'} fontSize={'14px'}>
                Anchor:
              </AppText>
            </Box>
            <Box>
              <AppText>{backlink?.anchor}</AppText>
            </Box>
            <Box>
              <AppText fontWeight={'medium'} fontSize={'14px'}>
                REL:
              </AppText>
            </Box>
            <Box>
              <CheckerRelCell
                relStatus={backlink?.backlinkMeta?.relStatus}
                relValue={backlink?.relValue}
              />
            </Box>
            {backlink && backlink.expectedLiveDate && (
              <Box>
                <AppText fontWeight={'medium'} fontSize={'14px'}>
                  Expected Live Date:
                </AppText>
              </Box>
            )}
            {backlink && backlink.expectedLiveDate && (
              <Box>
                <AppText fontWeight={'medium'} fontSize={'14px'}>
                  {expectedLiveDate}
                </AppText>
              </Box>
            )}
          </SimpleGrid>
        </Flex>

        <Flex mt={4} alignItems={'end'}>
          <Box flex={1}>
            <FormLabel mb={2} p={0}>
              Tags
            </FormLabel>
            <SelectListFilter
              mr={2}
              name="batchTags"
              options={currentTags}
              isInModal
              isMulti
              defaultOption={currentDefaultTags}
              onSelectionChange={(items: SelectOption[]) => {
                handleAddTags(items);
              }}
            />
          </Box>

          <Tooltip shouldWrapChildren label={'Manage Tags'} aria-label="manageTags">
            <IconButton
              aria-label="add-edit-tags"
              px={3}
              bgColor={AppColors.appBackground}
              color={'black'}
              onClick={() => handleTagManagement()}
              boxSize={'54px'}
              borderRadius={'xl'}
            >
              <Icon
                as={iconHelper.settingsIcon}
                height={'25px'}
                width={'25px'}
                color={'gray.400'}
              />
            </IconButton>
          </Tooltip>
        </Flex>

        {/* <AppDatePicker<BacklinkFormData>
          mt={6}
          name="expectedLiveDate"
          control={backLinkControl}
          // minDate={minDate}
          error={errors.expectedLiveDate}
          label="Expected Live Date"
        /> */}

        <AppInput<BacklinkFormData>
          mt={6}
          flex={1}
          control={backLinkControl}
          name="url"
          label="URL from"
          error={errors.url}
          variant="grayed"
        />

        <Flex align={'end'} gap={4}>
          <AppInput<BacklinkFormData>
            mt={6}
            control={backLinkControl}
            error={undefined}
            name="price"
            label="Price"
            placeHolder={'Price'}
            inputType="number"
            flex={1}
            onChange={() => {
              getCurrencyWarning();
            }}
            variant="grayed"
          />

          <SelectListFilter
            name="priceCurrency"
            options={currencyOptions || []}
            defaultOption={defaultCurrency}
            isInModal
            isMulti={false}
            showClear
            placeholder="Select Currency"
            onSelectionChange={(item: SelectOption[]) => {
              if (item) {
                setValue('priceCurrency', item[0]?.value);
                getCurrencyWarning();
              } else {
                setValue('priceCurrency', undefined);
                getCurrencyWarning();
              }
            }}
          />
        </Flex>

        {warning && (
          <Box mt={4}>
            <AppAlertItemList
              messages={['Currency recommended']}
              alertType={'warning'}
            ></AppAlertItemList>
          </Box>
        )}

        <AppInput<BacklinkFormData>
          mt={6}
          control={backLinkControl}
          error={errors.expectedAnchor}
          name="expectedAnchor"
          label="Expected Anchor"
          flex={1}
          variant="grayed"
        />
        <AppInput<BacklinkFormData>
          mt={6}
          control={backLinkControl}
          error={errors.expectedLandingPage}
          name="expectedLandingPage"
          label="Expected Landing Page"
          flex={1}
          variant="grayed"
        />

        <AppInput<BacklinkFormData>
          mt={6}
          control={backLinkControl}
          error={errors.notes}
          label="Notes"
          name="notes"
          textArea={true}
          flex={1}
          variant="grayed"
        />
        {relSplit && (
          <Flex mt={6} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Checkbox
              isChecked={relSplit.includes('follow')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onRelSelectChange(event.currentTarget.checked, 'follow');
              }}
            >
              Follow
            </Checkbox>
            <Checkbox
              isDisabled={relFollow}
              isChecked={relSplit.includes('nofollow')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onRelSelectChange(event.currentTarget.checked, 'nofollow');
              }}
            >
              No Follow
            </Checkbox>
            <Checkbox
              isDisabled={relFollow}
              isChecked={relSplit.includes('ugc')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onRelSelectChange(event.currentTarget.checked, 'ugc');
              }}
            >
              UGC
            </Checkbox>
            <Checkbox
              isDisabled={relFollow}
              isChecked={relSplit.includes('sponsored')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onRelSelectChange(event.currentTarget.checked, 'sponsored');
              }}
            >
              Sponsored
            </Checkbox>
          </Flex>
        )}

        <AppButton
          bgColor={AppColors.ctaColor}
          color={'white'}
          mt={8}
          w={'full'}
          isDisabled={!isValid && !warning}
          onClick={() => {
            handleFormSubmit();
          }}
        >
          {'Save'}
        </AppButton>
      </form>
    </Flex>
  );
};

export default BackLinkEditForm;
