import { Box, Flex, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AppColors, AppText, useDialog } from '@backlinkit/shared';
import { BaseBacklinkCell } from './base-backlink-cell';
import EflPanel from '../../efl-panel';
import { useClientDialog } from '../../../../providers/client-dialog/use-client-dialog';

type EflCellProps = BaseBacklinkCell & {
  organizationId: string;
  parentUrl: string;
  isDomain?: boolean;
};

const EflCell: React.FC<EflCellProps> = ({ organizationId, parentUrl, item, isDomain }) => {
  const dialog = useDialog();
  const clientDialog = useClientDialog();

  // const [fetchBacklinkEflsQuery] = useLazyFetchBacklinkEflsQuery();

  const handleEflListDialog = async () => {
    // const eflList = await fetchBacklinkEflsQuery(item.id).unwrap();

    dialog({
      title: `${item.url} EFL's`,
      showCancel: true,
      size: '3xl',
      render: (onSubmit) => {
        return (
          <EflPanel organizationId={organizationId} backlinkId={item.id} parentUrl={parentUrl} />
        );
      },
    });
  };

  if (!item.backlinkMeta) {
    return <></>;
  }

  const handleDomainDialog = async () => {
    clientDialog({
      title: `${item.url} EFL's`,
      showCancel: true,
      size: '3xl',
      render: (onSubmit) => {
        return (
          <EflPanel organizationId={organizationId} backlinkId={item.id} parentUrl={parentUrl} />
        );
      },
    });
  };

  return (
    <Box>
      <Tooltip
        label={
          <Flex flexDirection={'column'}>
            <AppText>External Follow: {item?.backlinkMeta?.externalFollowCount}</AppText>
            <AppText>External NoFollow: {item?.backlinkMeta?.externalNoFollowCount}</AppText>
            <AppText>Internal: {item?.backlinkMeta?.internalFollowCount}</AppText>
          </Flex>
        }
      >
        <span>
          {!isDomain && (
            <AppText
              color={AppColors.successColor}
              fontSize={'16px'}
              cursor={'pointer'}
              onClick={() => handleEflListDialog()}
            >
              {item?.backlinkMeta?.externalFollowCount}
            </AppText>
          )}
          {isDomain && (
            <AppText
              color={AppColors.successColor}
              fontSize={'16px'}
              cursor={'pointer'}
              onClick={handleDomainDialog}
            >
              {item?.backlinkMeta?.externalFollowCount ?? 0}
            </AppText>
          )}
        </span>
      </Tooltip>
    </Box>
  );
};

export default EflCell;
