import React, { useEffect, useState } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { AppInput, SelectOption, SelectListFilter, AppButton, AppColors } from '@backlinkit/shared';
import { Box, Flex, FormLabel, Icon, IconButton, SimpleGrid, Tooltip } from '@chakra-ui/react';
import { BacklinkBatchFormData } from './backlink-batch-form';
import TagManagement from '../dialog-management/tag-management';
import { useAppSelector } from '../../store/store';
import { selectOrganizationTags } from '../../store/slices/tag.slice';
import { useCurrencies } from '../../hooks/useCurrencies';
import { useClientDialog } from '../../providers/client-dialog/use-client-dialog';
import { iconHelper } from '../../utils/iconHelper';

type BacklinkBatchFormFieldsProps = {
  batchControl: Control<BacklinkBatchFormData, any>;
  errors: FieldErrors<BacklinkBatchFormData>;
  setValue: any;
  getValues: any;
  // defaultBatchTypeId?: string;
  // defaultBatchNicheTypeId?: string;
  defaultTags?: string[];
  defaultCurrency?: string;
  handleAddTags: (items: SelectOption[]) => void;
};

const BacklinkBatchFormFields: React.FC<BacklinkBatchFormFieldsProps> = ({
  batchControl,
  errors,
  setValue,
  getValues,
  // defaultBatchTypeId,
  // defaultBatchNicheTypeId,
  defaultTags,
  defaultCurrency,
  handleAddTags,
}) => {
  // const dialog = useDialog();
  const clientDialog = useClientDialog();
  const tags = useAppSelector(selectOrganizationTags);
  const [currentTags, setCurrentTags] = useState<SelectOption[]>([]);
  const [currentDefaultTags, setCurrentDefaultTags] = useState<SelectOption[]>([]);
  // const [batchNicheTypeOptions, setBatchNicheTypeOptions] = useState<SelectOption[]>();
  // const [batchTypeOptions, setBatchTypeOptions] = useState<SelectOption[]>();
  const currencies = useCurrencies();
  const [currencyOptions, setCurrencyOptions] = useState<SelectOption[]>();
  const [warning, setWarning] = useState<boolean>(false);

  // const { data: batchNicheTypes } = useFetchAllNicheTypesQuery({
  //   refetchOnMountArgChange: true,
  // });
  // const { data: batchTypes } = useFetchAllBatchTypesQuery({
  //   refetchOnMountArgChange: true,
  // });

  useEffect(() => {
    if (tags) {
      const selectors: SelectOption[] = tags.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setCurrentTags(selectors);
    }
  }, [tags]);

  // useEffect(() => {
  //   if (batchNicheTypes) {
  //     const selectors: SelectOption[] = [];
  //     batchNicheTypes?.map((x) => {
  //       selectors.push({
  //         label: x.name,
  //         value: x.id,
  //       });
  //     });
  //     setBatchNicheTypeOptions(selectors);
  //   }
  // }, [batchNicheTypes]);

  // useEffect(() => {
  //   if (batchTypes) {
  //     const selectors: SelectOption[] = [];
  //     batchTypes?.map((x) => {
  //       selectors.push({
  //         label: x.name,
  //         value: x.id,
  //       });
  //     });
  //     setBatchTypeOptions(selectors);
  //   }
  // }, [batchTypes]);

  useEffect(() => {
    if (defaultTags && currentTags) {
      const defaultValues = currentTags.filter((x) => defaultTags.includes(x.value));
      setCurrentDefaultTags(defaultValues);
      handleAddTags(defaultValues);
    }
  }, [defaultTags, currentTags]);

  // const getDefaultBatchTypeOption = () => {
  //   if (batchTypeOptions && defaultBatchTypeId) {
  //     const defaultValue = batchTypeOptions.find((x) => x.value === defaultBatchTypeId);

  //     return defaultValue;
  //   }

  //   return undefined;
  // };

  // const getDefaultBatchNicheTypeOption = () => {
  //   if (batchNicheTypeOptions && defaultBatchNicheTypeId) {
  //     const defaultValue = batchNicheTypeOptions.find((x) => x.value === defaultBatchNicheTypeId);

  //     return defaultValue;
  //   }

  //   return undefined;
  // };

  const getDefaultCurrencyOption = () => {
    if (currencyOptions && defaultCurrency) {
      const defaultValue = currencyOptions.find((x) => x.value === defaultCurrency);

      return defaultValue;
    }

    return undefined;
  };

  // const getDefaultTagOptions = () => {
  //   if (tags && defaultTags) {
  //     const defaultValues = currentTags.filter((x) => defaultTags.includes(x.value));

  //     return defaultValues;
  //   }

  //   return undefined;
  // };

  const getCurrencyWarning = () => {
    const formData = getValues();
    if (formData.price && formData.price > 0) {
      switch (formData?.priceCurrency) {
        case undefined:
          setWarning(true);
          break;
        case '':
          setWarning(true);
          break;
        default:
          setWarning(false);
          break;
      }
    }
  };

  const handleTagManagement = () => {
    clientDialog({
      title: `Tag Management`,
      position: 3,
      size: '4xl',
      render: (onSubmit) => {
        return <TagManagement />;
      },
    });
  };

  useEffect(() => {
    setCurrencyOptions(currencies);
  }, [currencies]);

  return (
    <>
      <AppInput<BacklinkBatchFormData>
        name="name"
        control={batchControl}
        error={errors.name}
        label="Batch Name"
        variant="grayed"
      />
      <Flex align={'end'}>
        <Box flex={1}>
          <FormLabel mb={2} p={0}>
            Tags
          </FormLabel>
          <SelectListFilter
            mr={2}
            name="batchTags"
            options={currentTags}
            isInModal
            isMulti
            defaultOption={currentDefaultTags}
            onSelectionChange={(items: SelectOption[]) => {
              handleAddTags(items);
            }}
          />
        </Box>

        <Tooltip shouldWrapChildren label={'Manage Tags'} aria-label="manageTags">
          <IconButton
            aria-label="add-edit-tags"
            px={3}
            bgColor={AppColors.appBackground}
            color={'black'}
            onClick={() => handleTagManagement()}
            boxSize={'54px'}
            borderRadius={'xl'}
          >
            <Icon as={iconHelper.settingsIcon} height={'25px'} width={'25px'} color={'gray.400'} />
          </IconButton>
        </Tooltip>
      </Flex>

      {/* <FormLabel p={0} m={0}>
        Batch Type
      </FormLabel>
      <Flex align={'center'} width={'100%'} gap={'10px'}>
        <SelectListFilter
          name="batchType"
          options={batchTypeOptions}
          isInModal
          showClear={true}
          defaultOption={getDefaultBatchTypeOption()}
          onSelectionChange={(items: SelectOption[]) => {
            if (items) {
              setValue('batchTypeId', items[0]?.value);
            } else {
              setValue('batchTypeId', undefined);
            }
          }}
          width={'100%'}
        />
      </Flex> */}
      {/* <FormLabel m={0} p={0}>
        Batch Niche
      </FormLabel>
      <Flex align={'center'} width={'100%'} gap={'10px'}>
        <SelectListFilter
          name="batchNicheType"
          options={batchNicheTypeOptions}
          isInModal
          showClear={true}
          defaultOption={getDefaultBatchNicheTypeOption()}
          onSelectionChange={(items: SelectOption[]) => {
            if (items) {
              setValue('nicheTypeId', items[0]?.value);
            } else {
              setValue('nicheTypeId', undefined);
            }
          }}
          w={'100%'}
        />
      </Flex> */}
      <AppInput<BacklinkBatchFormData>
        name="notes"
        control={batchControl}
        error={errors.notes}
        label={'Notes'}
        // textArea
        variant="grayed"
      />

      <Flex align={'end'} gap={4}>
        <AppInput<BacklinkBatchFormData>
          mt={6}
          control={batchControl}
          error={undefined}
          name="price"
          label="Price"
          placeHolder={'Price'}
          inputType="number"
          flex={1}
          onChange={() => {
            getCurrencyWarning();
          }}
          variant="grayed"
        />

        <SelectListFilter
          name="currency"
          options={currencyOptions || []}
          defaultOption={getDefaultCurrencyOption()}
          isInModal
          isMulti={false}
          showClear
          placeholder="Select Currency"
          onSelectionChange={(item: SelectOption[]) => {
            if (item) {
              setValue('currency', item[0]?.value);
              getCurrencyWarning();
            } else {
              setValue('currency', undefined);
              getCurrencyWarning();
            }
          }}
        />
      </Flex>
    </>
  );
};

export default BacklinkBatchFormFields;
