export const SA_ID_REGEX =
  /(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/
export const SA_ID_REGEX_OR_BLANK = /^(?:\d{2}[0-1]\d[0-3]\d\d{4}[0-1]\d{2}$)?$/
export const SA_PASSPORT_REGEX = /[a-zA-Z]{2}\d{7}/
export const SA_CELL_REGEX = /^(\+27|0)[6-8]\d{8}$/
export const SA_CELL_REGEX_LOCAL = /^(0)[6-8]\d{8}$/
export const SIX_DIGITS = /^\d{6}/
export const POSTAL_CODE_OR_BLANK = /^(?:\d{4})?$/
export const containsLowerCaseRegex = /^(?=.*[a-z]).+$/
export const containsUpperCaseRegex = /^(?=.*[A-Z]).+$/
export const containsNumericRegex = /^(?=.*\d).+$/
export const containsSpecialCharactersRegex = /^(?=.*[-+_!@#$%^&*., ?]).+$/
export const lettersAndSpaces = /^[a-zA-Z\s]*$/
export const lettersSpacesDashes = /^[a-zA-Z-\s]*$/
