import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppButton, AppColors, AppText, ProductType } from '@backlinkit/shared';
import BaseLayout from '../../components/layouts/base-layout';
import { useAppSelector } from '../../store/store';
import { selectUser } from '../../store/slices/authentication.slice';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { RoutesList } from '../../router/router';
import { useFetchProductByIdQuery } from '../../store/api/productApi';

const PaymentSuccessPage: React.FC = () => {
  const { id } = useParams();
  const user = useAppSelector(selectUser);
  const { data: product } = useFetchProductByIdQuery(id ?? '');
  const navigate = useNavigate();

  const paymentDate = new Date().toUTCString().slice(0, 16);

  return (
    <Flex direction={'column'} align={'center'} justify={'center'} w={'100vw'}>
      <Flex
        boxShadow={'lg'}
        w={'md'}
        h={'lg'}
        bgColor={'white'}
        direction={'column'}
        align={'center'}
        justify={'space-around'}
        gap={5}
        borderRadius={'xl'}
        p={8}
      >
        <CheckCircleIcon color={AppColors.successColor} boxSize={'80px'} />
        <AppText
          color={AppColors.primary}
          fontWeight={'bold'}
          fontSize={'2xl'}
          mt={'10px'}
          mb={'4px'}
        >
          {product?.type === ProductType.PAYG
            ? 'Plan Successfully Changed!'
            : 'Payment Successful!'}
        </AppText>
        <Flex direction={'column'} w={'full'} gap={3}>
          {product?.type !== ProductType.PAYG && (
            <>
              <Flex justify={'space-between'} w={'full'}>
                <AppText fontWeight={'bold'}>Payment Date</AppText>
                <AppText>{paymentDate}</AppText>
              </Flex>
              <Flex justify={'space-between'} w={'full'}>
                <AppText fontWeight={'bold'}>Payment Amount</AppText>
                <AppText>{`$ ${product?.price}`}</AppText>
              </Flex>
            </>
          )}

          <Flex justify={'space-between'} w={'full'}>
            <AppText fontWeight={'bold'}>Product</AppText>
            <AppText>{`${product?.name}`}</AppText>
          </Flex>
          <Flex justify={'space-between'} w={'full'}>
            <AppText fontWeight={'bold'}>Organization</AppText>
            <AppText>{user?.organization?.name}</AppText>
          </Flex>
        </Flex>
        <AppButton
          w={'full'}
          onClick={() => {
            if (product?.type !== ProductType.PAYG) {
              navigate(RoutesList.Dashboard);
            } else {
              navigate(RoutesList.GoogleChecker);
            }
          }}
          borderRadius="full"
          bgColor={'rgba(3, 125, 252, 0.1)'}
          borderColor={`transparent !important`}
          color={AppColors.secondary2}
        >
          Get Started!
        </AppButton>
      </Flex>
    </Flex>
  );
};
export default PaymentSuccessPage;
