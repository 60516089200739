import { useEffect } from 'react';

type usePageMetaProps = {
  url: string;
};

const host = process.env.REACT_APP_BASE_URL ?? '';

export const usePageMeta = ({ url }: usePageMetaProps) => {
  const defaultUrl = host;

  useEffect(() => {
    if (document) {
      document
        .querySelector("link[rel='canonical']")
        ?.setAttribute('href', url ? `${defaultUrl}${url}` : defaultUrl);
    }
  }, [defaultUrl, url]);
};
