import { Flex } from '@chakra-ui/react';
import { AppColors, AppText, ProductDto } from '@backlinkit/shared';

export interface CheckoutComponentProps {
  product?: ProductDto;
}

export const CheckoutComponent: React.FC<CheckoutComponentProps> = ({ product }) => {
  return (
    <Flex flexDir={'column'} w={'full'} px={6}>
      <Flex
        justifyContent={'space-between'}
        align={'center'}
        borderTopRadius={'2xl'}
        bgColor={AppColors.secondary2}
        border={`1px solid ${AppColors.secondary2}`}
        p={8}
      >
        <AppText color={'white'} fontWeight="bold" fontSize="2xl">
          Checkout - {product?.name}
        </AppText>
      </Flex>
      <Flex
        w={'100%'}
        p={4}
        border={`1px solid ${AppColors.secondary2}`}
        borderBottomRadius={'2xl'}
        justifyContent={'center'}
        bgColor={'white'}
      >
        <div className="checkout-container"></div>
      </Flex>
    </Flex>
  );
};
