import React, { useEffect, useState } from 'react';
import { Box, Flex, FormLabel, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import {
  AppButton,
  AppColors,
  BacklinkFormData,
  SelectListFilter,
  SelectOption,
} from '@backlinkit/shared';
import { BacklinkTag } from '@backlinkit/shared/lib/models/backlinkTag';
import { useClientDialog } from '../../providers/client-dialog/use-client-dialog';
import { selectOrganizationTags } from '../../store/slices/tag.slice';
import { useAppSelector } from '../../store/store';
import { iconHelper } from '../../utils/iconHelper';
import TagManagement from '../dialog-management/tag-management';

type BackLinkTagFormType = {
  form?: BacklinkFormData;
  defaultTags?: BacklinkTag[];
  onSubmit: (data: BacklinkFormData, tags?: string[]) => void;
};

const BackLinkTagForm: React.FC<BackLinkTagFormType> = ({ form, defaultTags, onSubmit }) => {
  const clientDialog = useClientDialog();
  const tags = useAppSelector(selectOrganizationTags);
  const [currentTags, setCurrentTags] = useState<SelectOption[]>([]);
  const [currentDefaultTags, setCurrentDefaultTags] = useState<SelectOption[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>();

  const handleFormSubmit = () => {
    if (form) onSubmit(form, selectedTags);
  };

  const handleAddTags = (items: SelectOption[]) => {
    const tags: string[] = items.map((x) => {
      return x.value;
    });
    setSelectedTags(tags);
  };

  useEffect(() => {
    if (tags) {
      const selectors: SelectOption[] = tags.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setCurrentTags(selectors);
    }
  }, [tags]);

  useEffect(() => {
    if (defaultTags && currentTags) {
      const defaultTagIds = defaultTags.map((x) => x.tagId);
      const defaultValues = currentTags.filter((x) => defaultTagIds.includes(x.value));
      setCurrentDefaultTags(defaultValues);
      handleAddTags(defaultValues);
    }
  }, [defaultTags, currentTags]);

  const handleTagManagement = () => {
    clientDialog({
      title: `Tag Management`,
      position: 3,
      size: '4xl',
      render: (onSubmit) => {
        return <TagManagement />;
      },
    });
  };

  return (
    <Flex flexDirection={'column'} flex={1}>
      <Flex mt={4} alignItems={'end'}>
        <Box flex={1}>
          <FormLabel mb={2} p={0}>
            Tags
          </FormLabel>
          <SelectListFilter
            mr={2}
            name="batchTags"
            options={currentTags}
            isInModal
            isMulti
            defaultOption={currentDefaultTags}
            onSelectionChange={(items: SelectOption[]) => {
              handleAddTags(items);
            }}
          />
        </Box>

        <Tooltip shouldWrapChildren label={'Manage Tags'} aria-label="manageTags">
          <IconButton
            aria-label="add-edit-tags"
            px={3}
            bgColor={AppColors.appBackground}
            color={'black'}
            onClick={() => handleTagManagement()}
            boxSize={'54px'}
            borderRadius={'xl'}
          >
            <Icon as={iconHelper.settingsIcon} height={'25px'} width={'25px'} color={'gray.400'} />
          </IconButton>
        </Tooltip>
      </Flex>

      {form?.id && (
        <AppButton
          mt={8}
          w={'full'}
          bgColor={AppColors.ctaColor}
          color={'white'}
          onClick={() => {
            handleFormSubmit();
          }}
        >
          {'Save'}
        </AppButton>
      )}
    </Flex>
  );
};

export default BackLinkTagForm;
