import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  OrganizationExludeLink,
  OrganizationExludeLinkForm,
  EditOrganizationExcludeLinkForm,
  GetByOrganizationOptionsRequest,
  PaginatedData,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationExcludeLinkApi = createApi({
  reducerPath: 'organizationExcludeLink',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchOrganizationExcludeLinksByOptions: build.query<
      PaginatedData<OrganizationExludeLink>,
      GetByOrganizationOptionsRequest
    >({
      query: (data) => ({
        url: `/organizationexcludelink/by/options`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<OrganizationExludeLink>) => {
        return response;
      },
    }),
    fetchAllOrganizationExcludeLinks: build.query<OrganizationExludeLink[], string>({
      query: (orgId) => ({
        url: `/organizationexcludelink/by/org/${orgId}`,
        method: 'GET',
      }),
    }),
    fetchOrganizationExcludeLinkById: build.query<OrganizationExludeLink, string>({
      query: (id) => ({
        url: `/organaizationexcludelink/${id}`,
        method: 'GET',
      }),
    }),
    createOrganizationExcludeLink: build.mutation<
      OrganizationExludeLink,
      OrganizationExludeLinkForm
    >({
      query: (body) => ({
        url: '/organizationexcludelink/create',
        method: 'POST',
        body: body,
      }),
    }),
    editOrganizationExcludeLink: build.mutation<
      OrganizationExludeLink,
      EditOrganizationExcludeLinkForm
    >({
      query: (body) => ({
        url: `/organizationexcludelink/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteOrganizationExludeLink: build.mutation<string, string>({
      query: (id) => ({
        url: `/organizationexcludelink/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchOrganizationExcludeLinksByOptionsQuery,
  useCreateOrganizationExcludeLinkMutation,
  useDeleteOrganizationExludeLinkMutation,
  useEditOrganizationExcludeLinkMutation,
  useFetchAllOrganizationExcludeLinksQuery,
  useFetchOrganizationExcludeLinkByIdQuery,
  useLazyFetchAllOrganizationExcludeLinksQuery,
  useLazyFetchOrganizationExcludeLinkByIdQuery,
} = organizationExcludeLinkApi;
