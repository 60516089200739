import { Image, useToast, Flex, useMediaQuery } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import UserRegistrationForm, { RegistrationData } from '../../components/forms/registration-form';
import { useUserRegistrationMutation } from '../../store/api/authenticationApi';
import { AppColors, AppText, useLoading } from '@backlinkit/shared';
import { useState } from 'react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { usePageMeta } from '../../hooks/usePageMeta';
import { RoutesList } from '../../router/router';

export default function RegistrationInvite() {
  usePageMeta({ url: RoutesList.Registration });
  const { sourceId } = useParams();
  const toast = useToast();
  const { setLoading } = useLoading();
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const [smallDevice] = useMediaQuery('(max-width: 1400px)');

  const [userRegistration] = useUserRegistrationMutation();

  // const { data: sourceType, isFetching } = useFetchOrganizationSourceTypeByIdQuery(sourceId ?? '', {
  //   skip: !sourceId,
  // });

  const signUp = async (registrationData: RegistrationData) => {
    if (registrationData.termsAndConditions === true) {
      setShowError(false);
      try {
        setLoading(true);
        await userRegistration({
          accountName: registrationData.accountName,
          email: registrationData.email,
          password: registrationData.password,
          organizationSourceId: sourceId ? sourceId : null,
          acceptMarketing: registrationData.keepUpdated,
          acceptTerms: registrationData.termsAndConditions,
        });
        // toast({
        //   title: 'Account created, verification required!',
        //   description: 'Please go action the email with instructions to verify your account.',
        //   status: 'success',
        //   duration: 9000,
        //   isClosable: true,
        // });
        setLoading(false);
        setShowSuccess(true);
      } catch (e) {
        setLoading(false);
        toast({
          title: 'Account creation error',
          status: 'error',
          description:
            "We've run into an error creating your account, you might have an account with us already, Contact support for help",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 10000);
    }
  };

  // const getSourceText = () => {
  //   if (sourceType) {
  //     return sourceType.description;
  //   } else {
  //     return '';
  //   }
  // };

  const image = `https://backlinkitprod.blob.core.windows.net/assets/7172c249-62a1-4039-a66a-71e78a639aa2_sign-up-image.png-1736766294664`;

  return (
    <Flex
      height={'100%'}
      align={'center'}
      justify={'center'}
      position={'relative'}
      overflow={'auto'}
      bgColor={AppColors.secondary2}
    >
      <Flex
        w={'100%'}
        align={'center'}
        justify={'space-evenly'}
        height={'100%'}
        p={{ base: 4, sm: 6, md: 8 }}
        gap={'80px'}
      >
        {!smallDevice && <Image src={image} maxH={'620px'} maxW={'650px'} />}
        <Flex
          bgColor={'white'}
          flexDir={'column'}
          justify={'center'}
          align={'center'}
          borderRadius={'30px'}
          p={{ base: 4, sm: 6, md: 8 }}
          gap={8}
          maxW={'800px'}
          w={'100%'}
          height={'100%'}
          overflow={'auto'}
        >
          {!showSuccess && (
            <AppText
              textAlign={'center'}
              mt={smallDevice ? '80px' : 4}
              fontWeight={'600'}
              variant={'h2'}
            >
              Create An Account
            </AppText>
          )}

          <Flex flexDirection={'column'} as={'form'} width={'100%'} maxW={'555px'}>
            {showSuccess ? (
              <Flex direction={'column'} align={'center'} justify={'space-around'} gap={5}>
                <CheckCircleIcon color={AppColors.successColor} boxSize={'80px'} />
                <AppText
                  color={AppColors.primary}
                  fontWeight={'bold'}
                  fontSize={'2xl'}
                  mt={'10px'}
                  mb={'4px'}
                  textAlign={'center'}
                >
                  Account created, verification required!
                </AppText>
                <Flex direction={'column'} w={'full'} gap={3} textAlign={'center'}>
                  We have sent an email to your registered address. Please follow the instructions
                  in the email to verify your account.
                </Flex>
              </Flex>
            ) : (
              <UserRegistrationForm showError={showError} onSubmit={signUp}></UserRegistrationForm>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
