import React, { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Icon,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { DeleteIcon } from '@chakra-ui/icons';
import { FiFile } from 'react-icons/fi';
import { FaDownload } from 'react-icons/fa';
import {
  BacklinkImportFileValidationResult,
  backlinkImportFileValidation,
} from '../../utils/sharedFormHelpers';
import BacklinkCsvImportValidationErrors from './backlink-csv-import-validation-errors';
import { importBacklinkCsvRequiredColumns } from '../../constants/csvImportDefinitions';
import {
  BacklinkFormData,
  AppColors,
  BacklinkCSVImport,
  AppCsvFileUploadReader,
  CsvFileUploadReaderResult,
  AppButton,
  AppInput,
  AppText,
  AppDatePicker,
} from '@backlinkit/shared';
import { useDispatch } from 'react-redux';
import { iconHelper } from '../../utils/iconHelper';
import { addDays } from 'date-fns';

export type CsvBacklinkFormData = {
  notes: string;
  backlinkCategoryId?: string;
  totalPrice?: number;
  expectedLiveDate?: Date;
};

export const backLinkCsvFormDataDefaultValues: CsvBacklinkFormData = {
  notes: '',
  backlinkCategoryId: undefined,
  totalPrice: undefined,
  expectedLiveDate: undefined,
};

const csvBackLinkFormDataSchema = yup.object({
  notes: yup.string(),
  backlinkCategoryId: yup.string(),
  totalPrice: yup.number().required('Field is required'),
  expectedLiveDate: yup.date(),
});

type CsvBackLinkCreateFormType<T> = {} & BaseFormProps<T>;

const CsvBackLinkCreateForm: React.FC<CsvBackLinkCreateFormType<BacklinkFormData[]>> = ({
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [backlinksList, setBacklinksList] = useState<BacklinkImportFileValidationResult>({
    validbacklinkFormsData: [],
    invalidBacklinkImports: [],
    invalidFileImports: [],
  });

  const [backlinksFileUploaded, setBacklinksFileUploaded] = useState<boolean>(false);

  const {
    control: backLinkControl,
    formState: { errors },
    getValues,
  } = useForm<CsvBacklinkFormData>({
    defaultValues: backLinkCsvFormDataDefaultValues,
    resolver: yupResolver(csvBackLinkFormDataSchema),
    mode: 'onChange',
  });

  const handleProcessBacklinks = () => {
    const currentForm = getValues();

    const backlinksFormList: BacklinkFormData[] = [];
    backlinksList.validbacklinkFormsData.forEach((csvBacklink, index) => {
      backlinksFormList.push({
        ...csvBacklink,
        notes: currentForm.notes,
      });
    });

    onSubmit(backlinksFormList);
  };

  const handleDeleteAddedBacklink = (formData: BacklinkFormData) => {
    const filteredData = [...backlinksList.validbacklinkFormsData].filter((x) => x !== formData);
    setBacklinksList({
      ...backlinksList,
      validbacklinkFormsData: filteredData,
    });
  };

  const onBacklinkImportFileParsed = (fileData: CsvFileUploadReaderResult) => {
    const backlinksFormValidationResult = backlinkImportFileValidation(fileData);

    if (backlinksFormValidationResult) {
      setBacklinksList(backlinksFormValidationResult);
      setBacklinksFileUploaded(true);
    }
  };

  const minDate = addDays(new Date(), 1);

  return (
    <Flex flexDirection={'column'} flex={1}>
      <form>
        {backlinksList.validbacklinkFormsData.length === 0 &&
          backlinksList.invalidFileImports.length > 0 && (
            <>
              <Flex flexDir={'column'}>
                <AppText
                  textAlign={'left'}
                  w={'100%'}
                  mt="2"
                  variant={'h2'}
                  fontWeight={'medium'}
                  fontSize={'22px'}
                >
                  Error importing file.
                </AppText>
                <AppText
                  textAlign={'left'}
                  w={'100%'}
                  mt="2"
                  variant={'h3'}
                  fontWeight={'normal'}
                  fontSize={'18px'}
                >
                  The file template used might not be correct.
                </AppText>
                <AppText
                  textAlign={'left'}
                  w={'100%'}
                  mt="2"
                  variant={'h3'}
                  fontWeight={'normal'}
                  fontSize={'18px'}
                >
                  Please download the template using the Download Template button and then retry the
                  import using that file.
                </AppText>
              </Flex>
            </>
          )}
        {backlinksFileUploaded &&
          backlinksList.validbacklinkFormsData.length === 0 &&
          backlinksList.invalidBacklinkImports.length === 0 &&
          backlinksList.invalidFileImports.length === 0 && (
            <>
              <Flex flexDir={'column'}>
                <AppText
                  textAlign={'left'}
                  w={'100%'}
                  mt="2"
                  variant={'h2'}
                  fontWeight={'medium'}
                  fontSize={'22px'}
                >
                  No records found in file.
                </AppText>
                <AppText
                  textAlign={'left'}
                  w={'100%'}
                  mt="2"
                  fontWeight={'normal'}
                  fontSize={'18px'}
                >
                  The file template used might not be correct or there are no records in the file
                  uploaded.
                </AppText>
                <AppText
                  textAlign={'left'}
                  w={'100%'}
                  mt="2"
                  fontWeight={'normal'}
                  fontSize={'18px'}
                >
                  Please download the template using the Download Template button and then retry the
                  import using that file.
                </AppText>
              </Flex>
            </>
          )}
        {backlinksList.validbacklinkFormsData.length === 0 &&
          backlinksList.invalidBacklinkImports.length > 0 && (
            <>
              <Flex flexDir={'column'}>
                <AppText
                  textAlign={'left'}
                  w={'100%'}
                  mt="2"
                  variant={'h2'}
                  fontWeight={'medium'}
                  fontSize={'22px'}
                >
                  Invalid Backlink imports
                </AppText>
                <Accordion allowToggle mt={4}>
                  <AccordionItem>
                    <AccordionButton>
                      <Flex flex={1} justifyContent={'space-between'} pr={4}>
                        <AppText
                          textAlign={'left'}
                          w={'100%'}
                          variant={'h3'}
                          fontWeight={'medium'}
                          fontSize={'18px'}
                        >
                          Import Errors
                        </AppText>
                        <Tag borderRadius="full" bgColor={AppColors.secondary} textColor={'white'}>
                          <TagLabel isTruncated={false} display={'flex'} overflow={'visible'}>
                            {backlinksList.invalidBacklinkImports.length}
                          </TagLabel>
                        </Tag>
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Flex flexDir={'column'} mt="4">
                        <AppText
                          textAlign={'left'}
                          w={'100%'}
                          variant={'h3'}
                          fontWeight={'medium'}
                          fontSize={'16px'}
                        >
                          We have found the following invalid Backlinks from the import. Please
                          review their error messages.
                        </AppText>
                        <BacklinkCsvImportValidationErrors
                          validation={backlinksList}
                        ></BacklinkCsvImportValidationErrors>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>
            </>
          )}
        {backlinksList.validbacklinkFormsData.length > 0 && (
          <>
            <AppText
              textAlign={'left'}
              w={'100%'}
              mt="2"
              variant={'h2'}
              fontWeight={'medium'}
              fontSize={'20px'}
            >
              File imported Successfully
            </AppText>
            <AppText
              textAlign={'left'}
              w={'100%'}
              mt="2"
              variant={'h3'}
              fontWeight={'medium'}
              fontSize={'18px'}
            >
              Please review details below
            </AppText>
            <Accordion allowToggle mt={6}>
              <AccordionItem>
                <AccordionButton>
                  <Flex flex={1} justifyContent={'space-between'} pr={4}>
                    <AppText
                      textAlign={'left'}
                      w={'100%'}
                      variant={'h3'}
                      fontWeight={'medium'}
                      fontSize={'18px'}
                    >
                      Valid Backlinks
                    </AppText>
                    <Tag borderRadius="full" bgColor={AppColors.secondary} textColor={'white'}>
                      <TagLabel isTruncated={false} display={'flex'} overflow={'visible'}>
                        {backlinksList.validbacklinkFormsData.length}
                      </TagLabel>
                    </Tag>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  pb={4}
                  maxH={'200px'}
                  overflowY={'auto'}
                  overflowX={'auto'}
                  maxW={'100%'}
                >
                  <TableContainer overflowY={'visible'} overflowX={'visible'}>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Url</Th>
                          <Th>Price</Th>
                          <Th>Currency</Th>
                          <Th>Expected_Anchor </Th>
                          <Th>Expected_Landing_Page</Th>
                          <Th>Expected Rel</Th>
                          <Th>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {backlinksList.validbacklinkFormsData.map((link, index) => {
                          return (
                            <Tr key={`${link.url}-${index}`}>
                              <Td>
                                <AppText isTruncated>{link.url}</AppText>
                              </Td>
                              <Td>
                                <AppText isTruncated>{link.price}</AppText>
                              </Td>
                              <Td>
                                <AppText isTruncated>{link.priceCurrency}</AppText>
                              </Td>
                              <Td>
                                <AppText isTruncated>{link.expectedAnchor}</AppText>
                              </Td>
                              <Td>
                                <AppText isTruncated>{link.expectedLandingPage}</AppText>
                              </Td>
                              <Td>
                                <AppText isTruncated>{link.expectedRel}</AppText>
                              </Td>
                              <Td>
                                <Flex>
                                  <AppButton
                                    mr={4}
                                    bgColor={AppColors.secondary}
                                    variant={'solid'}
                                    size="xs"
                                    borderRadius="full"
                                    onClick={() => {
                                      handleDeleteAddedBacklink(link);
                                    }}
                                    rightIcon={<DeleteIcon />}
                                  >
                                    Delete
                                  </AppButton>
                                </Flex>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <AppDatePicker<CsvBacklinkFormData>
              name="expectedLiveDate"
              minDate={minDate}
              control={backLinkControl}
              error={undefined}
              label="Expected Live Date"
            />

            <AppInput<CsvBacklinkFormData>
              control={backLinkControl}
              error={errors.notes}
              label="Notes"
              name="notes"
              placeHolder={'Notes'}
              textArea={true}
              flex={1}
              mt="4"
            />

            {backlinksList.invalidBacklinkImports.length > 0 && (
              <Accordion allowToggle mt={4}>
                <AccordionItem>
                  <AccordionButton>
                    <Flex flex={1} justifyContent={'space-between'} pr={4}>
                      <AppText
                        textAlign={'left'}
                        w={'100%'}
                        variant={'h3'}
                        fontWeight={'medium'}
                        fontSize={'18px'}
                      >
                        Import Errors
                      </AppText>
                      <Tag borderRadius="full" bgColor={AppColors.secondary} textColor={'white'}>
                        <TagLabel isTruncated={false} display={'flex'} overflow={'visible'}>
                          {backlinksList.invalidBacklinkImports.length}
                        </TagLabel>
                      </Tag>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Flex flexDir={'column'} mt="4">
                      <AppText
                        textAlign={'left'}
                        w={'100%'}
                        variant={'h3'}
                        fontWeight={'medium'}
                        fontSize={'16px'}
                      >
                        We have found the following invalid Backlinks from the import. Please review
                        their error messages.
                      </AppText>
                      <BacklinkCsvImportValidationErrors
                        validation={backlinksList}
                      ></BacklinkCsvImportValidationErrors>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}

            {backlinksList.validbacklinkFormsData.length > 0 && (
              <>
                {backlinksList.invalidBacklinkImports.length > 0 && (
                  <Flex flexDir={'column'}>
                    <AppText
                      textAlign={'left'}
                      w={'100%'}
                      mt="6"
                      variant={'h3'}
                      fontWeight={'medium'}
                      fontSize={'18px'}
                    >
                      Please note that you can still import the above valid Backlinks.
                    </AppText>
                  </Flex>
                )}

                <AppButton
                  mt="6"
                  w={'full'}
                  isDisabled={backlinksList.validbacklinkFormsData.length === 0}
                  onClick={() => {
                    handleProcessBacklinks();
                  }}
                >
                  {'Process Backlinks'}
                </AppButton>
              </>
            )}
          </>
        )}
        <>
          <Flex flexDir={'row'} mt={6}>
            <AppCsvFileUploadReader<BacklinkCSVImport>
              requiredColumns={importBacklinkCsvRequiredColumns.map((x) => {
                return x.key;
              })}
              onFileParsed={onBacklinkImportFileParsed}
            >
              <AppButton
                leftIcon={<Icon as={iconHelper.uploadIcon} />}
                bgColor={'rgba(3, 125, 252, 0.15)'}
                borderRadius={'2xl'}
                height={'55px'}
              >
                {backlinksFileUploaded ? 'Re-Upload' : 'Upload'}
              </AppButton>
            </AppCsvFileUploadReader>
            <Link href={'/templates/import-template.csv'} target="_blank">
              <AppButton
                leftIcon={<Icon as={iconHelper.downloadIcon} />}
                bgColor={'rgba(255, 136, 0, 0.1)'}
                borderRadius={'2xl'}
                height={'55px'}
                color={AppColors.ctaColor}
              >
                Download Template
              </AppButton>
            </Link>
          </Flex>
          {/* <AppInput<CsvBacklinkFormData>
            control={backLinkControl}
            error={errors.totalPrice}
            label="Total Links Price"
            name="totalPrice"
            placeHolder={undefined}
            inputType="number"
            flex={1}
            mt="4"
          /> */}
        </>
      </form>
    </Flex>
  );
};

export default CsvBackLinkCreateForm;
