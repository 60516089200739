import React, { useState, useEffect } from 'react';
import { Flex, FormControl, Icon, IconButton, Stack, Tooltip } from '@chakra-ui/react';
import { BaseFormProps } from './base-forms';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppButton, AppColors, AppInput, SelectListFilter, SelectOption } from '@backlinkit/shared';
import { useAppSelector } from '../../store/store';
import { selectOrganizationTags } from '../../store/slices/tag.slice';
import TagManagement from '../dialog-management/tag-management';
import { useClientDialog } from '../../providers/client-dialog/use-client-dialog';
import { iconHelper } from '../../utils/iconHelper';

export type BacklinkReportFormData = {
  emailAddress: string;
  tagId: string;
  notes?: string;
};

const BacklinkReportFormDefaultValues: BacklinkReportFormData = {
  emailAddress: '',
  tagId: '',
  notes: '',
};

const BacklinkReportFormDataSchema = yup.object({
  emailAddress: yup.string().required('Field is required'),
  tagId: yup.string(),
  notes: yup.string(),
});

type BacklinkReportFormProps<T> = {
  form?: BacklinkReportFormData;
} & BaseFormProps<T>;

const ExternalReportForm: React.FC<BacklinkReportFormProps<BacklinkReportFormData>> = ({
  form,
  onSubmit,
}) => {
  const clientDialog = useClientDialog();
  const {
    control: backlinkReportControl,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<BacklinkReportFormData>({
    defaultValues: form || BacklinkReportFormDefaultValues,
    resolver: yupResolver(BacklinkReportFormDataSchema),
    mode: 'all',
  });
  const tags = useAppSelector(selectOrganizationTags);
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (tags) {
      const selectors = tags.map((x) => {
        return { label: x.name, value: x.id } as SelectOption;
      });
      setSelectOptions(selectors);
    }
  }, [tags]);

  const handleSelect = (option: SelectOption) => {
    setValue('tagId', option.value);
  };

  const handleTagManagement = () => {
    clientDialog({
      title: 'Tag Management',
      position: 3,
      size: '4xl',
      render: (onSubmit) => {
        return <TagManagement />;
      },
    });
  };

  const handleFormSubmit = () => {
    const formData = getValues();
    onSubmit && onSubmit(formData);
  };

  return (
    <form>
      <Stack spacing={4}>
        <AppInput<BacklinkReportFormData>
          name="emailAddress"
          control={backlinkReportControl}
          label="Email"
          error={errors.emailAddress}
          variant={'grayed'}
        />
        <AppInput<BacklinkReportFormData>
          name="notes"
          control={backlinkReportControl}
          label="Notes"
          textArea={true}
          error={errors.notes}
          variant={'grayed'}
        />
        <FormControl>Tag:</FormControl>
        <Flex w={'100%'} gap={'10px'}>
          <SelectListFilter
            name="tag"
            w={'100%'}
            options={selectOptions}
            onSelectionChange={(value: SelectOption[]) => {
              handleSelect(value[0]);
            }}
            isInModal
            isMulti={false}
            showClear={false}
          />

          <Tooltip shouldWrapChildren label={'Manage Tags'} aria-label="manageTags">
            <IconButton
              aria-label="add-edit-tags"
              px={3}
              bgColor={AppColors.appBackground}
              color={'black'}
              onClick={() => handleTagManagement()}
              boxSize={'54px'}
              borderRadius={'xl'}
            >
              <Icon
                as={iconHelper.settingsIcon}
                height={'25px'}
                width={'25px'}
                color={'gray.400'}
              />
            </IconButton>
          </Tooltip>
        </Flex>
        <AppButton
          isDisabled={!isValid}
          onClick={handleFormSubmit}
          bgColor={AppColors.ctaColor}
          color={'white'}
        >
          Save
        </AppButton>
      </Stack>
    </form>
  );
};

export default ExternalReportForm;
