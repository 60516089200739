import { useEffect } from 'react';
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  useColorModeValue,
  useToast,
  Icon,
} from '@chakra-ui/react';
import {
  AppColors,
  AppDataTable,
  AppLoader,
  AppPaginator,
  AppText,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  EditOrganizationExcludeLinkForm,
  OrganizationExludeLink,
  OrganizationExludeLinkForm,
  useDataTable,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  useCreateOrganizationExcludeLinkMutation,
  useDeleteOrganizationExludeLinkMutation,
  useEditOrganizationExcludeLinkMutation,
  useFetchOrganizationExcludeLinksByOptionsQuery,
} from '../../../store/api/organizationExludeLinkApi';
import { useAppSelector } from '../../../store/store';
import { selectUser } from '../../../store/slices/authentication.slice';
import UrlCell from '../../backlinks/components/cells/url-cell';
import { ExcludeLinkForm } from '../../forms/organization-exclude-link-form';
import { iconHelper } from '../../../utils/iconHelper';
import { Can } from '../../../providers/permissions-provider';

const ExcludeLinksTable: React.FC = () => {
  const user = useAppSelector(selectUser);
  const [saveExludeLinkTrigger] = useCreateOrganizationExcludeLinkMutation();
  const [editExcludeLinkTrigger] = useEditOrganizationExcludeLinkMutation();
  const [deleteExcludeLinkTrigger] = useDeleteOrganizationExludeLinkMutation();

  const dialog = useDialog();
  const { setLoading } = useLoading();
  const toast = useToast();

  const { handleOnPageChange, nextPaginationQueryParams, pagination, setPagination } =
    useDataTable<OrganizationExludeLink>({
      defaultPageSize: 5,
    });

  const {
    data: links,
    isLoading,
    refetch,
  } = useFetchOrganizationExcludeLinksByOptionsQuery(
    {
      organizationId: user?.organizationId?.toString() ?? '',
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (links?.pagination) {
      setPagination(links?.pagination);
    }
  }, [links]);

  const renderDefaultTableActions = (item: OrganizationExludeLink) => {
    return (
      <Can permission={'OrganizationEflBlacklist'} permissionLevel="Write">
        <Menu>
          <MenuButton
            bgColor={'white'}
            color={'gray.700'}
            border={`1px solid ${AppColors.appBorder}`}
            as={Button}
            rightIcon={<ChevronDownIcon color={'gray.700'} boxSize={'15px'} />}
            borderRadius="full"
            minH={'40px'}
            fontWeight={'400'}
          >
            Actions
          </MenuButton>
          <MenuList width={'100%'}>
            <MenuItem
              onClick={() => {
                handleEdit(item);
              }}
              _hover={{
                bgColor: 'transparent',
              }}
            >
              <Flex
                p={2}
                borderRadius={'md'}
                width={'100%'}
                gap={2}
                align={'center'}
                _hover={{
                  bgColor: AppColors.appBackground,
                }}
              >
                <Flex
                  p={2}
                  borderRadius={'md'}
                  justify={'center'}
                  align={'center'}
                  backgroundColor={'rgba(0, 0, 0, 0.1)'}
                >
                  <Icon as={iconHelper.editIcon} />
                </Flex>
                Edit
              </Flex>
            </MenuItem>
            {user?.id !== item.id && (
              <MenuItem
                onClick={() => {
                  handleDelete(item);
                }}
                _hover={{
                  bgColor: 'transparent',
                }}
              >
                <Flex
                  p={2}
                  borderRadius={'md'}
                  gap={2}
                  width={'100%'}
                  align={'center'}
                  _hover={{
                    bgColor: AppColors.appBackground,
                  }}
                >
                  <Flex
                    p={2}
                    borderRadius={'md'}
                    justify={'center'}
                    align={'center'}
                    backgroundColor={'rgba(0, 0, 0, 0.1)'}
                  >
                    <Icon as={iconHelper.deleteIcon} />
                  </Flex>
                  Delete
                </Flex>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Can>
    );
  };
  const linkColumns: ColumnDefinitionType<OrganizationExludeLink>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'link',
      header: 'Link',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => <UrlCell url={item.link} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  const handleEdit = (item: OrganizationExludeLink) => {
    const formData: EditOrganizationExcludeLinkForm = {
      id: item.id ?? '',
      link: item.link,
      organizationId: item.organizationId,
    };
    dialog({
      title: 'Edit Exclude Link',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <ExcludeLinkForm
            form={formData}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertExcludeLink(formData);
            }}
          />
        );
      },
    });
  };

  const handleAdd = () => {
    dialog({
      title: 'Add Exclude',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <ExcludeLinkForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertExcludeLink(formData);
            }}
          />
        );
      },
    });
  };

  const handleDelete = async (item: OrganizationExludeLink) => {
    try {
      setLoading(true);
      await deleteExcludeLinkTrigger(item.id);
      toast({
        title: 'Link deleted',
        description: "We've deleted the link you've selected",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
      refetch();
    } catch (error) {
      toast({
        title: 'Link delete error',
        description:
          "We've run into an error deleting the link you selected. Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const upsertExcludeLink = async (formData: any) => {
    let editted = formData.id ? true : false;
    try {
      setLoading(true);
      if (!editted) {
        const createFormData = formData as OrganizationExludeLinkForm;
        await saveExludeLinkTrigger({
          link: createFormData.link,
          organizationId: createFormData.organizationId,
        });
      } else {
        const editFormData = formData as EditOrganizationExcludeLinkForm;
        await editExcludeLinkTrigger({
          id: editFormData.id ?? '',
          link: editFormData.link,
          organizationId: editFormData.organizationId,
        });
        editted = true;
      }
      toast({
        title: `Exclude Link ${editted ? 'updated' : 'created'}`,
        description: `We've ${editted ? 'updated' : 'created'} your exclude link.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetch();
      setLoading(false);
    } catch (error) {
      if (editted) {
        toast({
          title: `Exclude Link updating error= ${error}`,
          description: `We've run into an issue updating your exclude link. Contact suppport for help`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: `Exlude Link already exists in the system`,
          description: `Please change the link and try again`,
          duration: 9000,
          isClosable: true,
        });
      }

      setLoading(false);
    }
  };
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <>
      {isLoading && <AppLoader />}
      <Flex justifyContent={'space-between'} m={4}>
        <AppText color={textColorPrimary} fontWeight="bold" fontSize="2xl">
          {'EFL Blacklist'}
        </AppText>
        <Flex alignItems={'center'}>
          <Can permission={'OrganizationEflBlacklist'} permissionLevel="Write">
            <Button
              onClick={handleAdd}
              height={'55px'}
              borderRadius={'full'}
              border={`1px solid ${AppColors.ctaColor}`}
              color={AppColors.ctaColor}
              bgColor={'white'}
              fontWeight={'500'}
            >
              + Add Link
            </Button>
          </Can>
        </Flex>
      </Flex>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        rounded={'2xl'}
        m={4}
        flexDir={'column'}
        flex={1}
      >
        <AppDataTable
          data={links?.data || []}
          noDataMessage={'No exclude links added, start by adding some!'}
          columns={linkColumns}
          selectableRows={false}
          searchBar={false}
        />

        <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
      </Flex>
    </>
  );
};

export default ExcludeLinksTable;
