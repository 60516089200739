import { FaBug, FaChalkboardTeacher, FaCheckCircle, FaFile, FaRegUser } from 'react-icons/fa';
import { BiEdit, BiExport } from 'react-icons/bi';
import { MdOutlineMenuOpen, MdOutlinePayment } from 'react-icons/md';
import { RiBankCardLine } from 'react-icons/ri';
import { IoShareSocialOutline } from 'react-icons/io5';
import { IoMdNotificationsOutline, IoMdRefreshCircle } from 'react-icons/io';
import { FaEdit } from 'react-icons/fa';
import { IoGlobeOutline } from 'react-icons/io5';
import { FaCircleXmark, FaLink } from 'react-icons/fa6';
import { BiNotepad } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaCog } from 'react-icons/fa';
import { FaCircleUser } from 'react-icons/fa6';
import { FaRegImages } from 'react-icons/fa6';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IoMdSave } from 'react-icons/io';
import { FaDownload } from 'react-icons/fa';
import { IoPricetagsOutline } from 'react-icons/io5';
import { RxDashboard } from 'react-icons/rx';
import { IoMdLogOut } from 'react-icons/io';
import { HiClipboardList } from 'react-icons/hi';

export const iconHelper = {
  userIcon: FaRegUser,
  editIcon: BiEdit,
  paymentIcon: MdOutlinePayment,
  creditIcon: RiBankCardLine,
  shareIcon: IoShareSocialOutline,
  notificationIcon: IoMdNotificationsOutline,
  secondaryEditIcon: FaEdit,
  globeIcon: IoGlobeOutline,
  linkIcon: FaLink,
  noteIcon: BiNotepad,
  deleteIcon: RiDeleteBin6Line,
  settingsIcon: FaCog,
  userProfileIcon: FaCircleUser,
  imageIcon: FaRegImages,
  redirectIcon: FaExternalLinkAlt,
  saveIcon: IoMdSave,
  downloadIcon: FaDownload,
  tagsIcon: IoPricetagsOutline,
  dashboardIcon: RxDashboard,
  logoutIcon: IoMdLogOut,
  goToDashboardIcon: MdOutlineMenuOpen,
  traingingAreaIcon: FaChalkboardTeacher,
  bugIcon: FaBug,
  uploadIcon: FaFile,
  copyIcon: HiClipboardList,
  exportIcon: BiExport,
  refreshIcon: IoMdRefreshCircle,
  checkIcon: FaCheckCircle,
  closeIcon: FaCircleXmark,
};
