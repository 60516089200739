import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  GridItem,
  useBreakpointValue,
  Icon,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectAvailableCredits, selectUser } from '../../store/slices/authentication.slice';
import { AppColors, AppLoader, AppText, getSumOfNumbers } from '@backlinkit/shared';
import { useState } from 'react';
import { DomainStats } from '../dashboard/dashboard';
import UsersTable from '../../components/app/app-organization-users/organizationUsers';
import { Subscription } from '../dashboard/subscription';
import { selectDomains } from '../../store/slices/domain.slice';
import AppTabs from '@backlinkit/shared/lib/components/app-tabs/app-tabs';
import { format } from 'date-fns';
import CompetitorTable from '../../components/app/app-organization-competitors/app-organization-competitors';
import ExcludeLinksTable from '../../components/app/app-organization-exclude-links/app-organziation-exclude-links';
import creditIcon from '../../assets/credit-icon.svg';
import paymentIcon from '../../assets/payment-icon.svg';
import planIcon from '../../assets/plan-icon.svg';
import { Can, usePermissions } from '../../providers/permissions-provider';
import { useAppSelector } from '../../store/store';
import { iconHelper } from '../../utils/iconHelper';
import { useFetchCustomerSubscriptionsQuery } from '../../store/api/paddleApi';

export const OrganizationPage: React.FC = () => {
  const user = useSelector(selectUser);
  const availableCredits = useAppSelector(selectAvailableCredits);
  const organization = user?.organization;
  const domains = useSelector(selectDomains);
  const [domainStats, setDomainStats] = useState<DomainStats>();
  const [panelTitles, setPanelTitles] = useState<string[]>();
  const [tabs, setTabs] = useState<React.ReactNode[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { can } = usePermissions();

  const { data } = useFetchCustomerSubscriptionsQuery({}, { refetchOnMountOrArgChange: true });

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  // const dateSubscribed = new Date(organization?.product?.dateCreated ?? '')
  //   .toUTCString()
  //   .slice(0, 16);

  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  });
  const [largeDesktop] = useMediaQuery('(min-width: 1500px)');

  useEffect(() => {
    if (domains) {
      const backLinkCountList = domains.map((x) => x.backlinkCount);
      const errorCountList = domains.map((x) => x.backlinkErrorCount);
      const domainStatsData = {
        domainCount: domains.length,
        linkCount: getSumOfNumbers(backLinkCountList),
        errorCount: getSumOfNumbers(errorCountList),
      };

      setDomainStats(domainStatsData);
    }
  }, [domains]);

  useEffect(() => {
    const list = [];
    const tabList = [];

    if (can('OrganizationUsers', 'Read')) {
      list.push('Users');
      tabList.push(<UsersTable />);
    }

    if (can('OrganizationCompetitors', 'Read')) {
      list.push('Competitors');
      tabList.push(<CompetitorTable />);
    }

    if (can('OrganizationEflBlacklist', 'Read')) {
      list.push('EFL Blacklist');
      tabList.push(<ExcludeLinksTable />);
    }
    // if (can('OrganizationBilling', 'Read')) {
    //   list.push('Transactions (Coming Soon)');
    //   list.push('Invoices (Coming Soon)');
    // }

    setPanelTitles(list);
    setTabs(tabList);
  }, []);

  return (
    <Box p={'20px'} w="100%">
      {isLoading && <AppLoader />}
      <Box display={variant === 'desktop' ? 'grid' : 'flex'} flexDir={'column'} h="100%" w="100%">
        <Can permission={'OrganizationBilling'} permissionLevel="Read">
          <GridItem
            rowSpan={variant === 'desktop' ? 4 : 2}
            rowStart={1}
            gridColumnStart={variant === 'desktop' ? 1 : 1}
            gridColumnEnd={variant === 'desktop' ? 6 : 8}
            pos={'relative'}
            mr={4}
          >
            <Flex
              bgColor={'white'}
              flexDir={'column'}
              w={'full'}
              h={'full'}
              borderRadius={'2xl'}
              align={'center'}
            >
              <Subscription
                used={domainStats ? domainStats.linkCount : 0}
                total={user?.organization?.product?.linkLimit ?? 0}
                theme="dark"
              />
            </Flex>
          </GridItem>
          <GridItem
            rowSpan={variant === 'desktop' ? 4 : 2}
            rowStart={variant === 'desktop' ? 1 : 3}
            gridColumnStart={variant === 'desktop' ? 6 : 1}
            gridColumnEnd={13}
            borderRadius={'2xl'}
            bgColor={AppColors.secondary2}
            p={4}
            display={'flex'}
            flexDir={'column'}
            justifyContent={'space-between'}
          >
            <AppText fontSize={'2xl'} fontWeight={'bold'} color={'white'}>
              Billing Details
            </AppText>
            <Flex
              justify={'space-evenly'}
              align={'end'}
              gap={4}
              height={'100px'}
              bgColor={AppColors.secondary2}
            >
              <Flex
                w={'full'}
                justify={'space-between'}
                h={'100%'}
                bgColor={'white'}
                p={4}
                gap={2}
                borderRadius={'xl'}
              >
                <Icon
                  as={iconHelper.userIcon}
                  boxSize={{ md: '22px', lg: '25px' }}
                  color={AppColors.secondary2}
                />
                <Flex flexDir={'column'} justify={'space-between'} width={'100%'}>
                  <AppText>
                    {data && data.data && data.data.length > 0 && data.data[0].nextBilledAt
                      ? `Next billing`
                      : 'Billing Type'}
                  </AppText>
                  <AppText fontWeight={'600'} variant={largeDesktop ? 'h5' : 'body'}>
                    {data && data.data && data.data.length > 0 && data.data[0].nextBilledAt
                      ? format(new Date(data.data[0].nextBilledAt), 'dd MMM yyyy')
                      : 'Free Trial'}
                  </AppText>
                </Flex>
              </Flex>

              <Flex
                w={'full'}
                justify={'space-between'}
                gap={2}
                h={'100%'}
                bgColor={'white'}
                p={4}
                borderRadius={'xl'}
              >
                <Image src={planIcon} boxSize={{ md: '22px', lg: '25px' }} />
                <Flex flexDir={'column'} justify={'space-between'} width={'100%'}>
                  <AppText>Plan</AppText>
                  <AppText fontWeight={'600'} variant={largeDesktop ? 'h5' : 'body'}>
                    {user?.organization.product?.name}
                  </AppText>
                </Flex>
              </Flex>

              <Flex
                w={'full'}
                justify={'space-between'}
                gap={2}
                h={'100%'}
                p={4}
                borderRadius={'xl'}
                bgColor={'white'}
              >
                <Image src={paymentIcon} boxSize={{ md: '22px', lg: '25px' }} />
                <Flex flexDir={'column'} justify={'space-between'} width={'100%'}>
                  <AppText>Payment</AppText>
                  <AppText
                    fontWeight={'600'}
                    variant={largeDesktop ? 'h5' : 'body'}
                  >{`$${organization?.product?.price}`}</AppText>
                </Flex>
              </Flex>

              <Flex
                w={'full'}
                justify={'space-between'}
                gap={2}
                h={'100%'}
                p={4}
                borderRadius={'xl'}
                bgColor={'white'}
              >
                <Image src={creditIcon} boxSize={{ md: '22px', lg: '25px' }} />
                <Flex flexDir={'column'} justify={'space-between'} width={'100%'}>
                  <AppText>Available Credit</AppText>
                  <AppText fontWeight={'600'} variant={largeDesktop ? 'h5' : 'body'}>
                    {availableCredits}
                  </AppText>
                </Flex>
              </Flex>
            </Flex>
          </GridItem>
        </Can>
        <GridItem
          mt={4}
          rowSpan={variant === 'desktop' ? 11 : 7}
          colSpan={{ sm: 12, md: 12, lg: 12 }}
          bgColor={'white'}
          rounded={'2xl'}
        >
          {panelTitles && tabs && (
            <AppTabs
              bgColor={'white'}
              rounded={'2xl'}
              overflow={'auto'}
              p={4}
              mb={{ base: '0px', '2xl': '20px' }}
              titles={panelTitles}
              tabs={tabs}
              panelHeight="100%"
            />
          )}

          {/* <TransactionsTable />, <InvoicesTable /> */}
        </GridItem>
      </Box>
    </Box>
  );
};
