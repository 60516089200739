import React from 'react';
import { Flex, Alert, Box } from '@chakra-ui/react';
import { AppText } from '../app-text/app-text';
import { CloseIcon } from '@chakra-ui/icons';
import { Notification, NotificationType } from '../../models';
import { AppColors } from '../../theme';

export type AppUserNotificationProps = {
  item: Notification;
  itemsClearable?: boolean;
  onItemClearClick: (item: Notification) => void;
  onItemClick?: (item: Notification) => void;
};

export const AppUserNotification: React.FC<AppUserNotificationProps> = ({
  item,
  itemsClearable = false,
  onItemClearClick,
  onItemClick,
}) => {
  const handleClearAlertClick = (item: Notification) => {
    itemsClearable && onItemClearClick && onItemClearClick(item);
  };

  const getAlertType = (item: Notification) => {
    switch (item.notificationType) {
      case 0:
        return 'info';
      case 1:
        return 'warning';
      case 2:
        return 'error';
      default:
        return 'info';
    }
  };

  const getLetters = () => {
    switch (item.notificationType) {
      case NotificationType.BACKLINKDATA:
      case NotificationType.BACKLINKMANUALRESCRAPE:
        return 'B';
      case NotificationType.BACKLINKGOOGLEDATA:
        return 'BG';
      case NotificationType.USERCHECKERLINKDATA:
        return 'GC';
      case NotificationType.UPDATEORGNIZATION:
        return 'OG';
      case NotificationType.BROADCAST:
        return 'B';
      case NotificationType.PAYMENTSUCCESS:
      case NotificationType.PAYMENTFAILED:
      case NotificationType.PAYMENTREMINDER:
        return 'P';
      case NotificationType.EXTERNALREPORTSENT:
      case NotificationType.EXTERNALREPORTBACKLINKFIXED:
      case NotificationType.EXTERNALUSERREPORTVIEW:
        return 'ER';
      case NotificationType.INVITEACCEPTED:
        return 'IN';
      default:
        return '';
    }
  };

  return (
    <Flex
      justifyContent="start"
      alignItems="start"
      mb={2}
      bgColor={AppColors.notificationBackground}
      onClick={() => onItemClick && onItemClick(item)}
      p={4}
      borderRadius={'2xl'}
      gap={4}
    >
      <Flex
        boxSize={'40px'}
        minW={'40px'}
        borderRadius={'full'}
        bgColor={AppColors.notificationTagBackground}
        justify={'center'}
        align={'center'}
      >
        <AppText color={'white'}>{getLetters()}</AppText>
      </Flex>
      <Flex w={'100%'} direction={'column'}>
        <Flex w={'100%'} justifyContent={'space-between'} alignItems="center">
          <AppText mr={2} fontWeight={'bold'}>
            {item.title}
          </AppText>

          {itemsClearable && (
            <CloseIcon
              onClick={() => handleClearAlertClick(item)}
              width={'10px'}
              height={'10px'}
              _hover={{
                cursor: 'pointer',
              }}
            />
          )}
        </Flex>

        <Flex mt={2} align={'center'} gap={4}>
          <Flex direction={'row'} justifyContent={'start'} alignItems="center">
            <AppText fontSize={'15px'} fontWeight={300}>
              {item.description}
            </AppText>
          </Flex>
        </Flex>

        {item.dateCreated && (
          <Flex mt={2} direction={'row'}>
            <AppText fontSize={'12px'}>{item.dateCreated.toString().slice(0, 10)}</AppText>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
