import React, { useEffect, useState } from 'react';
import { Box, Flex, FormLabel } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppButton, AppColors, AppInput, SelectListFilter, SelectOption } from '@backlinkit/shared';
import { useFetchRolesQuery } from '../../store/api/roleApi';
import { useParams } from 'react-router-dom';
import { containsNumericRegex, containsUpperCaseRegex } from '../../models/Regex';

export interface UserCreateFormData {
  email: string;
  password: string;
  roleId: string;
}

export const UserCreateFormDataDefaultValues: UserCreateFormData = {
  email: '',
  password: '',
  roleId: '',
};

const UserCreateFormDataSchema = yup.object({
  email: yup.string().email().required('Field is required'),
  password: yup
    .string()
    .required('Field is required')
    .min(8, 'At least 8 characters')
    .matches(containsNumericRegex, 'At least 1 number')
    .matches(containsUpperCaseRegex, 'At least 1 capital letter'),
  roleId: yup.string(),
});

type UserCreateFormProps<T> = {
  form?: UserCreateFormData;
} & BaseFormProps<T>;

const UserCreateForm: React.FC<UserCreateFormProps<UserCreateFormData>> = ({ form, onSubmit }) => {
  const [roleList, setRoleList] = useState<SelectOption[]>([]);
  const { data: roles } = useFetchRolesQuery({});

  useEffect(() => {
    if (roles) {
      const selectors: SelectOption[] = roles.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setRoleList(selectors);
    }
  }, [roles]);

  const {
    control: UserCreateControl,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm<UserCreateFormData>({
    defaultValues: form || UserCreateFormDataDefaultValues,
    resolver: yupResolver(UserCreateFormDataSchema),
    mode: 'onChange',
  });

  return (
    <Flex dir="column" w={'full'}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          width: '100%',
        }}
      >
        <AppInput<UserCreateFormData>
          control={UserCreateControl}
          name="email"
          label="Email"
          placeHolder="Email"
          error={errors.email}
          variant="grayed"
        />
        <AppInput<UserCreateFormData>
          control={UserCreateControl}
          name="password"
          label="Password"
          placeHolder="Password"
          error={errors.password}
          variant="grayed"
        />
        <Box flex={1}>
          <FormLabel mb={2} p={0}>
            Role
          </FormLabel>
          <SelectListFilter
            name="role"
            options={roleList}
            isInModal
            onSelectionChange={(items: SelectOption[]) => {
              if (items) {
                setValue('roleId', items[0]?.value, { shouldValidate: true });
              }
            }}
            w={'100%'}
          />
        </Box>

        <AppButton
          w={'full'}
          mt={8}
          isDisabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          bgColor={AppColors.ctaColor}
          color={'white'}
        >
          Save
        </AppButton>
      </form>
    </Flex>
  );
};

export default UserCreateForm;
