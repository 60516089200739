import { Flex, Box, Image, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { useLazyVerifyExternalReportQuery } from '../../store/api/unAuthBacklinkReportApi';
import { useNavigate } from 'react-router-dom';
import OTPForm, { OTPFormData } from '../../components/forms/external-report-otp-form';
import { AppColors, useLoading } from '@backlinkit/shared';
import loginImage from '../../assets/login-image.png';

type ExternalReportOtpVerificationPageProps = {
  id: string;
};

const ExternalReportOtpVerificationPage: React.FC<ExternalReportOtpVerificationPageProps> = ({
  id,
}) => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [verifyOtp] = useLazyVerifyExternalReportQuery();
  const [smallDevice] = useMediaQuery('(max-width: 1400px)');

  const handleClick = async (data: OTPFormData) => {
    try {
      setLoading(true);
      const verified = await verifyOtp({ id, otp: data.otp ?? 0 }).unwrap();
      if (verified) {
        navigate(`/external-report/${id}`);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Flex h={'100%'} align={'center'} justify={'center'} bgColor={AppColors.secondary2}>
      <Flex
        w={'100%'}
        maxW={{ base: '650px', xl: '100%' }}
        justify={'space-evenly'}
        align={'center'}
        p={{ base: 4, sm: 6, md: 8 }}
      >
        <OTPForm
          onSubmit={(data) => {
            handleClick(data);
          }}
        />
      </Flex>
      {!smallDevice && (
        <Flex w={'100%'} height={'100%'} justify={'center'} align={'center'}>
          <Image maxH={'640px'} maxW={'760px'} src={loginImage} />
        </Flex>
      )}
    </Flex>
  );
};
export default ExternalReportOtpVerificationPage;
