import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Text,
  useColorModeValue,
  useToast,
  Icon,
} from '@chakra-ui/react';
import {
  AppColors,
  AppDataTable,
  AppLoader,
  AppPaginator,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  EditOrganizationCompetitorForm,
  OrganizationCompetitor,
  OrganizationCompetitorForm,
  useDataTable,
  useDialog,
  useLoading,
} from '@backlinkit/shared';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  useCreateOrganizationCompetitorMutation,
  useDeleteOrganizationCompetitorMutation,
  useEditOrganizationCompetitorMutation,
  useFetchOrganizationCompetitorsByOptionsQuery,
} from '../../../store/api/organization-competitorsApi';
import { useAppSelector } from '../../../store/store';
import { selectUser } from '../../../store/slices/authentication.slice';
import { CompetitorForm } from '../../forms/organization-competitor-form';
import UrlCell from '../../backlinks/components/cells/url-cell';
import { iconHelper } from '../../../utils/iconHelper';
import { Can } from '../../../providers/permissions-provider';
import { useEffect } from 'react';

const CompetitorTable: React.FC = () => {
  const [saveCompetitorTrigger] = useCreateOrganizationCompetitorMutation();
  const [editCompetiotorTrigger] = useEditOrganizationCompetitorMutation();
  const [deleteOrganizationCompetitorTrigger] = useDeleteOrganizationCompetitorMutation();
  const dialog = useDialog();
  const { setLoading } = useLoading();
  const toast = useToast();
  const user = useAppSelector(selectUser);

  const { handleOnPageChange, nextPaginationQueryParams, pagination, setPagination } =
    useDataTable<OrganizationCompetitor>({
      defaultPageSize: 5,
    });

  const {
    data: competitors,
    isLoading,
    refetch,
  } = useFetchOrganizationCompetitorsByOptionsQuery(
    {
      organizationId: user?.organizationId?.toString() ?? '',
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (competitors?.pagination) {
      setPagination(competitors?.pagination);
    }
  }, [competitors]);

  const renderDefaultTableActions = (item: OrganizationCompetitor) => {
    return (
      <Can permission={'OrganizationCompetitors'} permissionLevel="Write">
        <Menu>
          <MenuButton
            bgColor={'white'}
            color={'gray.700'}
            border={`1px solid ${AppColors.appBorder}`}
            as={Button}
            rightIcon={<ChevronDownIcon color={'gray.700'} boxSize={'15px'} />}
            borderRadius="full"
            minH={'40px'}
            fontWeight={'400'}
          >
            Actions
          </MenuButton>
          <MenuList borderRadius={'2xl'}>
            <MenuItem
              onClick={() => {
                handleEdit(item);
              }}
              _hover={{
                bgColor: 'transparent',
              }}
            >
              <Flex
                p={2}
                borderRadius={'md'}
                width={'100%'}
                gap={2}
                align={'center'}
                _hover={{
                  bgColor: AppColors.appBackground,
                }}
              >
                <Flex
                  p={2}
                  borderRadius={'md'}
                  justify={'center'}
                  align={'center'}
                  backgroundColor={'rgba(0, 0, 0, 0.1)'}
                >
                  <Icon as={iconHelper.editIcon} />
                </Flex>
                Edit
              </Flex>
            </MenuItem>
            {user?.id !== item.id && (
              <MenuItem
                onClick={() => {
                  handleDelete(item);
                }}
                _hover={{
                  bgColor: 'transparent',
                }}
              >
                <Flex
                  p={2}
                  borderRadius={'md'}
                  gap={2}
                  width={'100%'}
                  align={'center'}
                  _hover={{
                    bgColor: AppColors.appBackground,
                  }}
                >
                  <Flex
                    p={2}
                    borderRadius={'md'}
                    justify={'center'}
                    align={'center'}
                    backgroundColor={'rgba(0, 0, 0, 0.1)'}
                  >
                    <Icon as={iconHelper.deleteIcon} />
                  </Flex>
                  Delete
                </Flex>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Can>
    );
  };
  const competitorColumns: ColumnDefinitionType<OrganizationCompetitor>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'competitorName',
      header: 'Name',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'competitorDomain',
      header: 'Domain',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => <UrlCell url={item.competitorDomain} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  const handleEdit = (competitor: OrganizationCompetitor) => {
    const formData: EditOrganizationCompetitorForm = {
      id: competitor.id ?? '',
      competitorDomain: competitor.competitorDomain,
      competitorName: competitor.competitorName,
      organizationId: competitor.organizationId ?? '',
    };
    dialog({
      title: 'Edit Competitor',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <CompetitorForm
            form={formData}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertCompetitor(formData);
            }}
          />
        );
      },
    });
  };

  const handleAdd = () => {
    dialog({
      title: 'Create Competitor',
      showCancel: true,
      render: (onSubmit) => {
        return (
          <CompetitorForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertCompetitor(formData);
            }}
          />
        );
      },
    });
  };

  const upsertCompetitor = async (formData: any) => {
    let edittedCompetitor = formData.id ? true : false;
    try {
      setLoading(true);
      if (!edittedCompetitor) {
        const createFormData = formData as OrganizationCompetitorForm;
        await saveCompetitorTrigger({
          competitorDomain: createFormData.competitorDomain,
          competitorName: createFormData.competitorName,
          organizationId: createFormData.organizationId,
        });
      } else {
        const editFormData = formData as EditOrganizationCompetitorForm;
        await editCompetiotorTrigger({
          id: editFormData.id ?? '',
          competitorDomain: editFormData.competitorDomain,
          competitorName: editFormData.competitorName,
          organizationId: editFormData.organizationId ?? '',
        });
        edittedCompetitor = true;
      }
      toast({
        title: `Competitor ${edittedCompetitor ? 'updated' : 'created'}`,
        description: `We've ${edittedCompetitor ? 'updated' : 'saved'} your competitor for you.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetch();
      setLoading(false);
    } catch (error) {
      if (edittedCompetitor) {
        toast({
          title: `Competitor updating. error= ${error}`,
          description: `We've run into a problem updating your competitor, Contact support for help`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: `Competitor already exists in the system.`,
          description: `Please change the competitor name & try again`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
      setLoading(false);
    }
  };

  const handleDelete = async (competitor: OrganizationCompetitor) => {
    try {
      setLoading(true);
      await deleteOrganizationCompetitorTrigger(competitor.id);
      refetch();
      setLoading(false);
    } catch (error) {
      toast({
        title: 'Competitor Delete Error',
        description:
          "We've run into a problem deleting the selected competitor. Contact suppport for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    toast({
      title: 'Competitor deleted',
      description: "We've deleted the competitor you've selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <>
      {isLoading && <AppLoader />}
      <Flex justifyContent={'space-between'} m={4}>
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
          {'Competitors'}
        </Text>
        <Flex alignItems={'center'}>
          <Can permission={'OrganizationCompetitors'} permissionLevel="Write">
            <Button
              onClick={handleAdd}
              borderRadius={'full'}
              bgColor={'white'}
              border={`1px solid ${AppColors.ctaColor} !important`}
              height={'50px'}
              fontSize={'16px'}
              fontWeight={'500'}
              color={AppColors.ctaColor}
            >
              + Add Competitor
            </Button>
          </Can>
        </Flex>
      </Flex>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        rounded={'2xl'}
        m={4}
        flexDir={'column'}
        flex={1}
      >
        <AppDataTable
          data={competitors?.data || []}
          noDataMessage={'No competitors added, start by adding some!'}
          columns={competitorColumns}
          selectableRows={false}
          searchBar={false}
        />
        <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
      </Flex>
    </>
  );
};

export default CompetitorTable;
