import { createStandaloneToast } from '@chakra-ui/react';
import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';

export const { ToastContainer, toast } = createStandaloneToast();

export const ErrorMiddleware: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

  if (isRejectedWithValue(action)) {
    if (
      action?.payload?.status === 400 &&
      action?.payload?.data &&
      action?.payload?.data?.errorMessage
    ) {
      toast({
        title: 'An error occurred.',
        description: action?.payload?.data?.errorMessage,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'An error occurred.',
        description: 'Unable to fetch your data.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return next(action);
};
