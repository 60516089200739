import React from 'react';
import { Flex, Image, useToast, useMediaQuery } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { useUserAuthenticationMutation } from '../../store/api/authenticationApi';
import { setUserAuth, logout } from '../../store/slices/authentication.slice';
import LoginForm, { LoginFormData } from '../../components/forms/login-form';
import { AppColors, AppText } from '@backlinkit/shared';
import { usePermissions } from '../../providers/permissions-provider';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [smallDevice] = useMediaQuery('(max-width: 1400px)');
  const { can } = usePermissions();

  const [userAuthentication, { isLoading }] = useUserAuthenticationMutation();

  const dispatch = useDispatch();

  const signIn = async (loginFormData: LoginFormData) => {
    try {
      await dispatch(logout());

      const res = await userAuthentication({
        username: loginFormData.email,
        password: loginFormData.password,
      }).unwrap();

      dispatch(setUserAuth(res));
      localStorage.setItem(`bck_id_token`, res.token);

      if (res && res.user && res.user.organization && res.user.organization.subscriptionIsActive) {
        if (can('Domains', 'Read', res.user)) {
          navigate(RoutesList.Dashboard);
        } else {
          navigate(RoutesList.GoogleChecker);
        }
      } else if (
        res &&
        res.user &&
        res.user.organization &&
        res.user.organization.createdUserId === res.user.id
      ) {
        navigate(RoutesList.Products);
      } else {
        toast({
          title: 'Your trail has ended!',
          description:
            'The owner of this organization will need to subscribe to one of our products.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }

      // if (organizationRole === OrganizationRoleType.ADMIN) {
      //   navigate(RoutesList.Dashboard);
      // } else {
      //   navigate(RoutesList.Batches);
      // }
    } catch (errResponse) {
      // console.error(errResponse);
      // const httpError: HttpError = (errResponse as any).data as HttpError;
      // toast({
      //   title: 'Login Failed',
      //   description: httpError?.error?.message || 'Login failed.',
      //   status: 'error',
      //   duration: 9000,
      //   isClosable: true,
      // });
    }
  };

  // const goToUserRegistration = () => {
  //   navigate(`${RoutesList.Registration}`);
  // };
  const image = `https://backlinkitprod.blob.core.windows.net/assets/b3929817-7e2c-40dd-a252-984dd69fa28e_login-image.png-1736766293157`;

  return (
    <Flex h={'100vh'} align={'center'} justify={'center'} bgColor={AppColors.secondary2}>
      <Flex
        flexDir={'row-reverse'}
        w={'100%'}
        justify={'space-evenly'}
        height={'100%'}
        align={'center'}
        p={{ base: 4, sm: 6, md: 8 }}
        gap={'80px'}
      >
        {!smallDevice && <Image maxH={'640px'} maxW={'760px'} src={image} />}
        <Flex
          flexDir={'column'}
          bg={'white'}
          justify={'center'}
          align={'center'}
          borderRadius={'40px'}
          p={{ base: 4, sm: 6, md: 8 }}
          gap={8}
          w={'100%'}
          maxW={'650px'}
          height={'100%'}
          overflow={'auto'}
          my={{ base: 4, sm: 6, md: 8 }}
        >
          <AppText variant={'h2'} fontWeight={'semibold'}>
            Login
          </AppText>
          <Flex
            as={'form'}
            mt={10}
            width={'100%'}
            flexDir={'column'}
            justify={'center'}
            align={'center'}
          >
            <LoginForm isLoading={isLoading} onSubmit={signIn}></LoginForm>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Login;
