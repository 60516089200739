import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  OrganizationUser,
  PaginatedData,
  User,
  UserByOptionsRequest,
  UserCreateForm,
  UserEditForm,
} from '@backlinkit/shared';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchUsersByOrganizationId: build.query<OrganizationUser[], string>({
      query: (orgId) => ({
        url: `/user/by/organization/${orgId}`,
        method: 'GET',
      }),
      transformResponse: (response: OrganizationUser[]) => {
        return response;
      },
    }),
    fetchUsersByOptions: build.query<PaginatedData<User>, UserByOptionsRequest>({
      query: (data) => ({
        url: `/user/by/options`,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: PaginatedData<User>) => {
        return response;
      },
    }),
    fetchUserById: build.query<User, string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: User) => {
        return response;
      },
    }),
    userCreate: build.mutation<User, UserCreateForm>({
      query: (body) => ({
        url: `/user/create`,
        method: 'POST',
        body: body,
      }),
    }),
    userUpdate: build.mutation<User, UserEditForm>({
      query: (payload) => ({
        url: `user/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    userMarkSignIn: build.mutation<User, string>({
      query: (userId) => ({
        url: `/user/mark/signedin/${userId}`,
        method: 'PATCH',
      }),
    }),
    userDelete: build.mutation<User, string>({
      query: (userId) => ({
        url: `/user/delete/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useUserCreateMutation,
  useFetchUsersByOrganizationIdQuery,
  useFetchUsersByOptionsQuery,
  useLazyFetchUserByIdQuery,
  useUserMarkSignInMutation,
  useUserUpdateMutation,
  useUserDeleteMutation,
} = userApi;
