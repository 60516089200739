import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../store/slices/authentication.slice';
import { RoutesList } from './router';
import { selectDomain } from '../store/slices/domain.slice';
import { selectBacklink } from '../store/slices/backlink.slice';
import { useAppSelector } from '../store/store';

interface ProtectedRouteProps {
  route?: RoutesList;
  children: React.ReactNode | React.ReactNode[] | undefined;
}

const ProtectedRoute = ({ route, children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userIsAuthenticated = useAppSelector(isAuthenticated);
  // const domain = useAppSelector(selectDomain);
  // const backlink = useAppSelector(selectBacklink);

  const checkUserToken = () => {
    console.log('isLoggedIn', isLoggedIn);

    if (!userIsAuthenticated) {
      console.log('NOT userIsAuthenticated', userIsAuthenticated);

      setIsLoggedIn(false);
      return navigate(RoutesList.Index);
    }
    setIsLoggedIn(true);
    // routeConditionsGaurd();
  };

  // const routeConditionsGaurd = () => {
  //   if (route && userIsAuthenticated) {
  //     switch (route) {
  //       case RoutesList.Account:
  //       case RoutesList.OrganizationSubscriptions:
  //       case RoutesList.Dashboard:
  //       case RoutesList.GoogleChecker:
  //         // if (!isUserAdmin) {
  //         return navigate(RoutesList.Batches);
  //         // }
  //         break;
  //       case RoutesList.SecondTierLinks:
  //         if (!backlink && domain) {
  //           return navigate(RoutesList.Links);
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return <React.Fragment>{isLoggedIn ? children : null}</React.Fragment>;
};
export default ProtectedRoute;
