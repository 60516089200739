import { Tag, TagLabel, Tooltip, Icon, Flex } from '@chakra-ui/react';
import React from 'react';
import { FaTruckLoading, FaUserCircle } from 'react-icons/fa';
import { LinkStatus, RobotsIndexStatus, AppColors, AppText } from '@backlinkit/shared';
import { format } from 'date-fns';

type LinkStatusCellProps = {
  isManual?: boolean;
  linkStatus?: string;
  robotsIndexValue?: string;
  expectedLiveDate?: string | Date;
};

const LinkStatusCell: React.FC<LinkStatusCellProps> = ({
  isManual,
  linkStatus,
  robotsIndexValue,
  expectedLiveDate,
}) => {
  const today = new Date();
  const dateExpected = expectedLiveDate ? format(new Date(expectedLiveDate), 'dd MMM yyyy') : '';

  const renderLinkStatus = () => {
    if (!linkStatus) {
      return (
        <Tooltip label="Busy processing">
          <span>
            <Icon ml="3" color={'grey'} as={FaTruckLoading} />
          </span>
        </Tooltip>
      );
    }
    switch (linkStatus) {
      case LinkStatus.Ok:
      case LinkStatus.LinkNotFound:
      case LinkStatus.AnchorTextMismatch:
        return (
          <Tooltip label="(200) returned the requested data">
            <Tag borderRadius="full" colorScheme={'green'}>
              <TagLabel>200</TagLabel>
            </Tag>
          </Tooltip>
        );
      // case LinkStatus.LinkNotFound:
      //   return (
      //     <Tooltip label="No Href found">
      //       <Tag borderRadius="full" colorScheme={'red'}>
      //         <TagLabel>NHF</TagLabel>
      //       </Tag>
      //     </Tooltip>
      //   );
      // case LinkStatus.AnchorTextMismatch:
      //   return (
      //     <Tooltip label="Anchor text mismatch">
      //       <Tag borderRadius="full" colorScheme={'red'}>
      //         <TagLabel>ATM</TagLabel>
      //       </Tag>
      //     </Tooltip>
      //   );
      case LinkStatus.InternalError:
        return (
          <Tooltip label="(500) Internal Server error">
            <Tag borderRadius="full" colorScheme={'red'}>
              <TagLabel>500</TagLabel>
            </Tag>
          </Tooltip>
        );
      case LinkStatus.NotFound:
        return (
          <Tooltip label="(404) Page not found">
            <Tag borderRadius="full" colorScheme={'red'}>
              <TagLabel>404</TagLabel>
            </Tag>
          </Tooltip>
        );
      case LinkStatus.Unauthorized:
        return (
          <Tooltip label="(403) Forbidden">
            <Tag borderRadius="full" colorScheme={'red'}>
              <TagLabel>403</TagLabel>
            </Tag>
          </Tooltip>
        );
      case LinkStatus.Gone:
        return (
          <Tooltip label="(410) Removed permanently">
            <Tag borderRadius="full" colorScheme={'red'}>
              <TagLabel>410</TagLabel>
            </Tag>
          </Tooltip>
        );
      default:
        break;
    }
  };

  const isNotProspectLink = () => {
    return expectedLiveDate == null || (expectedLiveDate && expectedLiveDate >= today);
  };

  return (
    <Flex flexDirection={'row'} alignItems={'center'}>
      {isNotProspectLink() && renderLinkStatus()}
      {robotsIndexValue && isNotProspectLink() ? (
        <>
          {robotsIndexValue?.includes(RobotsIndexStatus.NoIndex) && (
            <Tooltip label="NoIndex (set by robots meta tag)">
              <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                <TagLabel>{'NI'}</TagLabel>
              </Tag>
            </Tooltip>
          )}
          {robotsIndexValue?.includes(RobotsIndexStatus.Nofollow) && (
            <Tooltip label="NoFollow (set by robots meta tag)">
              <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                <TagLabel>{'NF'}</TagLabel>
              </Tag>
            </Tooltip>
          )}
          {robotsIndexValue?.includes(RobotsIndexStatus.follow) && (
            <Tooltip label="Follow (set by robots meta tag)">
              <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                <TagLabel>{'FL'}</TagLabel>
              </Tag>
            </Tooltip>
          )}
          {robotsIndexValue?.includes(RobotsIndexStatus.Indexed) && (
            <Tooltip label="Indexed (set by robots meta tag)">
              <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
                <TagLabel>{'IN'}</TagLabel>
              </Tag>
            </Tooltip>
          )}
        </>
      ) : (
        isNotProspectLink() && (
          <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
            <TagLabel>{'Unset'}</TagLabel>
          </Tag>
        )
      )}

      {!isNotProspectLink() && (
        <Tooltip label={`Expected Live Date - ${dateExpected}`}>
          <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
            <TagLabel>{'Prospect'}</TagLabel>
          </Tag>
        </Tooltip>
      )}

      {/* {robotsIndexValue && (
        <Tooltip label={robotsIndexValue}>
          <Tag ml={1} borderRadius="full" colorScheme={'orange'}>
            <TagLabel>R</TagLabel>
          </Tag>
        </Tooltip>
      )} */}

      {isManual && (
        <Tooltip
          label={
            <Flex flexDirection={'column'}>
              <AppText>Manual Mode</AppText>
              <AppText mt={2} fontSize={10} lineHeight={'16px'}>
                This will automatically be reprocessed from the 1st of each month
              </AppText>
            </Flex>
          }
        >
          <Flex ml={2} justifyContent={'center'}>
            <Icon as={FaUserCircle} boxSize={'1em'} color={AppColors.secondary} />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

export default LinkStatusCell;
