import { Flex, Stack, Text, Box, Icon, useBreakpointValue, SimpleGrid } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { AppButton, ProductDto, AppColors } from '@backlinkit/shared';

export interface BannerProps {
  products: ProductDto[];
  onPlanSelect: (data: ProductDto) => void;
}

export const AppCreditProductBannerCards: React.FC<BannerProps> = ({ products, onPlanSelect }) => {
  const cardGap = useBreakpointValue({
    base: 8,
    lg: 2,
    xl: 8,
  });

  return (
    <SimpleGrid columns={1} gap={cardGap} w={'100%'}>
      {products.map((product) => {
        return (
          <Flex
            key={product.id}
            justify={'space-between'}
            flexDir={{ base: 'column', lg: 'row' }}
            pos={'relative'}
            boxShadow={'xl'}
            gap={{ base: 3, lg: 8 }}
            w={'100%'}
            flex={1}
            direction={'row'}
            borderRadius={'2xl'}
            border={`1px solid ${AppColors.appBorder}`}
            bgColor={'white'}
            p={6}
          >
            <Flex
              flexDir={'column'}
              align={'start'}
              bgColor={`white`}
              pos={'relative'}
              pb={4}
              gap={4}
              w={{ base: '100%', lg: '35%' }}
            >
              <Flex gap={3} align={'center'}>
                <Box height={'35px'} width={'3px'} bgColor={AppColors.ctaColor} />
                <Text
                  fontWeight={'600'}
                  textAlign={'center'}
                  fontSize={{ base: '18px', lg: '24px' }}
                  color={AppColors.ctaColor}
                >
                  {product.name} - {product.freeCredits}
                </Text>
              </Flex>
              <Flex align={'center'} justify={'center'} pos={'relative'} gap={2}>
                {product.price > 0 && (
                  <Text pos={'absolute'} color={'black'} left={0} top={0}>
                    $
                  </Text>
                )}
                <Text
                  textAlign={'center'}
                  fontSize={'2xl'}
                  fontWeight={'700'}
                  color={'black'}
                  marginLeft={'15px'}
                >
                  {`${product.price}`}
                </Text>
              </Flex>
              <Box width={'100%'}></Box>
            </Flex>

            <Flex w={{ base: '100%', lg: '65%' }} justifyContent={'flex-end'}>
              <AppButton
                borderRadius={'full'}
                borderColor={`${AppColors.secondary2} !important`}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                color={'white'}
                bgColor={AppColors.secondary2}
                onClick={() => onPlanSelect(product)}
              >
                Buy
              </AppButton>
            </Flex>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};
