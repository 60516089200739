import { NavLink, SocialLink } from '../models';
import { FaLinkedin } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import { FaYoutube } from 'react-icons/fa';

export const socials: SocialLink[] = [
  {
    icon: FaLinkedin,
    href: 'https://www.linkedin.com/company/backlinkit-seo/',
  },
  {
    icon: FaYoutube,
    href: 'https://www.youtube.com/@Backlinkit',
  },
];

export const insights: NavLink[] = [
  {
    title: 'Article Hub',
    href: '/',
  },
  {
    title: 'Case Studies',
    href: '/',
  },
];

export const coreTools: NavLink[] = [
  {
    title: 'Google Index Checker',
    href: '/',
  },
  {
    title: 'Domain Age Checker',
    href: '/',
  },
  {
    title: 'Backlink Intelligence',
    href: '/',
  },
];

export const supportLinks: NavLink[] = [
  {
    title: 'Contact us',
    href: '/contact',
  },
  // {
  //   title: 'Live Chat',
  //   href: '/',
  // },
  // {
  //   title: 'Knowledge base',
  //   href: '/',
  // },
];

export const legalLinks: NavLink[] = [
  {
    title: 'Privacy Policy',
    href: '/legal/privacy',
  },
  {
    title: 'Cookie Policy',
    href: '/cookies',
  },
  {
    title: 'Legal Hub',
    href: '/legal',
  },
];
