import React, { ReactNode, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { Box, Flex, Icon, Image } from '@chakra-ui/react';
import { DomainViewModel } from '../../models';
import { FaPlay } from 'react-icons/fa';
import { AppColors } from '../../theme';

export type AppPlayerProps = {
  media: string;
  controls?: boolean;
  width?: string;
  height?: string;
  thumbnailUrl?: string;
  playerRef?: any;
  showPlayButton?: boolean;
  playButton?: ReactNode;
  onPlayClick?: () => void;
} & ReactPlayerProps;

export const AppPlayer: React.FC<AppPlayerProps> = ({
  media,
  controls = true,
  thumbnailUrl,
  playerRef,
  width,
  height,
  showPlayButton = false,
  playButton,
  onPlayClick,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  return (
    <Box
      w={'100%'}
      height={'100%'}
      pos={'relative'}
      objectFit={'cover'}
      onClick={() => {
        onPlayClick && onPlayClick();
        setIsPlaying((prev) => !prev);
      }}
    >
      <ReactPlayer
        url={media}
        controls={showPlayButton ? isPlaying : controls}
        height={height ?? '100%'}
        style={{
          width: width ?? '100%',
        }}
        className="cc-media-react-player"
        light={
          thumbnailUrl && (
            <Image
              src={thumbnailUrl}
              objectFit={'cover'}
              objectPosition={'center'}
              alt="Thumbnail"
            />
          )
        }
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        playing={isPlaying}
        width={'100%'}
        ref={playerRef}
      />
      {showPlayButton && !isPlaying && (
        <Flex
          boxSize={'40px'}
          justify={'center'}
          pos={'absolute'}
          top={0}
          bottom={0}
          left={0}
          right={0}
          margin={'auto'}
          align={'center'}
          bgColor={AppColors.ctaColor}
          borderWidth={'2px'}
          borderStyle={'solid'}
          borderRadius={'full'}
          borderColor={'rgba(255, 255, 255, 1)'}
          zIndex={999}
          _hover={{
            cursor: 'pointer'
          }}
        >
          <Icon as={FaPlay} color={'white'} ml={'2px'} />
        </Flex>
      )}
    </Box>
  );
};
