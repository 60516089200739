import {
  Flex,
  useColorModeValue,
  Text,
  Box,
  Image,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import {
  useGetExternalReportQuery,
  useMarkExternalBacklinkFixedMutation,
} from '../../store/api/unAuthBacklinkReportApi';
import { useNavigate, useParams } from 'react-router-dom';
import ExternalReportTable from './external-report-table/external-report-table';
import { AppButton, AppColors, AppText, useLoading } from '@backlinkit/shared';
import { RoutesList } from '../../router/router';
import usersImage from '../../assets/footer-users-image.webp';
import footerLogo from '../../assets/backlinkit-color-white-logo.webp';
import SharedFooter from '@backlinkit/shared/lib/components/footer/shared-footer';
import whitelogo from '../../assets/white-logo.svg';
import { baseUrl } from '../../constants/navItems';

const ExternalReportsPage: React.FC = () => {
  const navigate = useNavigate();
  const title = 'Link Report';
  const toast = useToast();
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const { id } = useParams();
  const { data: report, refetch: refetchReport } = useGetExternalReportQuery(id!);
  const [markExternalBacklinkFixed] = useMarkExternalBacklinkFixedMutation();
  const { setLoading } = useLoading();

  const goToSignUp = () => {
    navigate(RoutesList.Registration);
  };

  const markBacklinkFixed = async (backlinkId: string) => {
    try {
      setLoading(true);
      await markExternalBacklinkFixed({ id: id ?? '', backlinkId }).unwrap();
      toast({
        title: 'Marked as fixed',
        description: `We've marked the backlink as fixed`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    } catch (error) {
      toast({
        title: `Error - limit reached`,
        description: `We've pickedup multiple attempts to fix the same link. This has been blocked`,
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
    }
    refetchReport();
  };

  return (
    <>
      <Box flexDir={'column'} h={'100vh'} bg={AppColors.appBackground} overflow={'auto'}>
        <Box
          w={'100vw'}
          display={'flex'}
          maxH={'auto'}
          bg={'white'}
          top={0}
          py={6}
          px={2}
          dir="row"
          justifyContent={'space-between'}
          alignItems={'center'}
          bgColor={AppColors.secondary2}
        >
          <Image src={whitelogo} h={'50px'} ml={'10px'} />
          <AppButton
            bgColor={AppColors.ctaColor}
            color={'white'}
            border={'1px solid white'}
            onClick={() => {
              window.open(baseUrl, '_blank');
            }}
          >
            Start Free Trial
          </AppButton>
        </Box>

        <Box>
          <Flex
            flexDirection={'column'}
            bg={useColorModeValue('white', 'gray.800')}
            rounded={'2xl'}
            p={4}
            m={4}
          >
            <AppText fontWeight={'bold'}>Note:</AppText>
            <AppText mt={2}>{report?.notes ?? 'N/A'}</AppText>
          </Flex>
          <Flex align={'center'} justifyContent={'center'} flex={1} dir="column">
            <Flex direction={'column'} w={'100%'}>
              <Flex mx={4}>
                <Text
                  color={textColorPrimary}
                  fontWeight={'bold'}
                  fontSize={'2xl'}
                  mt={'10px'}
                  mb={'4px'}
                >
                  {title}
                </Text>
              </Flex>
              <Flex
                bg={useColorModeValue('white', 'gray.800')}
                rounded={'2xl'}
                p={4}
                m={4}
                flexDir={'column'}
                flex={1}
              >
                <ExternalReportTable
                  data={report?.externalBacklinks ?? []}
                  markBacklinkFixed={markBacklinkFixed}
                />
              </Flex>
            </Flex>
          </Flex>
        </Box>

        <Box>
          <SharedFooter
            footerLogo={footerLogo}
            onBtnClick={() => {
              window.open(baseUrl, '_blank');
            }}
            usersImage={usersImage}
          />
        </Box>
      </Box>
    </>
  );
};
export default ExternalReportsPage;
