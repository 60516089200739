import { Box, Flex } from '@chakra-ui/react';
import {
  AppColors,
  AppConfirm,
  AppText,
  ProductDto,
  ProductType,
  useDialog,
} from '@backlinkit/shared';
import { useFetchProductsQuery } from '../../store/api/productApi';
import { useEffect, useState } from 'react';
import { Can } from '../../providers/permissions-provider';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import usePayments from '../../hooks/usePayments';
import { AppCreditProductBannerCards } from '../../components/app/app-credit-product-banner/app-credit-product-banner';
import { CheckoutComponent } from './checkout';

export const CreditProductsPage: React.FC = () => {
  const dialog = useDialog();
  const { handlePackage } = usePayments();
  const user = useSelector(selectUser);

  const { data: plans } = useFetchProductsQuery({
    refetchOnMountArgChange: true,
  });

  const [products, setProducts] = useState<ProductDto[]>();
  const [product, setProduct] = useState<ProductDto>();

  useEffect(() => {
    if (plans) {
      const filteredPlans = plans?.filter((x) => x.type === ProductType.CHECKERCREDITS);

      setProducts(filteredPlans);
    }
  }, [plans]);

  const setupPlan = async (product: ProductDto) => {
    try {
      dialog({
        title: 'Credit Purchase',
        centeredHeading: true,
        showCancel: true,
        render: (onCancel) => {
          return (
            <AppConfirm
              message={`You are about to purchase credits, are you sure?`}
              confirmText="Yes"
              cancelText="Cancel"
              onConfirm={async () => {
                onCancel();

                setProduct(product);
                await handlePackage(product);
              }}
              onCancel={() => {
                onCancel();
              }}
            ></AppConfirm>
          );
        },
      });
    } catch (error) {}
  };

  return (
    <Flex flexDir={'column'} mt={2} p={4} gap={6} w={'100%'}>
      <Can permission={'OrganizationBilling'} permissionLevel="Write">
        {!product && (
          <Flex flexDir={'column'} w={'full'} px={6}>
            <Flex
              justifyContent={'space-between'}
              align={'center'}
              borderTopRadius={'2xl'}
              bgColor={AppColors.secondary2}
              border={`1px solid ${AppColors.secondary2}`}
              p={8}
            >
              <AppText color={'white'} fontWeight="bold" fontSize="2xl">
                Please select a credit product
              </AppText>
            </Flex>
            <Flex
              w={'100%'}
              p={4}
              border={`1px solid ${AppColors.secondary2}`}
              borderBottomRadius={'2xl'}
            >
              {products && user?.organization?.product && (
                <AppCreditProductBannerCards
                  products={products ?? []}
                  onPlanSelect={(product) => setupPlan(product)}
                />
              )}
            </Flex>
          </Flex>
        )}

        <Box display={product ? 'Flex' : 'none'}>
          <CheckoutComponent product={product} />
        </Box>
      </Can>
    </Flex>
  );
};
