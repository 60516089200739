import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import logo from '../../assets/backlinkit_logo.svg';
import PasswordForm, { PasswordFormData } from '../../components/forms/password-reset-form';
import { AppColors, AppText, useLoading } from '@backlinkit/shared';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserAuthenticationMutation } from '../../store/api/authenticationApi';
import { logout, setUserAuth } from '../../store/slices/authentication.slice';
import { HttpError } from '@backlinkit/shared';
import { RoutesList } from '../../router/router';
import logoImage from '../../assets/login-image.png';
import {
  useAuthenticatePasswordKeyQuery,
  useUpdatePasswordMutation,
} from '../../store/api/authenticationApi';

const ChangePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { setLoading } = useLoading();
  const { id } = useParams();
  const { data: user } = useAuthenticatePasswordKeyQuery(id ?? '');
  const [smallDevice] = useMediaQuery('(max-width: 1400px)');

  const [userAuthentication, { data, isLoading, error, isSuccess, isError }] =
    useUserAuthenticationMutation();

  const [updatePassword] = useUpdatePasswordMutation();

  const dispatch = useDispatch();

  const signIn = async (formData: PasswordFormData) => {
    try {
      setLoading(true);
      await dispatch(logout());
      await updatePassword({
        id: user?.id ?? '',
        password: formData.confirmedPassword,
      });

      toast({
        title: 'Password',
        description: 'Password successfully saved.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      const res = await userAuthentication({
        username: user!.email,
        password: formData.newPassword,
      }).unwrap();

      dispatch(setUserAuth(res));
      setLoading(false);
      navigate(RoutesList.Dashboard);
    } catch (error) {
      console.error(error);
      const httpError: HttpError = (error as any).data as HttpError;

      toast({
        title: 'Login Failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Flex h={'100vh'} w={'100%'} align={'center'} justify={'center'} bgColor={AppColors.secondary2}>
      <Flex
        w={'100%'}
        gap={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
        align={'center'}
        px={'33px'}
        justify={'center'}
      >
        <Flex
          bg={'gray.50'}
          borderRadius={'40px'}
          p={{ base: 4, sm: 6, md: 8 }}
          gap={8}
          w={'100%'}
          maxW={'650px'}
          h={'calc(100vh - 10vh)'}
          display={'flex'}
          flexDir={'column'}
          justify={'center'}
        >
          <Stack spacing={4}>
            <AppText color={'gray.800'} lineHeight={1.1} variant={'h2'}>
              Change Password
            </AppText>
          </Stack>
          <Box as={'form'} mt={10} w={'100%'}>
            <PasswordForm onSubmit={signIn}></PasswordForm>
          </Box>
        </Flex>
        {!smallDevice && <Image maxH={'640px'} maxW={'760px'} src={logoImage} />}
      </Flex>
    </Flex>
  );
};

export default ChangePasswordPage;
