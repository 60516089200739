import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { RelStatus, AppText, AppColors } from '@backlinkit/shared';

type RelCellProps = {
  relStatus?: RelStatus;
  relValue?: string;
};

const RelCell: React.FC<RelCellProps> = ({ relStatus, relValue }) => {
  if (!relStatus) {
    return <></>;
  }

  const getRelStatus = () => {
    if (relStatus === RelStatus.follow) {
      return 'Follow';
    } else if (relStatus === RelStatus.noFollow) {
      return 'Nofollow';
    } else {
      return 'Not Found';
    }
  };

  const getRelColor = () => {
    if (relStatus === RelStatus.follow) {
      return AppColors.successColor;
    } else if (relStatus === RelStatus.noFollow) {
      return 'orange';
    } else {
      return 'black';
    }
  };

  return (
    <Tooltip label={relValue}>
      <div>
        <AppText color={getRelColor()}>{getRelStatus()}</AppText>
      </div>
    </Tooltip>
  );
};

export default RelCell;
